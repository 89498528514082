import { StepTypes, TaskTypes, ProjectTypes } from '@cango-app/sdk/types'
import { ComponentType, useContext, useMemo } from 'react'
import _orderBy from 'lodash/orderBy'
import { Stack } from '@mui/material'
import { useSelector } from 'react-redux'

import { selectors } from 'src/store/modules/projects-v3'
import { Box, Text } from 'src/components'
import { AnnotationCard } from 'src/modules/chains/components/annotation-card'
import { TaskContext } from 'src/providers'

import { FileViewItem } from './file-tasks/file-view'
import { LinkViewItem } from './link-view'
import { CompletationInfo } from './file-tasks/completation-info'

interface TaskReferenceItemType {
	stepId: string
	taskName: string
	chain: TaskTypes.Chain.ProjectChain | undefined
	tasks: TaskTypes.PopulatedTask[]
}

type TaskReferenceCategories = {
	files: TaskReferenceItemType[]
	links: TaskReferenceItemType[]
	// contacts: FileViewItemType[]
	notes:
		| {
				taskName: string
				completed: number | undefined
				annotations: TaskTypes.PopulatedTask['annotations']
		  }
		| undefined
}

const getFileReferences = (
	task: TaskTypes.PopulatedTask,
	previousRefs: TaskReferenceItemType[],
	index: number,
): TaskReferenceItemType[] => {
	const newRefs = [...previousRefs]
	if (!task.step?._id) {
		newRefs.push({
			stepId: `${task._id}-${index}-no-step`,
			taskName: task.name,
			chain: task.chain,
			tasks: [task],
		})
		return newRefs
	}

	const accIndex = newRefs.findIndex(
		(accTask) =>
			accTask.stepId === task.step?._id &&
			(accTask.chain?._id === task.chain?._id || !accTask.chain?._id),
	)

	const rootAccIndex = newRefs.findIndex(
		(_accTask) =>
			_accTask.stepId === task.step?._id &&
			(_accTask.chain?._id === task.chain?._id || !task.chain?._id),
	)

	if (rootAccIndex >= 0) {
		newRefs[rootAccIndex].tasks.push(task)
		newRefs[rootAccIndex].tasks = _orderBy(newRefs[rootAccIndex].tasks, 'iteration', 'desc')
		return newRefs
	}

	if (accIndex >= 0) {
		newRefs[accIndex].tasks.push(task)
		newRefs[accIndex].tasks = _orderBy(newRefs[accIndex].tasks, 'iteration', 'desc')
		return newRefs
	}

	newRefs.push({
		stepId: task.step?._id as string,
		taskName: task.name,
		chain: task.chain,
		tasks: [task],
	})

	return newRefs
}

export const TaskReference: ComponentType<{
	taskReferences: TaskTypes.TaskAction['task_references']
	project?: Pick<ProjectTypes.Project, '_id' | 'google_drive_folder_id' | 'name'>
	isAttachment: boolean
}> = ({ taskReferences, project, isAttachment }) => {
	const { updateTask } = useContext(TaskContext)
	const typeOfTaskElement: keyof Pick<TaskTypes.Task, 'actions' | 'attachments'> = isAttachment
		? 'attachments'
		: 'actions'
	const projectTasks = useSelector(selectors.getMappedProjectTasks)
	const references = useMemo((): TaskReferenceCategories => {
		if (!taskReferences.length) {
			return {
				files: [],
				links: [],
				// contacts: [],
				notes: undefined,
			}
		}
		const filteredReferences = taskReferences.filter((_ref) => _ref.task)

		return filteredReferences.reduce(
			(referenceCategories: TaskReferenceCategories, _reference, index) => {
				const referencedTask = projectTasks.get(_reference.task)
				let stepId = referencedTask?.step?._id
				if (!referencedTask) {
					return referenceCategories
				}
				if (!stepId) {
					stepId = `${referencedTask?._id}-no-step`
				}

				if (_reference.fields.includes(StepTypes.Action.TaskReferenceEnum.Files)) {
					referenceCategories.files = getFileReferences(
						referencedTask,
						referenceCategories.files,
						index,
					)
				}

				if (_reference.fields.includes(StepTypes.Action.TaskReferenceEnum.Links)) {
					referenceCategories.links = [
						...referenceCategories.links,
						{
							stepId: stepId,
							taskName: referencedTask.name,
							chain: referencedTask.chain,
							tasks: [referencedTask],
						},
					]
				}
				if (_reference.fields.includes(StepTypes.Action.TaskReferenceEnum.Notes)) {
					const completedAtTime = referencedTask.lifecycle?.completed_meta?.length
						? referencedTask.lifecycle?.completed_meta[
								referencedTask.lifecycle?.completed_meta.length - 1
							]
						: undefined
					referenceCategories.notes = {
						annotations: referencedTask.annotations,
						completed: completedAtTime?.at,
						taskName: referencedTask.name,
					}
				}
				return referenceCategories
			},
			{
				files: [],
				links: [],
				// contacts: [],
				notes: undefined,
			},
		)
	}, [taskReferences])

	const handleChangeFileIds = async (
		referencedTaskId: string,
		fileIds: string[],
		actionIndex: number,
	) => {
		await updateTask(`${typeOfTaskElement}.${actionIndex}.file_ids`, fileIds, {
			referencedTask: {
				_id: referencedTaskId,
				isAttachment,
			},
			updateDb: true,
		})
	}

	if (!project) {
		return null
	}

	return (
		<Stack direction="column" spacing={4}>
			{!!references.files.length && (
				<Box>
					<Text variant="overline">Files</Text>
					{references.files.map((fileRef, index) => {
						return (
							<FileViewItem
								key={`${fileRef.stepId}-${fileRef.chain?._id}-${index}`}
								item={fileRef}
								project={project}
								onUpdateFileIds={handleChangeFileIds}
								isLastItem={index === references.files.length - 1}
								typeOfTaskElement={typeOfTaskElement}
							/>
						)
					})}
				</Box>
			)}

			{!!references.links.length && (
				<Box>
					<Text variant="overline">Links</Text>
					{references.links.map((linkRef, index) => (
						<LinkViewItem
							key={`${linkRef.stepId}-${linkRef.chain?._id}-${index}`}
							item={linkRef}
							isLastItem={index === references.links.length - 1}
						/>
					))}
				</Box>
			)}
			{!!references.notes?.annotations.length && (
				<Box>
					<Text variant="overline">Notes</Text>
					<Text fontSize={14}>
						Task: <b>{references.notes.taskName}</b>
					</Text>
					<CompletationInfo completedAtTime={references.notes.completed} />
					<Stack direction="column" spacing={1.5} marginTop={2}>
						{references.notes.annotations.map(({ _id, annotation }) => {
							return <AnnotationCard key={_id} annotation={annotation} />
						})}
					</Stack>
				</Box>
			)}
		</Stack>
	)
}
