import { List, ListItemButton, ListItemIcon, Checkbox, ListItemText } from '@mui/material'
import { ComponentType, useCallback, useState } from 'react'
import _isEmpty from 'lodash/isEmpty'
import { TableTypes, ProjectTypes } from '@cango-app/sdk/types'
import { v4 } from 'uuid'

import { colors } from 'src/theme/colors'
import useDebouncedCallback from 'src/hooks/useDebouncedCallback'

import { Box } from '../box'
import { TextField } from '../text-field'

type Props = {
	answerType: TableTypes.AnswerType
	answerOptions: TableTypes.ListOption[]
	onAnswerClick: (answer: ProjectTypes.QuestionaireResponseItem[]) => void
	response?: ProjectTypes.QuestionaireAnswer
	images: Map<string, string>
}

const Image: ComponentType<{ imagePath?: string; imageUrl?: string }> = ({
	imagePath,
	imageUrl,
}) => {
	return (
		<Box mr={1} width={170}>
			{!!imageUrl && (
				<img
					srcSet={imageUrl}
					src={imageUrl}
					alt={imagePath}
					loading="lazy"
					style={{
						width: 170,
					}}
				/>
			)}
		</Box>
	)
}

const ListOption: ComponentType<{
	optionId: string
	onClick: () => void
	hasImages: boolean
	image: TableTypes.ListOption['image']
	label: string
	isSelected: boolean
}> = ({ optionId, onClick, hasImages, image, label, isSelected }) => (
	<ListItemButton disableGutters onClick={onClick}>
		{hasImages && (
			<Box width={150} height={150}>
				{image ? (
					<Image imagePath={image.path} imageUrl={image.url} />
				) : (
					<Box
						width={150}
						height={150}
						sx={{
							backgroundColor: colors.neutral['10'],
						}}
					/>
				)}
			</Box>
		)}
		<ListItemIcon>
			<Checkbox
				disableRipple
				inputProps={{
					'aria-labelledby': optionId,
				}}
				checked={isSelected}
			/>
		</ListItemIcon>
		<ListItemText id={optionId} primary={label} />
	</ListItemButton>
)

const OptionAnswerComponent: ComponentType<Props> = ({
	answerType,
	answerOptions,
	onAnswerClick,
	images,
	response,
}) => {
	const answer = response?.answer ?? []
	const anyAnswerHasImage = answerOptions.some(
		({ image }) => image && !_isEmpty(image) && image.path,
	)

	const handleAnswerClick = (itemOptionIdClicked: string) => {
		if (answerType === TableTypes.AnswerType.SingleSelect) {
			onAnswerClick([{ _id: v4(), option_id: itemOptionIdClicked }])
		} else if (answerType === TableTypes.AnswerType.MultiSelect) {
			const newAnswer = answer?.some(({ option_id }) => option_id === itemOptionIdClicked)
				? answer?.filter(({ option_id }) => option_id !== itemOptionIdClicked)
				: [...(answer || []), { _id: v4(), option_id: itemOptionIdClicked }]
			onAnswerClick(newAnswer)
		}
	}

	return (
		<List
			dense
			component="div"
			role="list"
			sx={{
				paddingTop: 4,
			}}
		>
			{answerOptions.map(({ _id, label, image }) => {
				return (
					<ListOption
						key={_id}
						optionId={_id}
						onClick={() => handleAnswerClick(_id)}
						hasImages={anyAnswerHasImage}
						image={
							image?.path ? { url: images.get(image?.path ?? ''), path: image?.path } : undefined
						}
						label={label}
						isSelected={answer.some((_ans) => _ans.option_id === _id)}
					/>
				)
			})}
		</List>
	)
}

const TextAnswerComponent: ComponentType<Props> = ({ answerType, onAnswerClick, response }) => {
	const [inputValue, setInputValue] = useState<string>(response?.answer?.[0]?.text || '')
	const answerId = response?.answer?.[0]?._id || ''

	const debouncedOnAnswerClick = useDebouncedCallback(
		useCallback(
			(newText: string) => {
				onAnswerClick([{ _id: answerId || v4(), text: newText }])
			},
			[onAnswerClick, answerId],
		),
		300,
	)

	const handleTextChange = (newText: string) => {
		setInputValue(newText)
		debouncedOnAnswerClick(newText)
	}

	return (
		<Box pt={4}>
			<TextField
				label="Answer"
				sx={{
					width: 400,
				}}
				value={inputValue}
				onChange={(e) => handleTextChange(e.target.value)}
				multiline={answerType === TableTypes.AnswerType.LongText}
			/>
		</Box>
	)
}

export const AnswerManager: ComponentType<Props> = (props) => {
	if (
		[TableTypes.AnswerType.SingleSelect, TableTypes.AnswerType.MultiSelect].includes(
			props.answerType,
		)
	) {
		return <OptionAnswerComponent {...props} />
	}

	if (
		[TableTypes.AnswerType.ShortText, TableTypes.AnswerType.LongText].includes(props.answerType)
	) {
		return <TextAnswerComponent {...props} />
	}

	return null
}
