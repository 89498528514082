import { useContext } from 'react'
import { useSelector } from 'react-redux'

import { selectors as userSelectors } from 'src/store/modules/user'

import { ChainContext } from './chain-provider'

export const useChainPermissions = () => {
	const { chain } = useContext(ChainContext)
	const parentOrganisations = useSelector(userSelectors.getParentOrganisations) ?? []
	const isSharedChain = parentOrganisations.find(({ _id }) => chain?.organisationId === _id)

	return {
		canEditChain: !isSharedChain,
	}
}
