import { StepTypes, TaskTypes, ProjectTypes } from '@cango-app/sdk/types'
import { ComponentType, useCallback, useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { V3ProjectSdk } from '@cango-app/sdk/api'
import { PulseLoader } from 'react-spinners'

import { Box, DriveUploadButton } from 'src/components'
import { selectors as authSelectors } from 'src/store/modules/auth'
import { showSnackbar } from 'src/helpers/snackbarManager'
import { TaskContext } from 'src/providers'

import { TaskFiles } from './task-files'

type FileUploadProps = {
	project?: Pick<ProjectTypes.Project, 'name' | 'google_drive_folder_id'>
	task: TaskTypes.PopulatedTask
	action: TaskTypes.TaskAction
	isAttachment?: boolean
}

export const FileUpload: ComponentType<FileUploadProps> = ({
	project: _project,
	task,
	action,
	isAttachment = false,
}) => {
	const authHeaders = useSelector(authSelectors.getAuthHeaders)
	const [isProjectLoading, setIsProjectLoading] = useState(false)
	const [project, setProject] = useState<
		Pick<ProjectTypes.Project, 'name' | 'google_drive_folder_id'> | undefined
	>(_project)
	const typeOfTaskElement: keyof Pick<TaskTypes.PopulatedTask, 'actions' | 'attachments'> =
		isAttachment ? 'attachments' : 'actions'
	const fileUploadIndex = task[typeOfTaskElement].findIndex(
		(_action) => _action.type === StepTypes.Action.ActionEnum.FileUpload,
	)
	const { updateTask, isLoadingTask } = useContext(TaskContext)
	const actionIndex = task[typeOfTaskElement].findIndex(({ _id }) => _id === action._id)

	const handleChangeFileIds = async (fileIds: string[]) => {
		try {
			const response = await V3ProjectSdk.addFileIdsToTask(
				import.meta.env.VITE_API as string,
				authHeaders,
				{
					taskId: task._id,
					fileIds,
					actionIndex: fileUploadIndex,
					isAttachment,
				},
			)
			updateTask(`${typeOfTaskElement}.${fileUploadIndex}.file_ids`, fileIds, {
				localStateKey: `${typeOfTaskElement}[${fileUploadIndex}].file_ids`,
			})
			updateTask(
				`${typeOfTaskElement}.${fileUploadIndex}.file_history`,
				response.file_history ?? [],
				{
					localStateKey: `${typeOfTaskElement}[${fileUploadIndex}].file_history`,
				},
			)
		} catch (error) {
			showSnackbar('Error adding file(s)', { variant: 'error' })
		}
	}

	const fetchProject = useCallback(async () => {
		try {
			setIsProjectLoading(true)
			const response = await V3ProjectSdk.getProject({
				baseURL: import.meta.env.VITE_API as string,
				authHeaders,
				projectId: task.project_id,
			})

			setProject({
				name: response.project.name,
				google_drive_folder_id: response.project.google_drive_folder_id,
			})
		} catch (error) {
			showSnackbar('Error fetching project', { variant: 'error' })
		} finally {
			setIsProjectLoading(false)
		}
	}, [task.project_id])

	useEffect(() => {
		if (!_project) {
			fetchProject()
		}
	}, [])

	if (isProjectLoading) {
		return (
			<Box>
				<PulseLoader size={4} />
			</Box>
		)
	}

	if (!project) return null

	return (
		<Box>
			<Box mb={1} display="flex" justifyContent={{ mobile: 'center', laptop: 'flex-start' }}>
				<DriveUploadButton
					selectedFilesIds={action.file_ids}
					isLoading={isLoadingTask}
					onFileIdsChange={handleChangeFileIds}
				/>
			</Box>
			<TaskFiles
				action={action}
				actionIndex={actionIndex}
				typeOfTaskElement={isAttachment ? 'attachments' : 'actions'}
			/>
		</Box>
	)
}
