import React, { ComponentType, useCallback, useEffect, useState } from 'react'
import { ImagesSdk } from '@cango-app/sdk/api'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { FileUploader } from 'react-drag-drop-files'
import { useSelector } from 'react-redux'
import PulseLoader from 'react-spinners/PulseLoader'
import { ImageList, ImageListItem } from '@mui/material'

import { selectors as authSelectors } from 'src/store/modules/auth'

import { DocumentUpload } from '../assets/icons'
import { showSnackbar } from '../helpers/snackbarManager'

import { Box } from './box'
import { Text } from './text'
import { Modal } from './modal'
import { TabPanel } from './tab-panel'

type InternalImageUploadProps = {
	open: boolean
	onClose: () => void
	onImageSelect: (data: { name: string; url: string }) => void
}

enum GalleryTabs {
	Upload = 0,
	MyGallery = 1,
}

const a11yProps = (index: number) => {
	return {
		id: `cango-table-tab-${index}`,
		'aria-controls': `cango-table-tabpanel-${index}`,
	}
}

const UploadPanel: ComponentType<{
	onUploadComplete: (data: { name: string; url: string }) => void
}> = ({ onUploadComplete }) => {
	const [isFileHovering, setIsFileHovering] = useState(false)
	const authHeaders = useSelector(authSelectors.getAuthHeaders)
	const [isFileUploading, setIsFileUploading] = useState(false)

	const handleSelectedFile = async (file: File) => {
		setIsFileUploading(true)
		const formData = new FormData()
		formData.append('image', file)
		try {
			const response = await ImagesSdk.upload(
				import.meta.env.VITE_API as string,
				authHeaders,
				formData,
			)
			onUploadComplete(response)
		} catch (error) {
			showSnackbar('Error uploading image', { variant: 'error' })
		} finally {
			setIsFileUploading(false)
		}
	}

	return (
		<Box mt={1}>
			<FileUploader
				handleChange={handleSelectedFile}
				classes="drop_area"
				onDraggingStateChange={setIsFileHovering}
				dropMessageStyle={{ backgroundColor: '#42a5f5' }}
				hoverTitle=" "
				maxSize={4}
				types={['JPG', 'PNG', 'GIF']}
			>
				<Box
					border="1.5px dashed black"
					borderRadius={2}
					display="flex"
					justifyContent="center"
					alignItems="center"
					flexDirection="column"
					py={2}
					sx={{ cursor: 'pointer' }}
				>
					{isFileUploading ? (
						<>
							<PulseLoader size={6} />
							<Text>Uploading...</Text>
						</>
					) : (
						<>
							<DocumentUpload />
							<Text variant="h6" fontWeight="bold" color={isFileHovering ? 'error' : 'primary'}>
								{isFileHovering ? 'Drop it like its hot!' : 'Select file(s) to upload'}
							</Text>
							<Text>or drag and drop here</Text>
							<Text>Up to 4MB</Text>
						</>
					)}
				</Box>
			</FileUploader>
		</Box>
	)
}

const MyGalleryPanel: ComponentType<{
	onImageSelect: (data: { name: string; url: string }) => void
}> = ({ onImageSelect }) => {
	const [imageUrls, setImageUrls] = useState<{ name: string; url: string }[]>([])
	const [isLoadingImages, setIsLoadingImages] = useState(false)
	const authHeaders = useSelector(authSelectors.getAuthHeaders)

	const fetchImages = useCallback(async () => {
		setIsLoadingImages(true)
		try {
			const response = await ImagesSdk.getBucket(import.meta.env.VITE_API as string, authHeaders)
			setImageUrls(response)
		} catch (error) {
			showSnackbar('Error fetching images', { variant: 'error' })
		} finally {
			setIsLoadingImages(false)
		}
	}, [])

	useEffect(() => {
		fetchImages()
	}, [])

	if (isLoadingImages) {
		return (
			<Box mt={1} display="flex" justifyContent="center" alignItems="center" height={300}>
				<PulseLoader size={6} />
			</Box>
		)
	}

	return (
		<Box my={1}>
			<ImageList cols={2} rowHeight={164} sx={{ height: 450 }}>
				{imageUrls.map((item) => (
					<ImageListItem
						key={item.url}
						onClick={() => onImageSelect(item)}
						sx={{
							position: 'relative',
							cursor: 'pointer',
							'& img': {
								transition: 'opacity 0.3s ease', // Smooth transition
							},
							'&:hover img': {
								opacity: 0.7, // Hover opacity
							},
							'&:active img': {
								opacity: 0.5, // Click (active) opacity
							},
						}}
					>
						<img srcSet={`${item.url}`} src={`${item.url}`} alt={item.name} loading="lazy" />
					</ImageListItem>
				))}
			</ImageList>
		</Box>
	)
}

export const InternalImageUpload: ComponentType<InternalImageUploadProps> = ({
	open,
	onClose,
	onImageSelect,
}) => {
	const [activeTab, setActiveTab] = useState(GalleryTabs.Upload)

	const handleImageSelect = (data: { name: string; url: string }) => {
		onImageSelect(data)
		onClose()
	}

	return (
		<Modal open={open} onClose={onClose}>
			<Box width={438} minHeight={500} maxHeight={600}>
				<Text variant="overline">Media gallery</Text>
				<Tabs value={activeTab} onChange={(event, newIndex) => setActiveTab(newIndex)}>
					<Tab label="Upload" {...a11yProps(GalleryTabs.Upload)} />
					<Tab label="My Gallery" {...a11yProps(GalleryTabs.MyGallery)} />
				</Tabs>
				<TabPanel index={GalleryTabs.Upload} value={activeTab}>
					<UploadPanel onUploadComplete={handleImageSelect} />
				</TabPanel>
				<TabPanel index={GalleryTabs.MyGallery} value={activeTab}>
					<MyGalleryPanel onImageSelect={handleImageSelect} />
				</TabPanel>
			</Box>
		</Modal>
	)
}
