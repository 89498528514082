import { ComponentType, useCallback, useContext, useMemo, useState } from 'react'
import { TableTypes } from '@cango-app/sdk/types'
import { TablesSdk } from '@cango-app/sdk/api'
import { isEvaluable, TableUtils } from '@cango-app/sdk/utils'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { v4 } from 'uuid'
import { Stack } from '@mui/material'
import clipboard from 'clipboardy'
import _uniq from 'lodash/uniq'
import { useSelector } from 'react-redux'

import { TableContext } from 'src/providers/table-provider'
import { showSnackbar } from 'src/helpers/snackbarManager'
import { Box, Text, Button, Grid } from 'src/components'
import { SaveIcon } from 'src/assets/icons'
import { colors } from 'src/theme/colors'
import { SingleSelectValues } from 'src/modules/tables/column-settings/calculation-modal/single-select-values'
import { selectors as authSelectors } from 'src/store/modules/auth'
import { selectors as projectSelectors } from 'src/store/modules/projects-v3'
import { selectors as tableSelectors } from 'src/store/modules/tables'

import { ConfigureCalculationForm } from './types'
import { allOperators, formulas } from './utils'
import { Slice } from './slice'
import { TableVLookup } from './table-vlookup'
import { CreateFormula } from './create-formula'
import { MathematicalOperators } from './mathematical-operators'
import { FormulaOperators } from './formula-operators'
import { ColumnList } from './column-list'
import { ReferenceColumnList } from './reference-column-list'
import { NumberInput } from './number-input'

type CalculationModalProps = {
	defaultCalculation: TableTypes.FormulaSlice[]
	columnId: string
	rowId?: string
	onClose: () => void
}

const getDefaultCalculation = (defaultCalculation: TableTypes.FormulaSlice[]) => {
	return defaultCalculation.map((_calc, index) => {
		const indexOfId = defaultCalculation.findIndex((_indexCalc) => _indexCalc.id === _calc.id)
		if (indexOfId !== index) {
			_calc.id = v4()
		}
		return _calc
	})
}

export const CalculationModal: ComponentType<CalculationModalProps> = ({
	columnId,
	rowId,
	defaultCalculation,
	onClose,
}) => {
	const formValues = useForm<ConfigureCalculationForm>({
		defaultValues: {
			calculation: getDefaultCalculation(defaultCalculation),
		},
	})
	const {
		control,
		handleSubmit,
		formState: { isDirty },
		watch,
	} = formValues
	const { fields, append, remove, update, insert } = useFieldArray({ control, name: 'calculation' })
	const authHeaders = useSelector(authSelectors.getAuthHeaders)
	const tableList = useSelector(tableSelectors.selectTableList)
	const selectedProjectId = useSelector(projectSelectors.getSelectedProjectId)
	const calculations = watch('calculation')
	const {
		onUpdateColumn,
		isUpdatingTable,
		mappedColumns,
		tableConfig,
		mappedRecords,
		updateRecords,
	} = useContext(TableContext)
	const [activeOperatorId, setActiveOperatorId] = useState('')
	const [selectedFieldId, setSelectedFieldId] = useState('')
	const [activeCursor, setActiveCursor] = useState<number | null>(null)

	const resetActiveIds = useCallback(() => {
		setActiveOperatorId('')
		setSelectedFieldId('')
	}, [])

	const handleSaveCalculation = async (data: ConfigureCalculationForm) => {
		if ((partialCalculation && !isEvaluable(partialCalculation)) || !tableConfig) {
			showSnackbar('Invalid calculation', { variant: 'error' })
			return
		}

		const lookupTablesNotCached = data.calculation.filter((_slice) => {
			return (
				_slice.type === TableTypes.FormulaSliceType.VLOOKUP &&
				!tableConfig?.vLookupTables[_slice.vlookup?.targetTableId ?? '']
			)
		})
		const notCachedIds = _uniq(
			lookupTablesNotCached.map((_slice) => _slice.vlookup?.targetTableId),
		).filter(Boolean)

		const newVLookupTables: TableTypes.VLookupTables = {}
		if (notCachedIds.length) {
			for (const _sliceTableId of notCachedIds) {
				if (!_sliceTableId) {
					continue
				}
				try {
					const sliceTable = await TablesSdk.getTable(
						import.meta.env.VITE_API as string,
						authHeaders,
						_sliceTableId,
						{
							build_type: 'cached',
							project_id: selectedProjectId,
						},
					)
					if (!sliceTable) {
						continue
					}
					newVLookupTables[_sliceTableId] = sliceTable.table
				} catch (error) {
					// eslint-disable-next-line no-console
					console.error(error)
				}
			}
		}

		if (!rowId) {
			await onUpdateColumn(
				columnId,
				{ calculation: data.calculation },
				{
					config: {
						...tableConfig,
						vLookupTables: {
							...tableConfig.vLookupTables,
							...newVLookupTables,
						},
					},
				},
			)
		} else {
			const record = mappedRecords.get(rowId)
			if (!record) {
				return
			}
			const previousRowCalculations = record.calculations
			await updateRecords({
				rows: [
					{
						oldRow: record,
						newRow: {
							...record,
							calculations: {
								...previousRowCalculations,
								[columnId]: data.calculation,
							},
						},
					},
				],
				save: true,
				// TODO Handle saving vLookup tables
				// config: {
				// 	vLookupTables: {
				// 		...tableConfig.vLookupTables,
				// 		...newVLookupTables,
				// 	},
				// },
				projectId: selectedProjectId,
			})
		}
		onClose()
	}

	const selectedField = useMemo(() => {
		return fields.find((_calc) => _calc.id === selectedFieldId)
	}, [selectedFieldId, fields])

	const getLabel = useCallback(
		({ type, value, lookup, reference_column }: TableTypes.FormulaSlice) => {
			switch (type) {
				case TableTypes.FormulaSliceType.FIELD:
					return mappedColumns.get(value)?.name
				case TableTypes.FormulaSliceType.SINGLE_SELECT_OPTION: {
					const [columnId, optionId] = value.split('::')
					const column = mappedColumns.get(columnId)
					if (!column) {
						return ''
					}
					const option = column.valueOptions.find(({ _id }) => _id === optionId)
					return `${column.name}:${option?.label}`
				}
				case TableTypes.FormulaSliceType.OPERATOR:
					return allOperators.find(({ _id }) => _id === value)?.label
				case TableTypes.FormulaSliceType.NUMBER:
					return value.split('__').join(' ')
				case TableTypes.FormulaSliceType.LOOKUP:
					return `Lookup from ${tableList.find(({ _id }) => _id === lookup?.tableId)?.name ?? 'TBC'}`
				case TableTypes.FormulaSliceType.VLOOKUP:
					return `VLookup`
				case TableTypes.FormulaSliceType.REFERENCE_FIELD: {
					if (!reference_column) {
						return ''
					}

					const mainColumn = mappedColumns.get(reference_column)
					if (!mainColumn) {
						return ''
					}

					if (value === TableTypes.QUESTIONAIRE_REFERENCE_ANSWER_PLACEHOLDER) {
						return `${mainColumn.name}.Answer`
					}

					if (!tableConfig?.referenceColumnNames[reference_column]) {
						return ''
					}

					const row = mappedRecords.get(rowId ?? '')

					if (
						value.includes('option:') &&
						row &&
						mainColumn.type === TableTypes.FieldType.QUESTIONAIRE_REFERENCE
					) {
						const optionId = value.split(':')[1]
						const options = TableUtils.getQuestionOptions({
							referenceColumns: tableConfig.referenceColumnNames ?? {},
							fieldId: reference_column,
							row,
						})
						return `${mainColumn.name}.Answer:${options.find(({ _id }) => _id === optionId)?.label}`
					}

					if (value.includes('option:')) {
						const optionId = value.split(':')[1]
						return mainColumn.valueOptions.find(({ _id }) => _id === optionId)?.label ?? ''
					}

					const referencedColumn = tableConfig?.referenceColumnNames[reference_column].find(
						(_refCol) => _refCol._id === value,
					)?.label
					return `${mainColumn.name}.${referencedColumn}`
				}
				default:
					return ''
			}
		},
		[mappedColumns, tableList, tableConfig?.referenceColumnNames],
	)

	const addField = (newSlice: TableTypes.FormulaSlice) => {
		const existingFieldIndex = fields.findIndex((_field) => _field.id === newSlice.id)
		if (existingFieldIndex > -1) {
			update(existingFieldIndex, newSlice)
		} else if (activeCursor === null) {
			append(newSlice)
		} else {
			insert(activeCursor, newSlice)
			setActiveCursor(activeCursor + 1)
		}
		resetActiveIds()
	}

	const partialCalculation = useMemo(() => {
		return fields
			.map(({ value, type }) => {
				if (type === TableTypes.FormulaSliceType.FIELD) {
					return 1 // convert fields to any number for evaluating formula
				}
				if (
					type === TableTypes.FormulaSliceType.LOOKUP ||
					type === TableTypes.FormulaSliceType.VLOOKUP ||
					type === TableTypes.FormulaSliceType.REFERENCE_FIELD ||
					type === TableTypes.FormulaSliceType.SINGLE_SELECT_OPTION
				) {
					return 1 // convert lookup to any number for evaluating formula
				}
				if (TableTypes.Operator[value as keyof typeof TableTypes.Operator]) {
					return TableTypes.Operator[value as keyof typeof TableTypes.Operator]
				}
				if (type === TableTypes.FormulaSliceType.NUMBER) {
					return String(value)
				}

				return value
			})
			.join('')
	}, [fields])

	const handleFormulaClick = (operatorId?: string) => {
		if (
			[
				'IFERROR',
				'IF',
				'ISEQUAL',
				'NOTEQUAL',
				'ROUND',
				'FORMATCURRENCY',
				'CONTAINS',
				'POWER',
			].includes(operatorId ?? '')
		) {
			addField({
				id: v4(),
				type: TableTypes.FormulaSliceType.OPERATOR,
				value: operatorId as string,
			})
		} else {
			if (operatorId) {
				setActiveOperatorId(operatorId)
				return
			}
		}
		resetActiveIds()
	}

	const handlePasteFromClipboard = async () => {
		const clipbboardInfo = await clipboard.read()
		const formattedClipboard = JSON.parse(clipbboardInfo)
		if (!formattedClipboard?.type) {
			showSnackbar('Clipboard does not include formula', { variant: 'error' })
			return
		}
		if (formattedClipboard.type === 'row_calculation') {
			formattedClipboard.data.forEach((field: TableTypes.FormulaSlice) => {
				addField({
					...field,
				})
			})
		}
	}

	return (
		<FormProvider {...formValues}>
			<Box>
				<Grid container columnSpacing={2}>
					<Grid item xs={3} />
					<Grid item xs={6} display="flex" alignItems="center" justifyContent="center">
						<Text fontWeight="bold" variant="overline" textAlign="center" mt={2}>
							Configure Calculation
						</Text>
					</Grid>

					<Grid item xs={12} mb={2}>
						<MathematicalOperators onOperatorClick={addField} />
					</Grid>
					<Grid item xs={12} mb={2}>
						<FormulaOperators
							activeOperatorId={activeOperatorId}
							onFormulaClick={handleFormulaClick}
						/>
					</Grid>
					{!!activeOperatorId && formulas.includes(activeOperatorId) && (
						<Grid item xs={12}>
							<CreateFormula activeOperator={activeOperatorId} onAddField={addField} />
						</Grid>
					)}
					{activeOperatorId === TableTypes.FormulaSliceType.VLOOKUP && (
						<Grid item xs={12}>
							<TableVLookup
								defaultValues={selectedField?.vlookup}
								onSaveVLookup={addField}
								selectedId={selectedFieldId}
								columnId={columnId}
							/>
						</Grid>
					)}
					{!activeOperatorId && (
						<>
							<Grid item xs={12} mb={2}>
								<ColumnList onItemClick={addField} />
							</Grid>
							<SingleSelectValues onFieldClick={addField} />
							<ReferenceColumnList
								onFieldClick={addField}
								rowId={rowId}
								selectedColumnId={columnId}
							/>
							<NumberInput onAddNumber={addField} />
						</>
					)}
					<Grid item xs={12} marginTop={3}>
						<Stack direction="row" alignItems="center">
							<Text flex={1}>Calculation:</Text>
							<Button
								size="small"
								variant="text"
								onClick={() => {
									remove()
									resetActiveIds()
								}}
							>
								Clear all
							</Button>
							<Button variant="text" size="small" onClick={handlePasteFromClipboard}>
								Paste from clipboard
							</Button>
						</Stack>
					</Grid>
					<Grid item xs={12}>
						<Stack
							direction="row"
							marginY={1}
							paddingY={2}
							sx={{
								overflowX: 'auto',
							}}
						>
							{fields.map((calc, index) => (
								<Box display="flex" alignItems="center" key={calc.id}>
									<Box
										sx={{
											borderLeft: activeCursor === index ? '1px solid black' : undefined,
											width: '10px',
											height: '25px',
											mr: '2px',
											'@keyframes blink-border': {
												'0%': { borderColor: 'black' },
												'50%': { borderColor: 'transparent' },
												'100%': { borderColor: 'black' },
											},
											animation: activeCursor === index ? 'blink-border 1s infinite' : undefined,
										}}
										onClick={() => setActiveCursor(index)}
									/>
									<Slice
										control={control}
										onClick={() => {
											setActiveCursor(null)
											if (calc.type === TableTypes.FormulaSliceType.VLOOKUP) {
												setActiveOperatorId(TableTypes.FormulaSliceType.VLOOKUP)
												setSelectedFieldId(calc.id)
											}
										}}
										onDelete={() => {
											remove(index)
											resetActiveIds()
										}}
										sliceIndex={index}
										key={calc.id}
										rowId={rowId}
									/>
									{index === fields.length - 1 && (
										<Box
											sx={{
												borderLeft: activeCursor === index + 1 ? '1px solid black' : undefined,
												width: '10px',
												height: '25px',
												ml: '2px',
												'@keyframes blink-border': {
													'0%': { borderColor: 'black' },
													'50%': { borderColor: 'transparent' },
													'100%': { borderColor: 'black' },
												},
												animation:
													activeCursor === index + 1 ? 'blink-border 1s infinite' : undefined,
											}}
											onClick={() => setActiveCursor(index + 1)}
										/>
									)}
								</Box>
							))}
						</Stack>
					</Grid>
					<Grid item xs={12} display="flex" justifyContent="center">
						<Text color={isEvaluable(partialCalculation) ? 'inherit' : colors['error']['main']}>
							{calculations.map((calc) => getLabel(calc)).join('')}
						</Text>
					</Grid>
					<Grid item xs={12}>
						<Box display="flex" justifyContent="flex-end">
							<Button
								startIcon={<SaveIcon fill="#fff" />}
								size="small"
								onClick={handleSubmit(handleSaveCalculation)}
								isLoading={isUpdatingTable}
								disabled={!isDirty}
							>
								Save
							</Button>
						</Box>
					</Grid>
				</Grid>
			</Box>
		</FormProvider>
	)
}
