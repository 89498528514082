import { ChainTypes } from '@cango-app/sdk/types'
import { Stack } from '@mui/material'
import { GridFilterModel } from '@mui/x-data-grid-premium'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'

import { Box, Button, Modal, Text, TextField } from 'src/components'
import { showSnackbar } from 'src/helpers/snackbarManager'
import {
	actions as projectActions,
	selectors as projectSelectors,
} from 'src/store/modules/projects-v3'

type Form = {
	ctaInstanceName: string
}

type Props = {
	onClose: () => void
	cta: ChainTypes.DatabaseCTAsChains | undefined
	filters: GridFilterModel | undefined
}

export const RfaModal = ({ onClose, cta, filters }: Props) => {
	const [isLoading, setIsLoading] = useState(false)
	const dispatch = useDispatch()
	const { control, handleSubmit } = useForm<Form>({
		defaultValues: {
			ctaInstanceName: '',
		},
	})

	const project = useSelector(projectSelectors.getSelectedProject)

	const onSubmit = async ({ ctaInstanceName }: Form) => {
		if (!project || !cta) return
		setIsLoading(true)
		try {
			await dispatch(
				projectActions.createOnDemandChain({
					projectId: project._id,
					newChainId: cta.chain,
					chainId: undefined,
					instanceName: ctaInstanceName,
					filters,
				}),
			)
		} catch (err) {
			showSnackbar('Error creating chain', { variant: 'error' })
		} finally {
			setIsLoading(false)
			onClose()
		}
	}

	return (
		<Modal open={Boolean(cta)} onClose={onClose}>
			<Box padding={2}>
				<Stack direction="column" spacing={2}>
					<Box>
						<Text variant="h5">{`Create: ${cta?.label}`}</Text>
						<Text variant="caption">
							This label will help you identify your RFA in the project.
						</Text>
					</Box>
					<Controller
						control={control}
						name="ctaInstanceName"
						render={({ field: { value, onChange } }) => (
							<TextField fullWidth label="Label" value={value} onChange={onChange} />
						)}
					/>
					<Button isLoading={isLoading} onClick={handleSubmit(onSubmit)}>
						Create
					</Button>
				</Stack>
			</Box>
		</Modal>
	)
}
