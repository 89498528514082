import React, { ComponentType, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Controller, useForm } from 'react-hook-form'
import { ContactTypes } from '@cango-app/sdk/types'
import _capitalize from 'lodash/capitalize'

import { actions as roleActions, selectors as roleSelectors } from 'src/store/modules/roles'
import { Box, Button, FormCard, Select, TextField } from 'src/components'
import { actions as contactActions } from 'src/store/modules/contacts'
import { selectors as configSelectors } from 'src/store/modules/config'
import { AsyncDispatchType, RootState } from 'src/store/types'
import { showSnackbar } from 'src/helpers/snackbarManager'

import { DeleteContact } from './delete-contact'

type ContactFormProps = {
	contact?: ContactTypes.Contact & { assignments?: number }
	closeDrawer?: () => void
	setNewContactId: (id: string) => void
	forTaskId?: string
	isSaving?: boolean
}

const deleteBtnText = (contact: ContactTypes.Contact & { assignments?: number }): string => {
	if (contact.type === ContactTypes.ContactType.Internal) {
		return 'Contact is a Cango user'
	} else if (contact.assignments && contact.assignments > 0) {
		return 'Contact in use'
	} else {
		return 'Delete'
	}
}

export const ContactForm: ComponentType<ContactFormProps> = ({
	contact,
	closeDrawer,
	setNewContactId,
	forTaskId,
	isSaving,
}) => {
	const dispatch = useDispatch<AsyncDispatchType>()
	const organisationId = useSelector(configSelectors.getOrganisationId)
	const roles = useSelector((state: RootState) =>
		roleSelectors.getRolesForSelect(state, organisationId ?? ''),
	)
	const [promptDelete, setShouldPromptDelete] = useState(false)
	const [isUpdating, setIsUpdating] = useState(false)
	const { handleSubmit, control } = useForm<ContactTypes.Contact>({
		defaultValues: contact,
	})

	useEffect(() => {
		if (!roles.length) dispatch(roleActions.getRoles())
	}, [])

	const filteredRoles = useMemo(
		() => roles.filter(({ _id, internal }) => !internal || contact?.roles.includes(_id)),
		[roles, contact],
	)

	const handleCloseDrawer = () => {
		if (!closeDrawer) return
		closeDrawer()
	}

	const onSubmit = async ({ _id, ...contactData }: ContactTypes.Contact) => {
		setIsUpdating(true)
		let responseStatus: 'fulfilled' | 'rejected'
		if (contact?._id) {
			const response = await dispatch(contactActions.updateContact({ _id, update: contactData }))
			responseStatus = response.meta.requestStatus
		} else {
			const response = await dispatch(contactActions.createContact({ ...contactData, forTaskId }))
			responseStatus = response.meta.requestStatus
			if (
				response.meta.requestStatus === 'fulfilled' &&
				response.payload &&
				typeof response.payload !== 'string'
			) {
				const newContact = response.payload
				setNewContactId(newContact._id)
				showSnackbar('Contact created', { variant: 'success' })
			}
		}
		setIsUpdating(false)
		if (responseStatus === 'fulfilled') {
			handleCloseDrawer()
		}
	}

	const internalContact = contact?.type === ContactTypes.ContactType.Internal

	if (promptDelete && contact) {
		return <DeleteContact contact={contact} closeDrawer={handleCloseDrawer} />
	}

	return (
		<form>
			<FormCard title="Details">
				<Box display={'flex'} sx={{ mt: 1, flexDirection: { mobile: 'column', laptop: 'row' } }}>
					<Controller
						name={'name'}
						control={control}
						rules={{ required: true }}
						render={({ field: { onChange, value }, fieldState: { error } }) => (
							<TextField
								disabled={internalContact}
								error={!!error}
								required
								fullWidth
								onChange={onChange}
								value={value}
								label={'Name'}
								containerProps={{ mb: 3, mr: { laptop: 1 }, flex: 1 }}
							/>
						)}
					/>
					<Controller
						name={'surname'}
						control={control}
						rules={{ required: true }}
						render={({ field: { onChange, value }, fieldState: { error } }) => (
							<TextField
								disabled={internalContact}
								error={!!error}
								onChange={onChange}
								value={value}
								fullWidth
								label={'Surname'}
								containerProps={{ mb: 3, ml: { laptop: 1 }, flex: 1 }}
							/>
						)}
					/>
				</Box>
				<Controller
					name={'type'}
					control={control}
					rules={{ required: true }}
					render={({ field: { onChange, value }, fieldState: { error } }) => (
						<Select
							error={!!error}
							required
							label="What type of Contact is this?"
							disabled={internalContact}
							onChange={(event) => onChange(event.target.value as ContactTypes.ContactType)}
							value={value}
							options={Object.values(ContactTypes.ContactType)
								.filter((item) => {
									if (value === ContactTypes.ContactType.Internal) {
										return true
									} else {
										return item !== ContactTypes.ContactType.Internal
									}
								})
								.map((item) => ({
									_id: item,
									label: _capitalize(item),
								}))}
							containerProps={{ mb: 3 }}
						/>
					)}
				/>
				<Controller
					name={'roles'}
					control={control}
					render={({ field: { onChange, value }, fieldState: { error } }) => (
						<Select
							disabled={internalContact}
							error={!!error}
							multiple
							multiline
							label="Which Role(s) does this Contact perform?"
							onChange={(event) => onChange(event.target.value)}
							value={value ?? []}
							options={filteredRoles}
						/>
					)}
				/>
			</FormCard>
			<FormCard title="Contact Info" sx={{ mt: 3 }}>
				<Controller
					name={'email'}
					control={control}
					render={({ field: { onChange, value }, fieldState: { error } }) => (
						<TextField
							disabled={internalContact}
							error={!!error}
							fullWidth
							onChange={onChange}
							value={value}
							label={'Email Address'}
							containerProps={{ mb: 3, mt: 1 }}
							type="email"
						/>
					)}
				/>
				<Controller
					name={'phone'}
					control={control}
					render={({ field: { onChange, value }, fieldState: { error } }) => (
						<TextField
							disabled={internalContact}
							error={!!error}
							fullWidth
							onChange={onChange}
							value={value}
							label={'Phone Number'}
							containerProps={{ mb: 3 }}
						/>
					)}
				/>
			</FormCard>
			{!internalContact && (
				<FormCard title="Address" sx={{ mt: 3 }}>
					<Controller
						name={'company'}
						control={control}
						render={({ field: { onChange, value }, fieldState: { error } }) => (
							<TextField
								error={!!error}
								fullWidth
								onChange={onChange}
								value={value}
								label={'Company'}
								containerProps={{ mb: 3, mt: 1 }}
							/>
						)}
					/>
					<Controller
						name={'address.line1'}
						control={control}
						render={({ field: { onChange, value }, fieldState: { error } }) => (
							<TextField
								error={!!error}
								fullWidth
								onChange={onChange}
								value={value}
								label={'Address Line 1'}
								sx={{ mb: 1 }}
							/>
						)}
					/>
					<Controller
						name={'address.line2'}
						control={control}
						render={({ field: { onChange, value }, fieldState: { error } }) => (
							<TextField
								error={!!error}
								fullWidth
								onChange={onChange}
								value={value}
								label={'Address Line 2'}
								sx={{ mb: 1 }}
							/>
						)}
					/>
					<Controller
						name={'address.line3'}
						control={control}
						render={({ field: { onChange, value }, fieldState: { error } }) => (
							<TextField
								error={!!error}
								fullWidth
								onChange={onChange}
								value={value}
								label={'Address Line 3'}
							/>
						)}
					/>
				</FormCard>
			)}
			<Box sx={{ mt: 2 }}>
				<Button variant="text" onClick={handleCloseDrawer}>
					Cancel
				</Button>
			</Box>
			<Box sx={{ mt: 1 }} display="flex">
				{!internalContact && (
					<Button
						variant="contained"
						onClick={handleSubmit(onSubmit)}
						isLoading={isUpdating || isSaving}
					>
						{contact ? 'Update' : 'Create'}
					</Button>
				)}
				{!!contact && (
					<Button
						onClick={() => setShouldPromptDelete(true)}
						variant="outlined"
						color="error"
						disabled={
							contact.type === ContactTypes.ContactType.Internal ||
							(!!contact.assignments && contact.assignments > 0)
						}
						sx={{ ml: 2 }}
					>
						{deleteBtnText(contact)}
					</Button>
				)}
			</Box>
		</form>
	)
}
