import { createSelector } from '@reduxjs/toolkit'
import { ContactTypes } from '@cango-app/sdk/types'
import sortBy from 'lodash/sortBy'
import Chip from '@mui/material/Chip'

import { selectors as roleSelectors } from '../roles'
import type { RootState } from '../../types'

import { Contact } from './types'

const getContactsState = (state: RootState) => state.cangoContacts

const getContacts: (state: RootState) => Contact[] = createSelector(
	getContactsState,
	({ contacts }) => contacts,
)

const getContactById = (state: RootState, contactId: string): Contact | undefined => {
	const contacts = getContacts(state)
	return contacts.find(({ _id }) => _id === contactId)
}

export type ContactForSelect = Contact & { label: string }

const getContactsForSelect: (state: RootState) => ContactForSelect[] = createSelector(
	getContacts,
	(contacts) => {
		const contactsOptions = contacts.map((contact) => ({
			...contact,
			label: `${contact.name} ${contact.surname}`,
		}))

		return [...sortBy(contactsOptions, 'label')]
	},
)

const getContactsForSelectDictionary: (state: RootState) => { [_id: string]: string } =
	createSelector(getContactsForSelect, (contacts) => {
		const contactsObject = contacts.reduce(
			(acc, contact) => {
				acc[contact._id] = contact.label
				return acc
			},
			{} as { [_id: string]: string },
		)

		return contactsObject
	})

type ContactWithRoleChips = Contact & {
	label: JSX.Element
}

const getContactsWithRoleChips: (
	state: RootState,
	organisationId: string,
) => ContactWithRoleChips[] = createSelector(
	getContacts,
	(state: RootState, organisationId: string) => roleSelectors.getMappedRoles(state, organisationId),
	(contacts, mappedRoles) => {
		const contactsObject = contacts.reduce((acc: ContactWithRoleChips[], contact) => {
			const roles = contact.roles.map((role) => (
				<Chip
					key={role}
					label={mappedRoles.get(role)?.label}
					size="small"
					style={{ marginLeft: 4 }}
				/>
			))
			return [
				...acc,
				{
					...contact,
					label: (
						<>
							{contact.name} {contact.surname}
							<div>{roles}</div>
						</>
					),
				},
			]
		}, [])

		return contactsObject
	},
)

const getContactsForSelectWithUnassigned: (state: RootState) => { label: string; _id: string }[] =
	createSelector(getContactsForSelect, (contacts) => [
		...contacts,
		{ label: 'Unassigned', _id: 'unassigned' },
	])

const mappedContacts: (state: RootState) => Map<string, ContactTypes.Contact> = createSelector(
	getContacts,
	(contacts) => new Map(contacts.map((contact) => [contact._id, contact])),
)

export const selectors = {
	getContacts,
	getContactById,
	getContactsForSelect,
	getContactsForSelectWithUnassigned,
	getContactsWithRoleChips,
	getContactsForSelectDictionary,
	mappedContacts,
}
