import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material'
import { ComponentType, useContext, useEffect, useMemo, useState } from 'react'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined'
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined'
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined'
import _isNumber from 'lodash/isNumber'
import {
	GridRowId,
	GridRowTreeConfig,
	GridValidRowModel,
	isAutogeneratedRow,
	useGridApiContext,
} from '@mui/x-data-grid-premium'
import _isEmpty from 'lodash/isEmpty'
import { TableTypes } from '@cango-app/sdk/types'
import { useSelector } from 'react-redux'
import { v4 } from 'uuid'
import CopyIcon from '@mui/icons-material/CopyAll'
import clipboard from 'clipboardy'
import _clamp from 'lodash/clamp'

import { Button } from 'src/components'
import { TableContext } from 'src/providers/table-provider'
import { showSnackbar } from 'src/helpers/snackbarManager'
import { getGroupedValue } from 'src/components/display-view/get-group-id'
import { selectors as projectSelectors } from 'src/store/modules/projects-v3'

import useConfirmGroupDuplication from '../../hooks/use-confirm-group-duplication'

import { COPY_CLIPBOARD, handleSpecialPasteFromClipboard } from './right-click-menu/utils'

type ActionsButtonProps = {
	permittedFunctions?: TableTypes.ActionFunction[]
	groupedFields?: TableTypes.GroupedField[]
}

const getChildRows = ({ tree, rowId }: { tree: GridRowTreeConfig; rowId: string }): string[] => {
	const childRowIds: string[] = []
	const row = tree[rowId]

	if (row.type === 'leaf') {
		childRowIds.push(rowId)
	}

	if (row.type === 'group') {
		const rowChildren = row.children.map((childId) =>
			getChildRows({ tree, rowId: childId as string }),
		)
		childRowIds.push(...rowChildren.flat())
	}

	return childRowIds
}

export const ActionsButton: ComponentType<ActionsButtonProps> = ({
	permittedFunctions,
	groupedFields = [],
}) => {
	const {
		updateRecords,
		onAddRow,
		onDeleteRecords,
		mappedRowData,
		mappedRecords,
		mappedColumns,
		isUpdatingTable,
		cacheRowUpdates,
	} = useContext(TableContext)
	const apiRef = useGridApiContext()
	const [NewNameDialog, confirmGroupName] = useConfirmGroupDuplication()
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
	const open = Boolean(anchorEl)
	const selectedCells = apiRef.current.getCellSelectionModel()
	const selectedCellsArray = apiRef.current.getSelectedCellsAsArray()
	const selectedRows = apiRef.current.getSelectedRows()
	const selectedProjectId = useSelector(projectSelectors.getSelectedProjectId)
	const hasCellsSelected = !_isEmpty(selectedCells)
	const hasRowsSelected = selectedRows.size > 0

	const hasOnlyOptionsSelected = useMemo(() => {
		return selectedCellsArray.every(({ field }) => {
			const column = mappedColumns.get(field)
			return column?.type === TableTypes.FieldType.OPTIONS
		})
	}, [selectedCellsArray])

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget)
	}
	const handleClose = () => {
		setAnchorEl(null)
	}

	const handleCopyOptions = () => {
		const selectedCellsArray = apiRef.current.getSelectedCellsAsArray()
		const selectedOptions = selectedCellsArray.reduce(
			(_options: TableTypes.ListOption[], { field, id }) => {
				const record = mappedRecords.get(id as string)
				if (!record) {
					return _options
				}
				const options = record.data[field]
				return [..._options, options as TableTypes.ListOption]
			},
			[],
		)

		const rowsToCopy: COPY_CLIPBOARD = {
			type: 'questionnaire_options',
			data: selectedOptions,
		}
		clipboard.write(JSON.stringify(rowsToCopy))
		showSnackbar('Options copied to clipboard', { variant: 'success' })
	}

	const handleAddRow = async (where: 'above' | 'below') => {
		const selectedRows = apiRef.current.getSelectedRows()
		const rows = apiRef.current.getSortedRows()
		const selectedIndex = rows.findIndex(({ _id }) => selectedRows.has(_id))
		if (selectedIndex === -1) {
			return
		}

		const position = where === 'below' ? selectedIndex + 1 : selectedIndex
		const validPosition = _isNumber(position) ? _clamp(position, 0, rows.length) : rows.length
		const rowIds = rows.map(({ _id }) => _id as string)
		const newRowId = v4()
		rowIds.splice(validPosition, 0, newRowId)
		await onAddRow({ newRowId }, rowIds)
		apiRef.current.setRowSelectionModel([])
	}

	const handleDuplicateRows = async () => {
		if (!selectedRows.size) {
			return
		}

		const groupValues = [...selectedRows.keys()].reduce((_rowIds: string[], _rowId) => {
			const row = apiRef.current.getRow(_rowId)
			if (isAutogeneratedRow(row)) {
				return [..._rowIds, _rowId as string]
			}
			return _rowIds
		}, [])

		if (groupValues.length && !groupedFields.length) {
			return
		}

		if (groupValues.length) {
			for (const groupKey of groupValues) {
				const currentDepth = apiRef.current.state.rows.tree[groupKey].depth
				const viewGroup = groupedFields[currentDepth]
				if (!viewGroup) {
					return
				}
				const currentGroupName = getGroupedValue(groupKey)

				const newGroupName = await confirmGroupName(currentGroupName, viewGroup.groupColumnId)
				if (!newGroupName.name || newGroupName.state === 'rejected') {
					return
				}
				const childRows = getChildRows({ tree: apiRef.current.state.rows.tree, rowId: groupKey })
				const rows = apiRef.current.getAllRowIds() as string[]
				const highestSelectedIndex = rows.reduce((_highestIndex: number, _rowId, index) => {
					if (childRows.some((_id) => _rowId === _id) && index > _highestIndex) {
						return index
					}
					return _highestIndex
				}, -1)
				if (highestSelectedIndex === -1) {
					return
				}
				const duplicatedRows: TableTypes.TableRow[] = childRows.reduce(
					(_records: TableTypes.TableRow[], _key) => {
						const record = mappedRecords.get(_key as string)
						if (!record) {
							return _records
						}
						return [
							..._records,
							{
								...record,
								data: {
									...record.data,
									[viewGroup.groupColumnId]: newGroupName.name,
								},
								_id: v4(),
							},
						]
					},
					[],
				)
				const duplicateRowIds = duplicatedRows.map(({ _id }) => _id)
				const position = highestSelectedIndex + 1
				rows.splice(position, 0, ...duplicateRowIds)
				await updateRecords({
					rows: [],
					newRows: duplicatedRows,
					save: true,
					projectId: selectedProjectId,
					// TODO handle duplicate with row order
					// config: {
					// 	row_order: rows,
					// },
				})
			}
		} else {
			const selectedRows = apiRef.current.getSelectedRows()
			const rows = apiRef.current.getSortedRows()
			const highestSelectedIndex = rows.reduce((_highestIndex: number, _row, index) => {
				if (selectedRows.has(_row._id) && index > _highestIndex) {
					return index
				}
				return _highestIndex
			}, -1)
			if (highestSelectedIndex === -1) {
				return
			}
			const duplicatedRows: TableTypes.TableRow[] = [...selectedRows.keys()].reduce(
				(_records: TableTypes.TableRow[], _key) => {
					const record = mappedRecords.get(_key as string)
					if (!record) {
						return _records
					}
					return [
						..._records,
						{
							...record,
							_id: v4(),
						},
					]
				},
				[],
			)
			const duplicateRowIds = duplicatedRows.map(({ _id }) => _id)
			const rowIds = rows.map(({ _id }) => _id as string)
			const position = highestSelectedIndex + 1
			rowIds.splice(position, 0, ...duplicateRowIds)
			await updateRecords({
				rows: [],
				newRows: duplicatedRows,
				save: true,
				// TODO handle duplicated row order
				// config: {
				// 	row_order: rowIds,
				// },
				projectId: selectedProjectId,
			})
		}
		apiRef.current.setRowSelectionModel([])
	}

	const handleDeleteRecords = async () => {
		let rowsForDeletion = selectedRows
		if (hasCellsSelected) {
			rowsForDeletion = new Map(
				Object.keys(selectedCells).reduce((_acc: Map<GridRowId, GridValidRowModel>, key) => {
					const row = mappedRowData.get(key)
					if (!row) {
						return _acc
					}
					_acc.set(row._id, row)
					return _acc
				}, new Map()),
			)
		}
		await onDeleteRecords(rowsForDeletion)
		apiRef.current.setRowSelectionModel([])
		apiRef.current.setCellSelectionModel({})
	}

	const handlePasteFromClipboard = async () => {
		const selectedCellsArray = apiRef.current.getSelectedCellsAsArray()
		const updatedRows = await handleSpecialPasteFromClipboard(
			mappedRecords,
			selectedCellsArray,
			selectedProjectId,
		)
		if (!updatedRows) {
			showSnackbar('No selected rows', { variant: 'error' })
		}
		cacheRowUpdates(updatedRows)
	}

	useEffect(() => {
		if (hasRowsSelected && hasCellsSelected) {
			apiRef.current.setCellSelectionModel({})
		}
	}, [hasCellsSelected, hasRowsSelected])

	return (
		<>
			{NewNameDialog}
			<Button
				aria-controls={open ? 'actions-menu-options' : undefined}
				aria-haspopup="true"
				aria-expanded={open ? 'true' : 'false'}
				variant="text"
				disabled={(!hasRowsSelected && !hasCellsSelected) || isUpdatingTable}
				onClick={handleClick}
			>
				Actions
			</Button>
			<Menu
				id="actions-menu-options"
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				onClick={handleClose}
			>
				{(!permittedFunctions ||
					permittedFunctions.includes(TableTypes.ActionFunction.ADD_ROW_ABOVE)) &&
					selectedRows.size === 1 && (
						<MenuItem onClick={() => handleAddRow('above')}>
							<ListItemIcon>
								<KeyboardArrowUpOutlinedIcon />
							</ListItemIcon>
							<ListItemText>Add row above</ListItemText>
						</MenuItem>
					)}
				{(!permittedFunctions ||
					permittedFunctions.includes(TableTypes.ActionFunction.ADD_ROW_BELOW)) &&
					selectedRows.size === 1 && (
						<MenuItem onClick={() => handleAddRow('below')}>
							<ListItemIcon>
								<KeyboardArrowDownOutlinedIcon />
							</ListItemIcon>
							<ListItemText>Add row below</ListItemText>
						</MenuItem>
					)}
				{(!permittedFunctions ||
					permittedFunctions.includes(TableTypes.ActionFunction.DUPLICATE_ROW)) &&
					hasRowsSelected && (
						<MenuItem onClick={handleDuplicateRows}>
							<ListItemIcon>
								<ContentCopyOutlinedIcon />
							</ListItemIcon>
							<ListItemText>Duplicate row(s)</ListItemText>
						</MenuItem>
					)}

				{(!permittedFunctions ||
					permittedFunctions.includes(TableTypes.ActionFunction.DELETE_ROW)) && (
					<MenuItem>
						<ListItemIcon>
							<DeleteIcon />
						</ListItemIcon>
						<ListItemText onClick={handleDeleteRecords}>Delete row(s)</ListItemText>
					</MenuItem>
				)}
				{!permittedFunctions && (
					<MenuItem>
						<ListItemIcon></ListItemIcon>
						<ListItemText onClick={handlePasteFromClipboard}>Paste from clipboard</ListItemText>
					</MenuItem>
				)}
				{hasOnlyOptionsSelected && (
					<MenuItem>
						<ListItemIcon>
							<CopyIcon />
						</ListItemIcon>
						<ListItemText onClick={handleCopyOptions}>
							<ListItemText>Copy options</ListItemText>
						</ListItemText>
					</MenuItem>
				)}
			</Menu>
		</>
	)
}
