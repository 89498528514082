import { ProjectTypes, TaskTypes } from '@cango-app/sdk/types'
import { createSelector } from '@reduxjs/toolkit'
import _values from 'lodash/values'
import _orderBy from 'lodash/orderBy'
import _map from 'lodash/map'
import { FilesSdk, V3ProjectSdk } from '@cango-app/sdk/api'
import _uniqBy from 'lodash/uniqBy'

import { constructProjectSectionsWithTasks } from 'src/helpers/chains'

import { selectors as userSelectors } from '../user/selectors'
import { selectors as persistedConfigSelectors } from '../persisted-config/selectors'
import { RootState } from '../../types'

import { ProjectFilesState, TaskListType } from './types'

const getProjectsState: (state: RootState) => RootState['cangoProjectsV3'] = createSelector(
	(state: RootState) => state.cangoProjectsV3,
	(projectsState) => projectsState,
)

const getCards: (state: RootState) => V3ProjectSdk.Card[] = createSelector(
	getProjectsState,
	({ projectCards }) => projectCards,
)

const getMenuChips = createSelector(getCards, (cards) =>
	cards.map(({ _id, name, active }) => ({ _id, name, active })),
)

const getProjects = createSelector(getProjectsState, ({ allProjects }) => allProjects || {})

const getProjectlist = createSelector(getProjects, (projects) => _values(projects))

const getProjectsListForSelect = createSelector(getProjectlist, (projectList) => {
	const filteredForSelect = _map(projectList, ({ _id, name }) => ({ _id, label: name }))
	return _orderBy(filteredForSelect, 'label', 'asc')
})

const getSelectedProject = createSelector(getProjectsState, ({ project }) => {
	return project
})

const getSelectedProjectId = createSelector(getProjectsState, ({ project }) => {
	return project?._id
})

const getProjectCachedAnswers = createSelector(
	getProjectsState,
	({ cachedQuestionnaireAnswers }) => cachedQuestionnaireAnswers,
)

const getSelectedProjectTableId = createSelector(getSelectedProject, (project) => {
	return project?.table
})

const getProjectTasks: (state: RootState, returnAll?: boolean) => TaskTypes.PopulatedTask[] =
	createSelector(
		getProjectsState,
		userSelectors.getCurrentUserId,
		(state: RootState, returnAll?: boolean) => returnAll ?? true,
		({ tasks }, userId, returnAll) => {
			if (returnAll) {
				return tasks
			}
			return tasks.filter(({ assignees }) => assignees.some(({ user }) => userId === user))
		},
	)

const getActiveTasksForUser = createSelector(
	(state: RootState) => getProjectTasks(state, false),
	(tasks) => {
		return tasks.filter(({ lifecycle }) => !lifecycle.complete)
	},
)

const collectChainIds = (
	chain: TaskTypes.PopulatedTask['chain'],
	chainIds: Set<string> = new Set(),
): Set<string> => {
	if (!chain || !chain._id) {
		return chainIds
	}

	chainIds.add(chain._id)

	for (const parentChain of chain.parent_chains ?? []) {
		chainIds.add(parentChain._id)
	}

	return chainIds
}

const getProjectTasksInChain: (
	state: RootState,
	task: TaskTypes.PopulatedTask | undefined,
) => TaskTypes.PopulatedTask[] = createSelector(
	(state: RootState) => getProjectTasks(state),
	(state: RootState, task?: TaskTypes.PopulatedTask) => task,
	(tasks, selectedTask) => {
		if (!selectedTask) {
			return []
		}

		const relevantChainIds = collectChainIds(selectedTask.chain)

		return tasks.filter((task) => {
			if (!task.chain || !task.chain._id) {
				return true
			}

			return relevantChainIds.has(task.chain._id)
		})
	},
)

const getProjectTaskAscendants: (
	state: RootState,
	task: TaskTypes.PopulatedTask | undefined,
) => TaskTypes.PopulatedTask[] = createSelector(
	(state: RootState) => getProjectTasks(state),
	(state: RootState, task?: TaskTypes.PopulatedTask) => task,
	(tasks, selectedTask) => {
		if (!selectedTask) {
			return []
		}

		const getTaskParents = (task: TaskTypes.PopulatedTask): TaskTypes.PopulatedTask[] => {
			if (!task.parent) {
				return []
			}

			const parent = tasks.find((_task) => _task._id === task.parent?._id)
			if (!parent) {
				return []
			}

			return [parent, ...getTaskParents(parent)]
		}

		return getTaskParents(selectedTask)
	},
)

const getProjectSteps = createSelector(
	(state: RootState) => getProjectsState(state).project_steps,
	(projectSteps) => {
		return _uniqBy(projectSteps, '_id')
	},
)

const getChainStarters = createSelector(
	(state: RootState) => getProjectsState(state).chain_starters,
	(chainStarters) => {
		return _uniqBy(chainStarters, '_id')
	},
)

const getTaskDescendants: (state: RootState, taskId?: string) => TaskTypes.PopulatedTask[] =
	createSelector(
		(state: RootState) => getProjectTasks(state),
		(state: RootState, taskId?: string) => taskId,
		(tasks, taskId) => {
			let offspring: string[] = []
			if (!taskId) return []

			const recursivelyGetChildren = (parentId: string) => {
				const parentTask = tasks.find(({ _id }) => _id === parentId)
				const children = tasks
					.filter(({ parent }) => {
						if (parentTask?.isMultiUse) return false
						// use step infrastructure to get children
						// if no step_id, use parent
						return parent?._id === parentId
					})
					.map(({ _id }) => _id)
				if (!children.length) return []
				offspring = [...offspring, ...children]
				children.map(recursivelyGetChildren)
			}

			recursivelyGetChildren(taskId)

			return tasks.filter(({ _id }) => offspring.includes(_id))
		},
	)

const getSelectedTask: (state: RootState, taskId?: string) => TaskTypes.PopulatedTask | undefined =
	createSelector(
		(state: RootState, taskId?: string) => taskId,
		(state: RootState) => getProjectTasks(state),
		(state: RootState) => state,
		(selectedTaskId, projectTasks, state) => {
			const task = projectTasks.find(({ _id }) => _id === selectedTaskId)
			if (!task) return
			const taskDescendants = getTaskDescendants(state, selectedTaskId).filter(
				({ parent, isMultiUse }) => !isMultiUse && parent?._id === selectedTaskId,
			)
			return {
				...task,
				isCompletable: taskDescendants.every(({ lifecycle }) => lifecycle.complete),
			}
		},
	)

const getMappedProjectTasks: (state: RootState) => Map<string, TaskTypes.PopulatedTask> =
	createSelector(getProjectTasks, (projectTasks) => {
		return new Map(projectTasks.map((task) => [task._id, task]))
	})

export const getParentTasksOfTask: (
	state: RootState,
	taskId?: TaskTypes.PopulatedTask,
) => TaskTypes.PopulatedTask[] = createSelector(
	getMappedProjectTasks,
	(state: RootState, task?: TaskTypes.PopulatedTask) => task,
	(projectTasks, task) => {
		if (!task) {
			return []
		}
		const getParentTasks = (
			_task: TaskTypes.PopulatedTask,
			visitedTasks = new Set<string>(),
		): TaskTypes.PopulatedTask[] => {
			if (visitedTasks.has(_task._id)) {
				return []
			}
			visitedTasks.add(_task._id)
			if (!_task.parent) {
				return []
			}
			const parentTask = projectTasks.get(_task.parent._id)
			if (!parentTask) {
				return []
			}

			const parentTasks: TaskTypes.PopulatedTask[] = [parentTask]
			if (parentTask.parent) {
				const parentOfParentTasks = getParentTasks(parentTask, visitedTasks)
				parentTasks.push(...parentOfParentTasks)
			}

			return parentTasks
		}

		return getParentTasks(task)
	},
)

const isChatModalOpen = createSelector(
	getProjectsState,
	(myTasksState) => myTasksState.isChatModalOpen,
)

export interface ProjectSection {
	_id: TaskTypes.PopulatedTask['_id']
	step_id?: string
	name: string
	tasks: TaskTypes.PopulatedTask[]
	chain: TaskTypes.PopulatedTask['chain'] | undefined
	isBlocked: boolean
	projectId: string
}

const getProjectSectionsWithTasksSelector: (
	state: RootState,
	returnAllTasks?: boolean,
) => ProjectSection[] = createSelector(
	getSelectedProject,
	getProjectTasks,
	persistedConfigSelectors.getProjectGroupings,
	userSelectors.getCurrentUserId,
	(state: RootState, returnAllTasks?: boolean) => returnAllTasks ?? true,
	(project, tasks, projectGrouping, userId, returnAll) => {
		return constructProjectSectionsWithTasks({
			tasks,
			groupBy: projectGrouping[project?._id ?? ''],
			returnAll,
			userId,
		})
	},
)

const getTask: (
	state: RootState,
	projectId: string,
	taskId?: string,
) => TaskTypes.PopulatedTask | undefined = createSelector(
	getMappedProjectTasks,
	getTaskDescendants,
	(_: RootState, __: string, taskId?: string) => taskId,
	(mappedTasks, taskDescendants, taskId) => {
		const task = mappedTasks.get(taskId || '')
		if (!task) return
		const taskChildren = taskDescendants.filter(({ isMultiUse }) => !isMultiUse)
		return {
			...task,
			isCompletable: taskChildren.every(({ lifecycle }) => lifecycle.complete),
		}
	},
)

const getDriveId = createSelector(getSelectedProject, (project) => project?.google_drive_folder_id)

const getProjectFiles: (state: RootState) => FilesSdk.GetFilesByFolderIdResponse = createSelector(
	getProjectsState,
	({ projectFiles }) => projectFiles,
)

const getProjectFilesState: (state: RootState) => ProjectFilesState = createSelector(
	getProjectsState,
	({ projectFilesState }) => projectFilesState,
)

const getProjectSection: (state: RootState, sectionId?: string) => ProjectSection | undefined =
	createSelector(
		(state: RootState) => getProjectSectionsWithTasksSelector(state),
		(state: RootState, sectionId?: string) => sectionId,
		(sections, sectionId) => sections.find(({ _id }) => _id === sectionId),
	)

const getProjectsLoadingState = createSelector(
	getProjectsState,
	({ loadingProjectsState }) => loadingProjectsState,
)

const getProjectLoadingState = createSelector(
	getProjectsState,
	({ loadingProjectState }) => loadingProjectState,
)
const getTasksState = createSelector(getProjectsState, ({ tasksState }) => tasksState)

const getSectionTasksPendingUpload = createSelector(getProjectSection, (section) => {
	if (!section) {
		return []
	}

	return section.tasks.filter((_task) => {
		const taskElements = [..._task.actions, ..._task.attachments]
		if (!taskElements.length) {
			return false
		}
		return taskElements.some((_action) => _action.file_ids.includes(V3ProjectSdk.UPLOADING_TEXT))
	})
})

const getTaskListType: (state: RootState) => TaskListType = createSelector(
	getProjectsState,
	(myTasksState) => myTasksState.myTaskListType,
)

const getNumberOfFilesPendingUpload = createSelector(getSectionTasksPendingUpload, (tasks) => {
	return tasks.reduce((acc, task) => {
		const taskElements = [...task.actions, ...task.attachments]
		const numberOfFileIds = taskElements.reduce((_acc: number, _action) => {
			const numberOfUploading = _action.file_ids.filter(
				(_id) => _id === V3ProjectSdk.UPLOADING_TEXT,
			).length
			return _acc + numberOfUploading
		}, 0)
		return acc + numberOfFileIds
	}, 0)
})

const getTotalNumberOfFilesRequiringUpload = createSelector(
	getProjectsState,
	(state) => state.filesToUpload,
)

const getPercentageOfTasksUploaded = createSelector(
	getNumberOfFilesPendingUpload,
	getTotalNumberOfFilesRequiringUpload,
	(filesPendingUpload, totalTasksRequiringUpload) => {
		if (totalTasksRequiringUpload === 0) {
			return 100
		}

		const effectiveCompleted = totalTasksRequiringUpload - filesPendingUpload * 0.5

		const percentage = (effectiveCompleted / totalTasksRequiringUpload) * 100
		return Math.min(100, Math.max(0, percentage))
	},
)

const getBlockOptions = createSelector(
	getProjectsState,
	(myTasksState) => myTasksState.blockOptions,
)

const getIsLoadingBlockOptions = createSelector(
	getProjectsState,
	(myTasksState) => myTasksState.isLoadingBlockOptions,
)

const getProjectWideChains = createSelector(getProjectsState, (state) => state.project_wide_chains)

const getProjectDatabaseCtas = createSelector(getProjectsState, (state) => state.database_ctas)

const mappedProjectRolesWithUsers = createSelector(
	getSelectedProject,
	(project) => new Map(project?.roles.map(({ role, user }) => [role, user])),
)

const getQuestionnaireAnswers = createSelector(
	getSelectedProject,
	getProjectCachedAnswers,
	(project, cachedAnswers) => {
		const savedAnswers = project?.questionaire_answers ?? []
		return _uniqBy([...cachedAnswers, ...savedAnswers], '_id')
	},
)

const getSelectedProjectTableConfig: (
	state: RootState,
	tableId: string,
) => ProjectTypes.TableConfig | undefined = createSelector(
	getSelectedProject,
	(rootState: RootState, tableId: string) => tableId,
	(project, tableId) => {
		return project?.table_config?.[tableId]
	},
)

export const selectors = {
	getCards,
	getMenuChips,
	getSelectedProject,
	getProjectTasks,
	getTaskDescendants,
	getTask,
	getDriveId,
	getSelectedTask,
	getProjectFiles,
	getProjectFilesState,
	// getProjectSectionsWithMasterTasks,
	getProjectSectionsWithTasksSelector,
	getMappedProjectTasks,
	getProjectsListForSelect,
	getProjectsLoadingState,
	getProjectLoadingState,
	getTasksState,
	getProjectlist,
	getProjectSection,
	getSectionTasksPendingUpload,
	getTaskListType,
	getPercentageOfTasksUploaded,
	getNumberOfFilesPendingUpload,
	getTotalNumberOfFilesRequiringUpload,
	getBlockOptions,
	getIsLoadingBlockOptions,
	isChatModalOpen,
	getProjectWideChains,
	getProjectDatabaseCtas,
	mappedProjectRolesWithUsers,
	getProjectTasksInChain,
	getProjectSteps,
	getChainStarters,
	getActiveTasksForUser,
	getParentTasksOfTask,
	getProjectTaskAscendants,
	getSelectedProjectTableId,
	getQuestionnaireAnswers,
	getProjectCachedAnswers,
	getSelectedProjectId,
	getSelectedProjectTableConfig,
}
