import { ProjectTypes, TableTypes } from '@cango-app/sdk/types'
import { GridFilterModel } from '@mui/x-data-grid-premium'

import { Question } from 'src/components/question-flow/types'
import { CangoQuestionaireNode } from 'src/modules/tables/questionaire-logic/types'
import {
	ColumnFilterList,
	MappedValueOptions,
	ResolvedRowData,
	TableState,
} from 'src/store/modules/tables/types'
import { getLayout } from 'src/modules/tables/questionaire-logic/get-layout'
import { sortNodesByFields } from 'src/modules/tables/questionaire-logic/utils'
import { applyFilterModelToRow, columnValueGetter } from 'src/modules/tables/utils'

const getQuestionParents = ({
	questionId,
	visitedNodes,
	questions,
}: {
	questionId: string
	visitedNodes?: Set<string>
	questions: TableTypes.TableRow[]
}): string[] => {
	if (!visitedNodes) {
		visitedNodes = new Set<string>()
	}
	if (visitedNodes.has(questionId)) {
		return []
	}
	visitedNodes.add(questionId)
	const parents = questions.filter((_ques) =>
		_ques.descendants?.some((_desc) => _desc.row === questionId),
	)
	let parentIds = parents.map((_parent) => _parent._id)
	if (parents.length) {
		parentIds = [
			...parents
				.map((_parent) =>
					getQuestionParents({
						questionId: _parent._id,
						visitedNodes,
						questions,
					}),
				)
				.flat(),
			...parentIds,
		]
	}

	return parentIds
}

const convertQuestionTableIntoQuestions = ({
	questionTable,
	rowsAfterFilter,
	mappedColumns,
	questionScope,
	questionaireAnswers,
	acceptedQuestionColumns,
	mappedValueOptions,
}: {
	questionTable: TableState
	rowsAfterFilter: ResolvedRowData[]
	mappedColumns: Map<string, TableTypes.Field>
	questionScope: string[]
	questionaireAnswers: ProjectTypes.QuestionaireAnswer[]
	acceptedQuestionColumns: string[]
	mappedValueOptions: MappedValueOptions
}): Question[] => {
	const questionTableQuestionId = questionTable.config.principal_field ?? ''
	const questionTableOptionsId =
		questionTable.fields.find((_field) => _field.type === TableTypes.FieldType.OPTIONS)?._id ?? ''

	const topsortedQuestionNodes = getLayout({
		rows: questionTable.rows,
		questionColumnId: questionTableQuestionId,
		optionsColumnId: questionTableOptionsId,
	})

	const questionColumns = [...mappedColumns.values()].filter(
		(_col) =>
			_col.type === TableTypes.FieldType.QUESTIONAIRE_REFERENCE &&
			(!acceptedQuestionColumns.length || acceptedQuestionColumns.includes(_col._id)),
	)
	const questionColumnIds = questionColumns.map((_col) => _col._id)

	const scopeAskedQuestions = new Map<string, Set<string>>()

	const createScopeKey = (row: ResolvedRowData): string => {
		return questionScope.map((_colId) => row[_colId] || '').join('>')
	}

	return rowsAfterFilter.reduce((_questions: Question[], _row) => {
		const scopeKey = createScopeKey(_row)
		const _rowQuestions = questionColumnIds.reduce(
			(_acc: { colId: string; question: CangoQuestionaireNode }[], _colId) => {
				const questionId = _row[_colId] as string | undefined
				if (!questionId) {
					return _acc
				}

				const getQuestion = (question_id: string) => {
					return topsortedQuestionNodes.nodes.find((_node) => _node.id === question_id)
				}

				const question = getQuestion(questionId)

				if (!question) {
					return _acc
				}

				const questionParents = getQuestionParents({
					questionId,
					questions: questionTable.rows,
				})

				questionParents.forEach((_parent) => {
					const parentQuestion = getQuestion(_parent)
					if (parentQuestion) {
						_acc.push({
							colId: _colId,
							question: parentQuestion,
						})
					}
				})

				_acc.push({
					colId: _colId,
					question: question,
				})
				return _acc
			},
			[],
		)

		const constructedQuestions = _rowQuestions.reduce((_acc: Question[], _rowQuestion) => {
			const question = _rowQuestion.question
			if (questionScope.length === 0 && _acc.some((_q) => _q._id === question.id)) {
				return _acc
			}

			const questionAnsweredInHigherScope = questionaireAnswers.some((_answer) => {
				return (
					_answer.question_id === question.id &&
					_answer.scope.length < questionScope.length &&
					_answer.scope.every((_scope) => {
						return questionScope.some((_colId) => _row[_colId] === _scope.value)
					})
				)
			})

			if (questionAnsweredInHigherScope) {
				return _acc
			}

			const questionRow = questionTable.rows.find((_record) => _record._id === question.id)
			if (!scopeAskedQuestions.has(scopeKey)) {
				scopeAskedQuestions.set(scopeKey, new Set<string>())
			}

			const askedInScope = scopeAskedQuestions.get(scopeKey)
			if (!askedInScope || askedInScope.has(question.id)) {
				return _acc
			}

			askedInScope.add(question.id)

			const questionId = `${_row._id}-${_rowQuestion.colId}-${questionScope.join('-')}`

			_acc.push({
				_id: questionId,
				info: questionScope.map((_colId) => {
					const mainTableColumn = mappedColumns.get(_colId)
					if (!mainTableColumn) return ''
					return `${mainTableColumn.name}: ${columnValueGetter(_row?.[_colId], mainTableColumn, mappedValueOptions)}`
				}),
				question: question.data.label,
				type: question.data.answerConfig.answerType,
				options: question.data.answerConfig.options,
				descendants: questionRow?.descendants ?? [],
				rowId: _row._id,
				columnId: _rowQuestion.colId,
				questionRowId: question.id,
				hierarchy: questionScope.reduce((_acc: Record<string, any>, _colId) => {
					return {
						..._acc,
						[_colId]: _row[_colId],
					}
				}, {}),
			})
			return _acc
		}, [])

		return [..._questions, ...constructedQuestions]
	}, [])
}

export const getQuestionaireQuestions = ({
	answers,
	questionTable,
	filters,
	mappedColumns,
	questionScope,
	acceptedQuestionColumns,
	columnFilterList,
	records,
	mappedValueOptions,
}: {
	answers: ProjectTypes.QuestionaireAnswer[]
	questionTable: TableState
	filters: GridFilterModel
	mappedColumns: Map<string, TableTypes.Field>
	questionScope: string[]
	acceptedQuestionColumns: string[]
	columnFilterList: ColumnFilterList
	records: ResolvedRowData[]
	mappedValueOptions: MappedValueOptions
}): Question[] => {
	const rowsAfterFilter = [...(records ?? [])].filter((_row) => {
		if (!filters.items.length) return true
		return applyFilterModelToRow({
			columns: columnFilterList,
			row: _row,
			filterModel: filters,
		})
	})

	return convertQuestionTableIntoQuestions({
		questionTable: questionTable,
		rowsAfterFilter,
		mappedColumns,
		questionScope,
		questionaireAnswers: answers ?? [],
		acceptedQuestionColumns,
		mappedValueOptions,
	})
}

export function sortNodesWithDescendants(nodes: Question[], sortBy: string[]): Question[] {
	function attachDescendants(sortedList: Question[], allNodes: Question[]): Question[] {
		const result: Question[] = []

		for (const node of sortedList) {
			result.push(node) // Add the current node
			const descendants = node.descendants
				.map((descendant) => allNodes.find((n) => n._id === descendant._id))
				.filter(Boolean) as Question[] // Filter valid descendants

			if (descendants.length > 0) {
				const sortedDescendants = attachDescendants(
					sortNodesByFields(descendants, sortBy),
					allNodes,
				)
				result.push(...sortedDescendants) // Append descendants after the parent
			}
		}

		return result
	}

	const sortedNodes = sortNodesByFields(nodes, sortBy)
	return attachDescendants(sortedNodes, nodes)
}
