import { createSelector } from '@reduxjs/toolkit'

import { selectors as authSelectors } from '../auth/selectors'
import { RootState } from '../../types'

import { RolesByOrgId, RoleWithUsage } from './types'

const getRolesState = (state: RootState) => state.cangoRoles

const getRoles: (state: RootState) => RolesByOrgId = createSelector(
	getRolesState,
	({ roles }) => roles,
)

const getRolesByOrgId: (state: RootState, organisationId?: string) => RoleWithUsage[] =
	createSelector(
		getRoles,
		authSelectors.getOrganisationId,
		(_state: RootState, overrideOrganisationId?: string) => overrideOrganisationId,
		(roles, selectedOrganisationId, overrideOrganisationId) => {
			return roles[overrideOrganisationId ?? selectedOrganisationId ?? ''] ?? []
		},
	)

const getRoleById = (
	state: RootState,
	organisationId: string,
	roleId?: string,
): RoleWithUsage | undefined => {
	if (!roleId) return undefined
	const roles = getRoles(state)
	if (!organisationId) return undefined
	return roles[organisationId].find(({ _id }) => _id === roleId)
}

const getRolesForSelect: (
	state: RootState,
	organisationId: string,
	internalOnly?: boolean,
) => RoleWithUsage[] = createSelector(
	getRolesByOrgId,
	(state: RootState, organisationId: string, internalOnly?: boolean) => !!internalOnly,
	(rolesByOrganisationId, internalOnly) => {
		let rolesForSelect = rolesByOrganisationId
		if (internalOnly) {
			rolesForSelect = rolesByOrganisationId.filter(({ internal }) => internal)
		}
		return rolesForSelect.map((role) => ({
			...role,
			value: role._id as string,
		}))
	},
)

const getMappedRoles = createSelector(
	getRolesByOrgId,
	(roles) => new Map(roles.map((role) => [role._id, role])),
)

export const selectors = {
	getRoles,
	getRolesByOrgId,
	getRoleById,
	getRolesForSelect,
	getMappedRoles,
}
