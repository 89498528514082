import { AuthSdk } from '@cango-app/sdk/api'
import { AxiosError } from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/react'

import { CangoReduxModuleName, type RootState } from '../../types'

import { selectors } from './selectors'
import { CangoSchemaState } from './types'

export const updateSchema = createAsyncThunk<
	{ organisationId: string; newToken: string },
	string,
	{ rejectValue: CangoSchemaState; state: RootState }
>('config/update-schema', async (organisationId, { rejectWithValue, getState }) => {
	try {
		const state = getState()
		const organisationId =
			selectors.getSelectedOrganisationId(state) ??
			state[CangoReduxModuleName.CangoAuth].defaultOrganisationId

		if (!organisationId) {
			throw new Error('No organisation selected')
		}

		const organisation = state[CangoReduxModuleName.CangoAuth].userOrganisations[organisationId]

		const headers = {
			RUID: organisationId,
			Authorization: `Bearer ${organisation?.jwt || ''}`,
		}

		const response = await AuthSdk.updateApp(import.meta.env.VITE_API as string, headers)
		return {
			organisationId,
			newToken: response.newToken,
		}
	} catch (error) {
		Sentry.captureException(error)
		const axiosMessage = (error as AxiosError<{ message: string }>).response?.data?.message

		if (axiosMessage === AuthSdk.AuthErrorMessages.MigrationInProgress) {
			return rejectWithValue(CangoSchemaState.MigrationInProgress)
		}

		return rejectWithValue(CangoSchemaState.Failed)
	}
})
