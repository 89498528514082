import React, { ComponentType } from 'react'
import { useDispatch } from 'react-redux'
import { RoleTypes } from '@cango-app/sdk/types'

import { Box, Button, Text } from 'src/components'
import { actions as roleActions } from 'src/store/modules/roles'

type DeleteRoleProps = {
	closeDrawer: () => void
	role: RoleTypes.ClientRole
}

export const DeleteRole: ComponentType<DeleteRoleProps> = ({ role, closeDrawer }) => {
	const dispatch = useDispatch()

	const handleDeleteRole = () => {
		dispatch(roleActions.deleteRole(role._id))
		closeDrawer()
	}

	return (
		<Box width={400} p={3} role="presentation">
			<Text sx={{ mb: 3 }} variant="h4">
				{role.label}
			</Text>
			<Button onClick={handleDeleteRole} variant="contained" color="error">
				Delete Role
			</Button>
		</Box>
	)
}
