import {
	DataGridPremium,
	DataGridPremiumProps,
	GRID_AGGREGATION_FUNCTIONS,
	GridAggregationFunction,
	GridCellCoordinates,
	GridCellParams,
	GridColDef,
	GridColumnVisibilityModel,
	GridFilterModel,
	GridInitialState,
	GridRowId,
	GridSlotsComponentsProps,
	GridValidRowModel,
	useGridApiRef,
} from '@mui/x-data-grid-premium'
import React, {
	ComponentType,
	MutableRefObject,
	useCallback,
	useContext,
	useMemo,
	useState,
} from 'react'
import { TableTypes } from '@cango-app/sdk/types'
import { v4 } from 'uuid'
import Papa, { ParseResult } from 'papaparse'
import clipboard from 'clipboardy'
import _uniq from 'lodash/uniq'
import Stack from '@mui/system/Stack/Stack'
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium'
import { useSelector } from 'react-redux'
import classnames from 'classnames'

import { dataGridTables } from 'src/helpers/ui'
import { selectors as persistedConfigSelectors } from 'src/store/modules/persisted-config'
import { Box, Text } from 'src/components'
import { TableContext } from 'src/providers/table-provider'
import { showSnackbar } from 'src/helpers/snackbarManager'
import { ConfirmationResolveType, SwitchChainResolveType } from 'src/hooks/use-confirm-cell-change'
import { PopulatedAnswer } from 'src/modules/tables/types'
import { selectors as authSelectors } from 'src/store/modules/auth'
import { selectors as projectSelectors } from 'src/store/modules/projects-v3'

import {
	getRowDifference,
	getRowReferences,
	isValidJSON,
	SINGLE_SELECT_FIELDS,
	stringsMatch,
} from './utils'
import { CustomColumnMenu } from './column-menu'
import { CustomToolbar } from './toolbar'
import { CustomFooter } from './footer'
import { CustomNoRowsOverlay } from './no-rows'
import { ColumnSettingsModal } from './column-settings/column-settings-modal'
import { useColumnFormatter } from './use-column-formatter'
import { ListOptionsModal } from './list-options-modal'
import { RightClickMenuActions } from './right-click-menu/right-click-menu'
import { COPY_CLIPBOARD, handleSpecialPasteFromClipboard } from './right-click-menu/utils'

type CoreTableProps = {
	apiRef?: MutableRefObject<GridApiPremium>
	rowReordering?: boolean
	hideFooter?: boolean
	onFilterChange?: (filters: GridFilterModel) => void
	onColumnFiltersChange?: (columns: GridColumnVisibilityModel) => void
	isStatic?: boolean
	onCellClick?: (data: { columnId: string; rowId: string; cellId: string }) => void
	selectedCell?: string
	isLocked?: boolean
	styles?: DataGridPremiumProps['sx']
	hideToolbar?: boolean
	hideTable?: boolean
	initialState?: GridInitialState
	hideDensitySelector?: boolean
	hideColumnSelector?: boolean
	hideFilterSelector?: boolean
	changeConfirmation?: {
		Dialog: JSX.Element
		onChangeCallback: (
			rowDifference: string[],
			newRow: GridValidRowModel,
			oldRow: GridValidRowModel,
		) => Promise<SwitchChainResolveType>
	}
	maxHeight: string
}

const slots = {
	toolbar: CustomToolbar,
	columnSortedDescendingIcon: null,
	columnSortedAscendingIcon: null,
	columnMenu: CustomColumnMenu,
	footer: CustomFooter,
	noRowsOverlay: CustomNoRowsOverlay,
	noResultsOverlay: CustomNoRowsOverlay,
}

export const cangoTableAggregationList = [
	...Object.keys(GRID_AGGREGATION_FUNCTIONS).map((agg) => ({
		_id: agg,
		label: agg,
	})),
	{ _id: 'concatenate', label: 'concatenate' },
	{
		_id: 'value',
		label: 'value',
	},
]

export const concatenateAggregation: GridAggregationFunction<string, string | null> = {
	apply: (params) => {
		if (!params.values.length) {
			return null
		}

		const removedEmptyValues = params.values.filter(Boolean)
		const uniqValues = _uniq(removedEmptyValues)
		return uniqValues.join(', ')
	},
	label: 'concatenate',
}

export const valueAggregation: GridAggregationFunction<string, any> = {
	apply: (params) => {
		if (!params.values.length) {
			return null
		}

		return params.values[0]
	},
	label: 'value',
}

export const cangoTableAggregations: Record<string, GridAggregationFunction> = {
	...GRID_AGGREGATION_FUNCTIONS,
	concatenate: concatenateAggregation,
	value: valueAggregation,
}

export const CoreTable: ComponentType<CoreTableProps> = ({
	onFilterChange,
	rowReordering = false,
	hideFooter = false,
	onColumnFiltersChange,
	isStatic,
	onCellClick,
	selectedCell,
	isLocked,
	hideToolbar,
	styles,
	initialState,
	hideColumnSelector = false,
	hideDensitySelector = false,
	hideFilterSelector = false,
	hideTable = false,
	changeConfirmation,
	maxHeight,
	apiRef: apiRefOverride,
}) => {
	const {
		tableConfig,
		isUpdatingTable,
		isLoadingTable,
		onUpdateColumn,
		updateTableConfig,
		updateRecords,
		mappedColumns,
		resolvedRows,
		mappedRecords,
		cachedRowIds,
		referenceTables,
		saveNewReferenceTables,
		mappedValueOptions,
		cacheRowUpdates,
	} = useContext(TableContext)
	const apiRefDefault = useGridApiRef()
	const apiRef = apiRefOverride ?? apiRefDefault
	const authHeaders = useSelector(authSelectors.getAuthHeaders)
	const showAnswersConfig = useSelector(persistedConfigSelectors.getAnswersConfig)
	const [filterButtonEl, setFilterButtonEl] = useState<HTMLButtonElement | null>(null)
	const [settingsModalId, setSettingsModalId] = useState<string | null>(null)
	const [selectedRow, setSelectedRow] = useState<
		| (TableTypes.TableRow & {
				columnId: string
				columnType: TableTypes.FieldType
		  })
		| null
	>(null)
	const selectedProject = useSelector(projectSelectors.getSelectedProject)
	const selectedProjectId = selectedProject?._id
	const tableDensity =
		selectedProject?.table_config?.[tableConfig?._id ?? '']?.row_density ?? tableConfig?.row_density

	const processRowUpdate: NonNullable<DataGridPremiumProps['processRowUpdate']> = async (
		newRow,
		oldRow,
	) => {
		const rowDifference = getRowDifference(oldRow, newRow).filter((_columnId) => {
			const column = mappedColumns.get(_columnId)
			if (!column) {
				return false
			}
			const rowValue = newRow[column._id]
			if (!SINGLE_SELECT_FIELDS.includes(column.type) || !rowValue) {
				return true
			}
			const valueOptions = mappedValueOptions[column._id]
			return valueOptions.has(String(rowValue))
		})

		if (!rowDifference.length) {
			return oldRow
		}
		if (changeConfirmation && rowDifference.length) {
			const answer = await changeConfirmation.onChangeCallback(rowDifference, newRow, oldRow)
			if (answer.state === ConfirmationResolveType.Rejected) {
				apiRef.current.setCellSelectionModel({})
				return oldRow
			}
		}

		const originalRecord = mappedRecords.get(newRow._id)

		if (!originalRecord) {
			return oldRow
		}
		const newRecord = { ...originalRecord }

		rowDifference.forEach((columnId) => {
			const column = mappedColumns.get(columnId)
			if (!column) {
				return
			}
			if (column?.type === TableTypes.FieldType.CALCULATION || newRecord.calculations[columnId]) {
				newRecord.overrides = {
					...(newRecord.overrides ?? {}),
					[columnId]: newRow[columnId],
				}
				return
			}
			newRecord.data[columnId] = newRow[columnId]
		})

		let newTablesToSave: Record<string, TableTypes.PopulatedCangoTable> = {}
		const { newReferenceTables, newReferences } = await getRowReferences({
			record: newRecord,
			records: [...mappedRecords.values()],
			columnsChanged: rowDifference,
			mappedColumns,
			vLookupTables: tableConfig?.vLookupTables,
			authHeaders,
			referenceTables,
			projectId: selectedProjectId,
		})

		newTablesToSave = {
			...newTablesToSave,
			...newReferenceTables,
		}

		newRecord.references = newReferences

		const rowUpdates: {
			newRow: TableTypes.TableRow
			oldRow: TableTypes.TableRow
		}[] = [
			{
				newRow: newRecord,
				oldRow: originalRecord,
			},
		]

		const selectedRows: Map<GridRowId, GridValidRowModel> = apiRef.current.getSelectedRows()

		if (selectedRows.has(newRow._id)) {
			for (const [_selectedRowId] of selectedRows) {
				const selectedRow = mappedRecords.get(String(_selectedRowId))
				if (!selectedRow || _selectedRowId === newRow._id) {
					continue
				}

				const updatedRow = { ...selectedRow }
				rowDifference.forEach((columnId) => {
					const column = mappedColumns.get(columnId)
					if (
						column?.type === TableTypes.FieldType.CALCULATION ||
						updatedRow.calculations[columnId]
					) {
						updatedRow.overrides = {
							...updatedRow.overrides,
							[columnId]: newRow[columnId],
						}
						return
					}
					updatedRow.data[columnId] = newRow[columnId]
				})

				// Await the asynchronous operation
				const { newReferenceTables: subReferenceTables, newReferences } = await getRowReferences({
					record: newRecord,
					records: [...mappedRecords.values()],
					columnsChanged: rowDifference,
					mappedColumns,
					vLookupTables: tableConfig?.vLookupTables,
					authHeaders,
					referenceTables,
					projectId: selectedProjectId,
				})

				updatedRow.references = newReferences

				newTablesToSave = {
					...newTablesToSave,
					...subReferenceTables,
				}

				rowUpdates.push({ oldRow: selectedRow, newRow: updatedRow })
			}
		}

		const [cachedValue] = await updateRecords({
			rows: rowUpdates,
			save: true,
			projectId: selectedProjectId,
		})
		saveNewReferenceTables(newReferenceTables)

		apiRef.current.setCellSelectionModel({})
		return { ...cachedValue.data, _id: cachedValue._id }
	}

	const handleTableBooleanClick = async (params: GridCellParams) => {
		const newValue = !params.value
		const column = mappedColumns.get(params.field)
		if (column?.type !== TableTypes.FieldType.CALCULATION) {
			await processRowUpdate({ ...params.row, [params.field]: newValue }, params.row, {} as any)
		} else {
			const row = mappedRecords.get(params.row._id)
			if (!row) {
				return
			}
			await updateRecords({
				rows: [
					{
						oldRow: row,
						newRow: {
							...row,
							overrides: {
								...(row.overrides ?? {}),
								[params.field]: newValue,
							},
						},
					},
				],
				save: true,
				projectId: selectedProjectId,
			})
		}
	}

	const { columns, contextMenu, setContextMenu, cell } = useColumnFormatter({
		apiRef,
		isTableLocked: !!isLocked,
		onCheckIconClick: handleTableBooleanClick,
	})

	const someAnswersWithImagesShowing = useMemo(() => {
		return Object.keys(showAnswersConfig[tableConfig?._id ?? ''] ?? {}).some((_key) => {
			if (!showAnswersConfig[tableConfig?._id ?? '']?.[_key]) return false
			return resolvedRows.some((_row) => {
				const answerKey = `${_key}_answer`
				const answers = _row[answerKey] as PopulatedAnswer[] | undefined
				if (!answers) return false
				return answers.some((_answer) => _answer.imageUrl)
			})
		})
	}, [showAnswersConfig, tableConfig?._id, resolvedRows])

	const { selectedColumnId, selectedRowId } = useMemo(() => {
		if (selectedCell) {
			const [columnId, rowId] = selectedCell.split('-')
			return {
				selectedColumnId: columnId,
				selectedRowId: rowId,
			}
		}
		return {
			selectedColumnId: '',
			selectedRowId: '',
		}
	}, [selectedCell])

	const getCellClassnames = useCallback(
		(params: GridCellParams) => {
			const isCached = cachedRowIds.has(params.row._id)
			const isSelected = selectedRowId === params.id && selectedColumnId === params.field
			const column = mappedColumns.get(params.field)
			if (!column) {
				return ''
			}
			const colType = column.type
			let hasError = false

			if (
				params.value &&
				[
					TableTypes.FieldType.TABLE_SELECT,
					TableTypes.FieldType.SINGLE_SELECT,
					TableTypes.FieldType.REFERENCE,
					TableTypes.FieldType.CONTACT,
					TableTypes.FieldType.ROLE,
					TableTypes.FieldType.QUESTIONAIRE_REFERENCE,
				].includes(colType)
			) {
				const valueOptions = mappedValueOptions[column._id]
				const rowValue = params.row[column._id]
				const record = mappedRecords.get(params.row._id)

				if (
					!record?.calculations?.[column._id] &&
					valueOptions &&
					!valueOptions.has(String(rowValue))
				) {
					hasError = true
				}
			}

			return classnames({
				'row--edited': isCached,
				'cell-checkbox': params.colDef?.type === 'boolean' && params.isEditable,
				'cell--selected': isSelected,
				'cell--options': colType === TableTypes.FieldType.OPTIONS,
				'cell--questionaire-reference': colType === TableTypes.FieldType.QUESTIONAIRE_REFERENCE,
				'cell--error': hasError,
			})
		},
		[cachedRowIds, selectedColumnId, selectedRowId, mappedColumns, mappedRecords],
	)

	const handlePasteResults = async (
		results: ParseResult<string[]>,
		originCell: GridCellCoordinates,
	) => {
		const isJSON = isValidJSON(results.data[0][0])
		let rowsByCell = results.data as string[][]
		if (isJSON) {
			const clipboardData = JSON.parse(results.data[0][0]) as COPY_CLIPBOARD
			if (clipboardData?.type !== 'questionnaire_options' || !clipboardData?.data) {
				return
			}
			rowsByCell = clipboardData.data.map((_option: TableTypes.ListOption) => [_option])
		}
		const columnId = originCell.field
		const rowId = originCell.id
		if (apiRef.current.getCellMode(rowId, columnId) === 'edit') {
			apiRef.current.stopCellEditMode({
				id: rowId as GridRowId,
				field: columnId ?? '',
				ignoreModifications: true,
			})
		}

		if (!columnId || !rowId) return
		const rowIndex = resolvedRows.findIndex((row) => row._id === rowId)
		const columnIndex = columns.findIndex((column) => column.field === columnId)
		if (rowIndex === -1 || columnIndex === -1) return

		const newRows: TableTypes.TableRow[] = []
		const updatesToCache: {
			newRow: TableTypes.TableRow
			oldRow: TableTypes.TableRow
		}[] = []

		for (let i = 0; i < rowsByCell.length; i++) {
			const _row = rowsByCell[i]
			const distanceFromPastingRow = i
			const updatedCells: GridValidRowModel = {}
			for (
				let distanceFromPastingColumn = 0;
				distanceFromPastingColumn < _row.length;
				distanceFromPastingColumn++
			) {
				const _cell = _row[distanceFromPastingColumn]
				const cellColumn = columns[columnIndex + distanceFromPastingColumn]
				const cangoColumnDef = mappedColumns.get(cellColumn.field)
				if (!cellColumn) return
				let value: any = _cell
				if (cellColumn.type === 'number') {
					value = Number(_cell.replace(/[^0-9.-]+/g, ''))
					value = cangoColumnDef?.format === TableTypes.FormatType.percentage ? value / 100 : value
				} else if (cellColumn.type === 'boolean') {
					value = _cell === 'true'
				} else if (
					cangoColumnDef?.valueOptions.length &&
					SINGLE_SELECT_FIELDS.includes(cangoColumnDef.type)
				) {
					value =
						cangoColumnDef.valueOptions.find((_valOpt) => stringsMatch(_valOpt.label, value))
							?._id ?? _cell
				}

				updatedCells[cellColumn.field] = value
			}

			const dataGridRow = resolvedRows[rowIndex + distanceFromPastingRow]
			if (!dataGridRow) {
				const id = v4()
				const newRow = { id, _id: id, ...updatedCells }
				newRows.push({
					_id: id,
					data: newRow,
					tableId: tableConfig?._id ?? '',
					organisationId: tableConfig?.organisationId ?? '',
					calculations: {},
					references: {},
					projectId: selectedProjectId,
				})
				continue
			}
			const originalRecord = mappedRecords.get(dataGridRow._id)
			if (!originalRecord) {
				continue
			}
			const recordCopy = {
				...originalRecord,
				data: {
					...dataGridRow,
					...updatedCells,
				},
			}
			updatesToCache.push({ newRow: recordCopy, oldRow: originalRecord })
		}

		cacheRowUpdates(updatesToCache, newRows)
	}

	const handlePaste = async (text: string) => {
		const isJSON = isValidJSON(text)
		const selectedCells = apiRef.current.getSelectedCellsAsArray()

		if (isJSON) {
			const clipboardDataObject: COPY_CLIPBOARD = await JSON.parse(text)
			if (
				clipboardDataObject?.type &&
				clipboardDataObject.type !== 'questionnaire_options' &&
				clipboardDataObject?.data
			) {
				if (!resolvedRows.length) return
				const updatedRows = await handleSpecialPasteFromClipboard(
					mappedRecords,
					selectedCells,
					selectedProjectId,
				)
				cacheRowUpdates(updatedRows)
				return
			}
		}

		const pastedText = text.replace(/\r\n/g, '\n').replace(/\r/g, '\n')

		if (!selectedCells.length) return
		const originCell = selectedCells[0]
		Papa.parse(pastedText as any, {
			delimiter: '\t',
			newline: '\n',
			skipEmptyLines: false,
			quoteChar: '"',
			complete: (results: ParseResult<string[]>) => handlePasteResults(results, originCell),
		})
	}

	const handleColumnOrderChange = () => {
		const columnOrder =
			apiRef?.current.getAllColumns()?.map(({ field }) => field) ??
			columns.map(({ field }) => field)
		const filteredOfActions = columnOrder.filter((_column) => {
			return !['__reorder__', '__check__', 'Actions'].includes(_column)
		})
		const orderedFields = filteredOfActions.sort((a, b) => {
			if (a === tableConfig?.principal_field) return -1
			if (b === tableConfig?.principal_field) return 1
			return columnOrder.indexOf(a) - columnOrder.indexOf(b)
		})
		updateTableConfig({ column_order: orderedFields }, true)
	}

	const handleRowOrderChange = () => {
		const sortedRows = apiRef.current.getSortedRows()
		const rowIds = sortedRows.map((row) => row._id)
		updateTableConfig(
			{
				row_order: rowIds,
			},
			true,
		)
	}

	const handleCellClick = useCallback(
		async (params: GridCellParams) => {
			if (['group'].includes(params.rowNode.type)) return // prevent error if it's autogenerated column)
			if (params.field === '__check__') return
			const colType = mappedColumns.get(params.field)?.type

			if (colType && [TableTypes.FieldType.VIDEO, TableTypes.FieldType.URL].includes(colType)) {
				return
			}
			if (
				colType === TableTypes.FieldType.OPTIONS ||
				colType === TableTypes.FieldType.CALCULATION
			) {
				const cangoRow = mappedRecords.get(params.id as string)
				setSelectedRow(
					cangoRow ? { ...cangoRow, columnId: params.field, columnType: colType } : null,
				)
				return
			}
			if (onCellClick) {
				onCellClick({
					columnId: params.field,
					rowId: params.row._id,
					cellId: params.id as string,
				})
			}
		},
		[mappedColumns, isUpdatingTable, mappedRecords],
	)

	const columnsWithActions = useMemo<GridColDef[]>(() => {
		if (isStatic) return columns
		let columnsCopy = [...columns]
		if (tableConfig?._id && showAnswersConfig[tableConfig._id]) {
			const config = showAnswersConfig[tableConfig._id]
			columnsCopy = columnsCopy.reduce((acc: GridColDef[], column) => {
				acc.push(column)
				if (config[column.field]) {
					acc.push({
						field: `${column.field}_answer`,
						headerName: 'Answer',
						type: 'custom',
						width: 150,
						editable: false,
						disableReorder: true,
						sortable: false,
						pinnable: false,
						filterable: false,
						groupable: false,
						aggregable: false,
						hideable: false,
						renderCell: (params) => {
							const answers = (params.value ?? []) as PopulatedAnswer[]
							if (answers.some((_answer) => _answer.imageUrl)) {
								return (
									<Stack direction="row" flexWrap="wrap">
										{answers.map((_answer) => {
											return (
												<Stack key={_answer._id}>
													{_answer.imageUrl && (
														<img
															srcSet={_answer.imageUrl}
															src={_answer.imageUrl}
															alt={`image of ${_answer.label}`}
															loading="lazy"
															style={{
																width: 50,
															}}
														/>
													)}
													{_answer && <Text fontSize={10}>{_answer.label}</Text>}
												</Stack>
											)
										})}
									</Stack>
								)
							}
							return answers.map((_answer) => _answer.label).join(', ')
						},
					})
				}
				return acc
			}, [])
		}
		return columnsCopy
	}, [columns, showAnswersConfig])

	const memoizedSlotProps = useMemo(
		(): GridSlotsComponentsProps => ({
			toolbar: {
				printOptions: { disableToolbarButton: true },
				csvOptions: { disableToolbarButton: true },
				isStatic,
				isLocked,
				hideToolbar,
				setFilterButtonEl,
				hideColumnSelector,
				hideDensitySelector,
				hideFilterSelector,
			},
			baseCheckbox: {
				size: 'small',
			},
			columnMenu: {
				slotProps: {
					openSettings: setSettingsModalId,
				},
			},
			panel: {
				anchorEl: filterButtonEl,
			},
			loadingOverlay: {
				variant: 'linear-progress',
			},
			cell: {
				onContextMenu: (e: any) => {
					e.preventDefault()
				},
			},
		}),
		[
			isStatic,
			isLocked,
			hideToolbar,
			hideColumnSelector,
			hideDensitySelector,
			hideFilterSelector,
			filterButtonEl,
		],
	)

	return (
		<>
			{!!changeConfirmation && changeConfirmation.Dialog}
			<ColumnSettingsModal onClose={() => setSettingsModalId(null)} columnId={settingsModalId} />
			{!!selectedRow && selectedRow.columnType === TableTypes.FieldType.OPTIONS && (
				<ListOptionsModal
					apiRef={apiRef}
					onClose={() => setSelectedRow(null)}
					row={selectedRow}
					columnId={selectedRow.columnId}
				/>
			)}
			<Box
				width="100%"
				height={maxHeight}
				display={hideTable ? 'none' : 'flex'}
				flexDirection="column"
			>
				<DataGridPremium
					apiRef={apiRef}
					getRowId={(row) => row._id}
					initialState={initialState}
					getRowHeight={() => (someAnswersWithImagesShowing ? 'auto' : undefined)}
					rows={resolvedRows}
					columns={columnsWithActions}
					density={tableDensity}
					onDensityChange={(params) => {
						updateTableConfig({ row_density: params }, true)
					}}
					rowReordering={tableConfig?.type !== TableTypes.TableType.Questionaire && rowReordering}
					pinnedColumns={{
						left: tableConfig?.principal_field
							? ['__reorder__', '__check__', tableConfig.principal_field]
							: [],
					}}
					onColumnWidthChange={(params) => {
						onUpdateColumn(params.colDef.field, { width: params.width })
					}}
					onCellKeyDown={async (params, event) => {
						if ((event.metaKey || event.ctrlKey) && ['v'].includes(event.key)) {
							event.stopPropagation()
							const data = await navigator.clipboard.readText()
							handlePaste(data)
						}
					}}
					hideFooter={hideFooter}
					onRowOrderChange={handleRowOrderChange}
					onColumnOrderChange={handleColumnOrderChange}
					processRowUpdate={processRowUpdate}
					onProcessRowUpdateError={(error) => {
						showSnackbar(error?.message, { variant: 'error' })
					}}
					checkboxSelection={true}
					sortModel={tableConfig?.sortingModel}
					onSortModelChange={(newModel) => updateTableConfig({ sortingModel: newModel }, true)}
					onColumnVisibilityModelChange={onColumnFiltersChange}
					disableClipboardPaste
					//@ts-ignore doesn't make sense that this is throwing an error. I cannot seem to fix it.
					sx={{
						...dataGridTables,
						...styles,
						...(hideTable ? { display: 'none' } : {}),
					}}
					showCellVerticalBorder
					showColumnVerticalBorder
					ignoreValueFormatterDuringExport
					disableRowSelectionOnClick
					disableColumnMenu={isStatic}
					getAggregationPosition={() => 'inline'}
					loading={isLoadingTable}
					groupingColDef={{ width: 250 }}
					aggregationFunctions={cangoTableAggregations}
					rowGroupingColumnMode="multiple"
					onFilterModelChange={onFilterChange}
					cellSelection
					onClipboardCopy={(copiedString) => {
						const lines = copiedString.split(/\r?\n/)
						const output = lines.join('\n')
						clipboard.write(output.replace(/"/g, '\\"'))
					}}
					onCellClick={handleCellClick}
					getCellClassName={getCellClassnames}
					slots={slots}
					slotProps={memoizedSlotProps}
				/>
			</Box>
			<RightClickMenuActions
				contextMenu={contextMenu}
				handleClose={() => setContextMenu(null)}
				cellData={cell}
			/>
		</>
	)
}
