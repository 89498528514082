import { FilesSdk } from '@cango-app/sdk/api'
import { useDispatch, useSelector } from 'react-redux'
import { useMemo } from 'react'

import { RootState, AsyncDispatchType } from 'src/store/types'
import { selectors as persistedFilesSelector } from 'src/store/modules/persisted-files'
import * as thunks from 'src/store/modules/persisted-files/thunks'

export type UseParentFolderFiles = {
	allFiles: FilesSdk.GetFilesByFolderIdResponse
	isLoadingFiles: boolean
	fetchFiles: () => Promise<{ id: string; files: FilesSdk.File[] }>
	addNewFilesToCollection: () => Promise<void>
}

export function useParentFolderFiles(
	parentFolderId: string,
	parentFolderName: string,
): UseParentFolderFiles {
	const dispatch = useDispatch<AsyncDispatchType>()
	const allFiles = useSelector((state: RootState) =>
		persistedFilesSelector.getProjectFiles(state, parentFolderId),
	)
	const isLoadingFiles = useSelector((state: RootState) =>
		persistedFilesSelector.getIsLoadingFilesForProjectId(state, parentFolderId),
	)

	const fetchFiles = async () => {
		if (!parentFolderId) {
			return { id: '', files: [] }
		}
		return await dispatch(thunks.fetchFiles({ parentFolderId, parentFolderName })).unwrap()
	}

	const addNewFilesToCollection = async () => {
		await dispatch(thunks.addNewFilesToCollection({ parentFolderId }))
	}

	return useMemo(
		() => ({
			allFiles,
			isLoadingFiles,
			fetchFiles,
			addNewFilesToCollection,
		}),
		[allFiles, isLoadingFiles, fetchFiles, addNewFilesToCollection],
	)
}
