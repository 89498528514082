import { useState, useCallback, ComponentType, useEffect, PropsWithChildren } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _set from 'lodash/set'
import _cloneDeep from 'lodash/cloneDeep'
import { TaskTypes } from '@cango-app/sdk/types'
import { V3ProjectSdk, TasksSdk } from '@cango-app/sdk/api'

import { getRequiredTables } from 'src/providers/task-provider/utils'
import { selectors as authSelectors } from 'src/store/modules/auth'
import { errorHandler } from 'src/helpers/api'
import { showSnackbar } from 'src/helpers/snackbarManager'
import usePolling from 'src/hooks/usePolling'
import {
	actions as projectActions,
	selectors as projectSelectors,
} from 'src/store/modules/projects-v3'
import { selectors as persistedConfigSelectors } from 'src/store/modules/persisted-config'
import { SelectedOptions, TaskDbChainWithDescendants } from 'src/providers/task-provider/types'
import { AsyncDispatchType, RootState } from 'src/store/types'
import { RouteId } from 'src/constants/routes'
import { getSectionId } from 'src/helpers/chains'
import { useMyTasks } from 'src/hooks/useMyTasks'
import { actions as tableActions, selectors as tableSelectors } from 'src/store/modules/tables'

import { TaskContext } from './context'
import { selectAvailableDescendants } from './selectors'

export const TaskProvider: ComponentType<
	PropsWithChildren<{
		taskId?: string
		fetchFiles?: () => void
		onTaskLoading?: (isLoading: boolean) => void
		onClose?: () => void
	}>
> = (props) => {
	const dispatch = useDispatch<AsyncDispatchType>()
	const [isLoadingTask, setIsLoadingTask] = useState(false)
	const [task, setTask] = useState<TaskTypes.PopulatedTask | undefined>()
	const authHeaders = useSelector(authSelectors.getAuthHeaders)
	const [nextTaskDbChains, setNextTaskDbChains] = useState<TaskDbChainWithDescendants>({})
	const project = useSelector(projectSelectors.getSelectedProject)
	const projectSteps = useSelector(projectSelectors.getProjectSteps)
	const chain_starters = useSelector(projectSelectors.getChainStarters)
	const allTableIds = useSelector(tableSelectors.selectAllTableIds)
	const tablesFetching = useSelector(tableSelectors.selectTableFetchingStates)
	const groupingType = useSelector((state: RootState) =>
		persistedConfigSelectors.getProjectGroupingConfig(state, project?._id ?? ''),
	)
	const { selectedSectionId, goToTask } = useMyTasks()
	const [selectedOptions, setSelectedOptions] = useState<SelectedOptions>(
		(task?.isMultiUse && task?.isMenu) || !task?.isMultiUse
			? (task?.lifecycle.completed_options ?? [])
			: [],
	)
	const descendants = useSelector((state: RootState) =>
		selectAvailableDescendants(state, selectedOptions, task),
	)

	const isPendingFileUploads =
		!!task?.actions.some((_action) => _action.file_ids.includes(V3ProjectSdk.UPLOADING_TEXT)) ||
		!!task?.attachments?.some((_attachment) =>
			_attachment.file_ids.includes(V3ProjectSdk.UPLOADING_TEXT),
		)

	const handleLoadingToggle = (loading: boolean) => {
		setIsLoadingTask(loading)
		if (props.onTaskLoading) {
			props.onTaskLoading(loading)
		}
	}

	const updateTask = useCallback(
		async (
			key: keyof TasksSdk.UpdateTaskRequest['update'],
			value: any,
			options?: {
				updateDb?: boolean
				localStateKey?: string
				referencedTask?: {
					_id: string
					isAttachment: boolean
				}
			},
		) => {
			const taskIdToUpdate = options?.referencedTask?._id ?? task?._id
			if (!taskIdToUpdate) return

			try {
				if (options?.updateDb) {
					await TasksSdk.updateTask({
						baseURL: import.meta.env.VITE_API as string,
						authHeaders,
						taskId: taskIdToUpdate,
						data: {
							[key]: value,
						},
					})
				}

				setTask((_task) => {
					if (!_task) return
					const taskCopy = _cloneDeep(_task)
					if (options?.referencedTask?._id) {
						if (options.referencedTask.isAttachment) {
							let taskReferenceIndex: number | undefined = undefined
							const actionIndex = taskCopy.attachments.findIndex((attachment) =>
								attachment.task_references.find((_ref, index) => {
									if (_ref.task === options.referencedTask?._id) {
										taskReferenceIndex = index
										return true
									}
								}),
							)

							_set(
								taskCopy,
								`attachments.${actionIndex}.task_references.${taskReferenceIndex}.task.${String(key)}`,
								value,
							)
						} else {
							let taskReferenceIndex: number | undefined = undefined
							const actionIndex = taskCopy.actions.findIndex((action) =>
								action.task_references.find((_ref, index) => {
									if (_ref.task === options.referencedTask?._id) {
										taskReferenceIndex = index
										return true
									}
								}),
							)
							_set(
								taskCopy,
								`actions.${actionIndex}.task_references.${taskReferenceIndex}.task.${String(key)}`,
								value,
							)
						}

						return { ...taskCopy }
					}
					_set(taskCopy, options?.localStateKey ?? key, value)
					return taskCopy
				})
				dispatch(projectActions.updateStoreTask({ taskId: taskIdToUpdate, key: key as any, value }))
			} catch (error) {
				showSnackbar('Could not update task', { variant: 'error' })
			}
		},
		[setTask, task],
	)

	const chainsThatNeedFetching = useCallback(
		(task: TaskTypes.PopulatedTask): string[] => {
			if (!task.step?.descendants.length) {
				return []
			}

			return task.step.descendants.reduce((_chains: string[], _desc) => {
				if (
					_desc.step?.chain_id &&
					!projectSteps.some((_step) => _step._id === _desc.step?.chain_id)
				) {
					_chains.push(_desc.step.chain_id)
				}

				if (!_desc.step?.chain_reference) {
					return _chains
				}

				if (chain_starters.some((_chain) => _chain._id === _desc.step?.chain_reference)) {
					return _chains
				}

				return [..._chains, _desc.step.chain_reference]
			}, [])
		},
		[chain_starters],
	)

	const getTasksPendingUploadedFiles = useCallback(async () => {
		if (!task || !isPendingFileUploads || !props.fetchFiles) return
		try {
			const response = await V3ProjectSdk.getTasksPendingUploadedFiles({
				baseURL: import.meta.env.VITE_API as string,
				authHeaders,
				data: {
					taskIds: [task._id],
				},
			})
			if (response.length) {
				props.fetchFiles()
				response.forEach((_task) => {
					if (task._id !== _task._id) return
					updateTask('actions', _task.actions)
				})
			}
		} catch (error) {
			showSnackbar('Could not fetch files', { variant: 'error' })
		}
	}, [isPendingFileUploads, task?._id])
	const [isPolling, startPolling, stopPolling] = usePolling(getTasksPendingUploadedFiles, 5000)

	const fetchTask = useCallback(async () => {
		if (!props.taskId) {
			setTask(undefined)
			return
		}
		try {
			handleLoadingToggle(true)
			const response = await TasksSdk.getTask(import.meta.env.VITE_API as string, authHeaders, {
				taskId: props.taskId,
			})
			setTask(response)
			setSelectedOptions(
				(response.isMultiUse && response.isMenu) || !response.isMultiUse
					? (response.lifecycle.completed_options ?? [])
					: [],
			)

			const chainsToFetch = chainsThatNeedFetching(response)
			if (chainsToFetch.length) {
				await dispatch(projectActions.getProjectSteps({ chainIds: chainsToFetch }))
			}

			const requiredTables = getRequiredTables(response)
			const nonFetchedTables = requiredTables.filter((tableId) => !allTableIds.includes(tableId))

			for (const tableId of nonFetchedTables) {
				if (!tablesFetching[tableId].isFetching) {
					await dispatch(tableActions.fetchTable({ newTableId: tableId }))
				}
			}

			if (location.pathname.includes(`/${RouteId.MyTasks}`)) {
				const sectionId = getSectionId(
					{ name: response.section, chain: response.chain },
					groupingType,
				)
				if (selectedSectionId !== sectionId) {
					goToTask(response)
				}
			}
		} catch (error) {
			errorHandler({ error, dispatch, message: 'Could not fetch files' })
		} finally {
			handleLoadingToggle(false)
		}
	}, [props.taskId, authHeaders, location.pathname, projectSteps])

	useEffect(() => {
		if (!isPolling && task && isPendingFileUploads) {
			startPolling()
		}

		if (isPolling && (!isPendingFileUploads || !task)) {
			stopPolling()
		}

		return () => {
			if (isPolling) {
				stopPolling()
			}
		}
	}, [isPendingFileUploads, isPolling, task])

	useEffect(() => {
		if (props.taskId) {
			fetchTask()
		}
	}, [props.taskId])

	return (
		<TaskContext.Provider
			value={{
				task: task,
				isLoadingTask,
				updateTask,
				setNextTaskDbChains,
				nextTaskDbChains,
				onClose: props.onClose,
				selectedOptions,
				setSelectedOptions,
				descendants,
			}}
		>
			{props.children}
		</TaskContext.Provider>
	)
}
