import { V3ProjectSdk } from '@cango-app/sdk/api'
import { Stack } from '@mui/material'
import dayjs from 'dayjs'
import { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { selectors as authSelectors } from 'src/store/modules/auth'
import { Box, Button, Grid, Modal, Select, Text } from 'src/components'
import { showSnackbar } from 'src/helpers/snackbarManager'
import { actions as projectActions } from 'src/store/modules/projects-v3'

type Props = {
	open: boolean
	onClose: () => void
	projectWithWarningDate: V3ProjectSdk.Card[]
}

const CLEAN_UP_ACTIONS_OPTIONS = Object.values(V3ProjectSdk.ProjectManageActions).map((action) => ({
	_id: action,
	label: action,
}))

export const ReviewProjectsModal = ({ open, onClose, projectWithWarningDate }: Props) => {
	const authHeaders = useSelector(authSelectors.getAuthHeaders)
	const dispatch = useDispatch()
	const [projectManageActions, setProjectManageActions] = useState<
		V3ProjectSdk.ManageProjectRequest['projects']
	>([])
	const [isLoading, setIsLoading] = useState(false)

	const handleSetAction = useCallback(
		(action: V3ProjectSdk.ProjectManageActions, projectId: string) => {
			const foundProjectAction = projectManageActions.find(
				(project) => project.projectId === projectId,
			)
			if (!foundProjectAction) {
				setProjectManageActions((currentProjectActions) => [
					...currentProjectActions,
					{
						projectId,
						action,
					},
				])
				return
			}
			setProjectManageActions((currentProjectActions) =>
				currentProjectActions.map((projectAction) => {
					if (projectAction.projectId === projectId) {
						return {
							projectId,
							action,
						}
					}
					return projectAction
				}),
			)
		},
		[projectActions],
	)

	const submitActions = useCallback(
		async (projectsToSubmit: V3ProjectSdk.ManageProjectRequest['projects']) => {
			const filteredProjectActions = projectsToSubmit.filter(({ action }) =>
				[
					V3ProjectSdk.ProjectManageActions.delete,
					V3ProjectSdk.ProjectManageActions.archive,
					V3ProjectSdk.ProjectManageActions.snooze,
				].includes(action),
			)
			try {
				await V3ProjectSdk.manageProjects(import.meta.env.VITE_API as string, authHeaders, {
					projects: filteredProjectActions,
				})
				await dispatch(projectActions.getCards())
			} catch (error) {
				showSnackbar('Could not found projects')
			} finally {
				setIsLoading(false)
				onClose()
			}
		},
		[],
	)
	return (
		<Modal open={open} onClose={onClose}>
			<>
				<Stack direction="column" spacing={2}>
					<Text variant="h4">Clean up projects:</Text>
					<Text>{"Choose actions for projects you haven't visited in a while."}</Text>
					<Box px={1}>
						<Grid container columnSpacing={2} mb={2}>
							<Grid item xs={4}>
								<Text variant="h6">Project</Text>
							</Grid>
							<Grid item xs={4}>
								<Text variant="h6">Last update</Text>
							</Grid>
							<Grid item xs={4}>
								<Text variant="h6">Action</Text>
							</Grid>
						</Grid>
					</Box>
					<Box
						display="flex"
						flexDirection="column"
						width="100%"
						maxHeight="300px"
						px={1}
						style={{
							overflowY: 'auto',
						}}
					>
						{projectWithWarningDate.map(({ _id, name, updatedAt }) => {
							return (
								<Grid
									container
									key={_id}
									direction="row"
									alignItems="center"
									columnSpacing={2}
									mb={1}
								>
									<Grid item xs={4}>
										<Text variant="h6" fontSize={14}>
											{name}
										</Text>
									</Grid>
									<Grid item xs={4}>
										<Text variant="caption">
											Last update: {dayjs.unix(updatedAt).format('D MMM YYYY')}
										</Text>
									</Grid>
									<Grid item xs={4}>
										<Select
											defaultValue={V3ProjectSdk.ProjectManageActions.no_action}
											options={CLEAN_UP_ACTIONS_OPTIONS}
											disableOrdering
											value={
												projectManageActions.find(({ projectId }) => projectId === _id)?.action
											}
											onChange={(e) =>
												handleSetAction(e.target.value as V3ProjectSdk.ProjectManageActions, _id)
											}
										/>
									</Grid>
								</Grid>
							)
						})}
					</Box>
				</Stack>
				<Stack direction="row" spacing={1} mt={2}>
					<Button
						fullWidth
						onClick={() => submitActions(projectManageActions)}
						isLoading={isLoading}
					>
						Confirm Actions
					</Button>
					<Button
						color="warning"
						variant="outlined"
						isLoading={isLoading}
						onClick={() => {
							const projectsToArchive = projectWithWarningDate.map(({ _id }) => ({
								projectId: _id,
								action: V3ProjectSdk.ProjectManageActions.archive,
							}))
							submitActions(projectsToArchive)
						}}
					>
						Archive All
					</Button>
					<Button
						color="error"
						variant="outlined"
						isLoading={isLoading}
						onClick={() => {
							const projectsToDelete = projectWithWarningDate.map(({ _id }) => ({
								projectId: _id,
								action: V3ProjectSdk.ProjectManageActions.delete,
							}))
							submitActions(projectsToDelete)
						}}
					>
						Delete All
					</Button>
					<Button variant="text" onClick={onClose}>
						Cancel
					</Button>
				</Stack>
			</>
		</Modal>
	)
}
