import { TableTypes } from '@cango-app/sdk/types'
import MenuItem from '@mui/material/MenuItem'
import {
	GridColumnMenu,
	GridColumnMenuItemProps,
	GridColumnMenuProps,
	useGridApiContext,
} from '@mui/x-data-grid-premium'
import _cloneDeep from 'lodash/cloneDeep'
import { ComponentType, useCallback, useContext, useMemo, useState } from 'react'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { PulseLoader } from 'react-spinners'
import _orderBy from 'lodash/orderBy'
import _uniq from 'lodash/uniq'
import { v4 } from 'uuid'
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer'
import { useDispatch, useSelector } from 'react-redux'
import capitalize from 'lodash/capitalize'

import { getNewUniqueId } from 'src/modules/tables/utils'
import {
	actions as persistedConfigActions,
	selectors as persistedConfigSelectors,
} from 'src/store/modules/persisted-config'
import { Box, Button, Checkbox, Select, TextField } from 'src/components'
import { TableContext } from 'src/providers/table-provider'
import { FlagIcon, TrashIcon } from 'src/assets/icons'
import useDebouncedCallback from 'src/hooks/useDebouncedCallback'
import { selectors as projectSelectors } from 'src/store/modules/projects-v3'
import { selectors as tableSelectors } from 'src/store/modules/tables'

import { columnsWithSettings } from './column-settings/types'

const fieldTypeOptions = _orderBy(
	[
		{ label: 'Text', _id: TableTypes.FieldType.STRING },
		{ label: 'Number', _id: TableTypes.FieldType.NUMBER },
		{ label: 'Date', _id: TableTypes.FieldType.DATE },
		{ label: 'True / False', _id: TableTypes.FieldType.BOOLEAN },
		{ label: 'Single Select', _id: TableTypes.FieldType.SINGLE_SELECT },
		{ label: 'Contact', _id: TableTypes.FieldType.CONTACT },
		{ label: 'Formula', _id: TableTypes.FieldType.CALCULATION },
		{
			label: 'URL',
			_id: TableTypes.FieldType.URL,
		},
		{
			label: 'Video',
			_id: TableTypes.FieldType.VIDEO,
		},
		{
			label: 'Reference',
			_id: TableTypes.FieldType.REFERENCE,
		},
		{
			label: 'Concatination',
			_id: TableTypes.FieldType.CONCATINATION,
		},
		{
			label: 'Unique ID',
			_id: TableTypes.FieldType.UNIQUE_ID,
		},
		{
			label: 'Role',
			_id: TableTypes.FieldType.ROLE,
		},
		{
			label: 'Options',
			_id: TableTypes.FieldType.OPTIONS,
		},
		{
			label: 'Questionaire',
			_id: TableTypes.FieldType.QUESTIONAIRE_REFERENCE,
		},
		{
			label: 'File',
			_id: TableTypes.FieldType.FILE,
		},
		{
			label: 'Long Text',
			_id: TableTypes.FieldType.LONG_TEXT,
		},
	],
	'label',
)

const ColumnNameField: ComponentType<GridColumnMenuItemProps & { name: string }> = (props) => {
	const { onUpdateColumn, isUpdatingTable } = useContext(TableContext)
	const [columnName, setColumnName] = useState(props.name)

	return (
		<Box m={1}>
			<TextField
				label="Column name"
				value={columnName}
				onChange={(event) => setColumnName(event.target.value)}
				onKeyDown={(e) => e.stopPropagation()}
				disabled={isUpdatingTable}
				size="small"
				fullWidth
			/>
			{props.name !== columnName && !!columnName && (
				<Button
					variant="text"
					onClick={() => onUpdateColumn(props.colDef.field, { name: columnName })}
					size="small"
					fullWidth
					isLoading={isUpdatingTable}
					sx={{ minWidth: 50, mt: 0.5 }}
				>
					Save
				</Button>
			)}
		</Box>
	)
}

const FieldTypeSelector: ComponentType<
	GridColumnMenuItemProps & {
		column: TableTypes.Field
		openSettings: (fieldId: string) => void
	}
> = (props) => {
	const {
		onUpdateColumn,
		isUpdatingTable,
		mappedColumns,
		tableConfig,
		updateRecords,
		resolvedRows,
		mappedRecords,
		mappedValueOptions,
	} = useContext(TableContext)
	const selectedProjectId = useSelector(projectSelectors.getSelectedProjectId)
	const tableList = useSelector(tableSelectors.selectTableList)
	const [numberOfDecimals, setNumberOfDecimals] = useState<number | null>(
		props.column.numberOfDecimals ?? null,
	)
	const column = mappedColumns.get(props.colDef.field)
	const apiRef = useGridApiContext()
	const contactColumns = useMemo(() => {
		return [...mappedColumns.values()].reduce((_acc: { _id: string; label: string }[], _column) => {
			if (_column.type !== TableTypes.FieldType.CONTACT) {
				return _acc
			}
			return [..._acc, { _id: _column._id, label: _column.name }]
		}, [])
	}, [mappedColumns])

	const handleUpdateColumnType = async (type: TableTypes.FieldType) => {
		if (apiRef?.current) {
			// remove filters when updating column
			apiRef.current.setFilterModel({ items: [] })
		}
		const previousType = column?.type
		const columnUpdates: Partial<TableTypes.Field> & { _id: string } = {
			_id: props.colDef.field,
			type,
		}
		if (
			type === TableTypes.FieldType.SINGLE_SELECT &&
			previousType === TableTypes.FieldType.STRING
		) {
			const allColumnValues =
				resolvedRows.reduce((_acc: string[], _record) => {
					if (_record[props.colDef.field]) {
						_acc = _uniq([..._acc, String(_record[props.colDef.field])])
					}
					return _acc
				}, []) ?? []
			const colValuesWithIds = allColumnValues.map((value) => {
				return { _id: v4(), label: value }
			})
			const recordsWithAssignedIndex: { _id: string; valueIndex: number }[] =
				resolvedRows.map((_record) => {
					const indexOfValue = allColumnValues.indexOf(String(_record[props.colDef.field]))
					return {
						_id: _record._id,
						valueIndex: indexOfValue,
					}
				}) ?? []
			const updatedRows = resolvedRows.reduce(
				(
					rows: {
						newRow: TableTypes.TableRow
						oldRow: TableTypes.TableRow
					}[],
					_row,
				) => {
					const record = mappedRecords.get(_row._id)
					if (!record) {
						return rows
					}
					if (!record.data[props.colDef.field]) {
						return [...rows, { oldRow: record, newRow: record }]
					}
					const recordWithIndex = recordsWithAssignedIndex.find(
						(_record) => _record._id === _row._id,
					)
					if (!recordWithIndex) {
						return [...rows, { oldRow: record, newRow: record }]
					}
					return [
						...rows,
						{
							oldRow: record,
							newRow: {
								...record,
								data: {
									...record.data,
									[props.colDef.field]: colValuesWithIds[recordWithIndex.valueIndex]._id,
								},
							},
						},
					]
				},
				[],
			)

			if (column) {
				await onUpdateColumn(column._id, {
					...columnUpdates,
					valueOptions: colValuesWithIds,
				})
				await updateRecords({
					rows: updatedRows,
					save: true,
					projectId: selectedProjectId,
				})
			}

			return
		} else if (
			type === TableTypes.FieldType.STRING &&
			previousType === TableTypes.FieldType.SINGLE_SELECT
		) {
			const valueOptions = mappedValueOptions[props.colDef.field]
			const updatedRows = resolvedRows.reduce(
				(
					rows: {
						newRow: TableTypes.TableRow
						oldRow: TableTypes.TableRow
					}[],
					_row,
				) => {
					const record = mappedRecords.get(_row._id)
					if (!record) {
						return rows
					}

					if (!record.data[props.colDef.field]) {
						return rows
					}

					const currentValue = String(record.data[props.colDef.field])
					const labelValue = valueOptions.get(currentValue)

					return [
						...rows,
						{
							oldRow: record,
							newRow: {
								...record,
								data: {
									...record.data,
									[props.colDef.field]: labelValue,
								},
							},
						},
					]
				},
				[],
			)

			if (column) {
				await onUpdateColumn(column._id, columnUpdates)
				await updateRecords({
					rows: updatedRows,
					save: true,
					projectId: selectedProjectId,
				})
			}

			return
		}

		if (
			previousType === TableTypes.FieldType.CALCULATION &&
			type !== TableTypes.FieldType.CALCULATION
		) {
			columnUpdates['calculation'] = []
			if (
				previousType === TableTypes.FieldType.CALCULATION &&
				[...mappedRecords.values()].some((_record) => _record.calculations?.[props.colDef.field])
			) {
				const confirmChange = confirm(
					'This column has row calculations. Are you sure you want to proceed?',
				)
				if (!confirmChange) {
					return
				}
			}
		}

		if (type === TableTypes.FieldType.UNIQUE_ID) {
			const allRowUpdates = resolvedRows.reduce(
				(
					_rows: {
						newRow: TableTypes.TableRow
						oldRow: TableTypes.TableRow
					}[],
					_row,
				) => {
					if (_row[props.colDef.field]) {
						return _rows
					}
					const fullRow = mappedRecords.get(_row._id)
					if (!fullRow) {
						return _rows
					}
					const newRow = _cloneDeep(fullRow)
					const resolvedRowsIncludingUpdates = resolvedRows.map((_resolvedRow) => {
						const updatedRow = _rows.find((_row) => _row.newRow._id === _resolvedRow._id)?.newRow
						if (updatedRow) {
							return updatedRow.data
						}
						return _resolvedRow
					})
					const uniqueId = getNewUniqueId({
						resolvedRows: resolvedRowsIncludingUpdates,
						columnId: props.colDef.field,
					})

					newRow.data[props.colDef.field] = uniqueId

					return [
						..._rows,
						{
							newRow: newRow,
							oldRow: fullRow,
						},
					]
				},
				[],
			)

			await onUpdateColumn(props.colDef.field, columnUpdates)
			await updateRecords({ rows: allRowUpdates, projectId: selectedProjectId })
			return
		}

		await onUpdateColumn(props.colDef.field, columnUpdates)
	}

	const updateNumberOfDecimals = useDebouncedCallback(
		useCallback(
			(fieldId: string, numberOfDecimals: number | null) => {
				onUpdateColumn(fieldId, {
					numberOfDecimals,
				})
			},
			[onUpdateColumn],
		),
		850,
	)

	const handleUpdateNumberOfDecimals = useCallback(
		(newValue: number | null) => {
			setNumberOfDecimals(newValue)
			updateNumberOfDecimals(props.colDef.field, newValue)
		},
		[props.colDef.field, updateNumberOfDecimals],
	)

	return (
		<>
			<Box m={1}>
				<Select
					onChange={(event) => handleUpdateColumnType(event.target.value as TableTypes.FieldType)}
					label="Column type"
					options={fieldTypeOptions}
					disabled={isUpdatingTable}
					value={props.column.type}
					size="small"
					containerProps={{ width: '100%' }}
					fullWidth
				/>
				{[TableTypes.FieldType.CALCULATION].includes(props.column.type) && (
					<Select
						label="Return Type"
						options={Object.values(TableTypes.ReturnValueType).map((returnValueType) => ({
							_id: returnValueType,
							label: capitalize(returnValueType),
						}))}
						disableOrdering
						value={props.column.returnType}
						onChange={(event) => {
							onUpdateColumn(props.colDef.field, {
								returnType: event.target.value as TableTypes.ReturnValueType,
								format:
									event.target.value !== TableTypes.ReturnValueType.number
										? TableTypes.FormatType.unformatted
										: props.column.format,
							})
						}}
					/>
				)}
				{[TableTypes.FieldType.NUMBER, TableTypes.FieldType.CALCULATION].includes(
					props.column.type,
				) && (
					<>
						<Select
							label="Format"
							disabled={
								props.column.type === TableTypes.FieldType.CALCULATION &&
								props.column.returnType !== TableTypes.ReturnValueType.number
							}
							options={Object.values(TableTypes.FormatType).map((format) => ({
								_id: format,
								label: format,
							}))}
							value={props.column.format}
							onChange={(event) => {
								onUpdateColumn(props.colDef.field, {
									format: event.target.value as TableTypes.FormatType,
								})
							}}
						/>

						{((props.column.type === TableTypes.FieldType.NUMBER &&
							props.column.format !== TableTypes.FormatType.currency) ||
							([TableTypes.FieldType.CALCULATION].includes(props.column.type) &&
								props.column.returnType === TableTypes.ReturnValueType.number &&
								props.column.format !== TableTypes.FormatType.currency)) && (
							<>
								<Checkbox
									label="Use a fixed number of decimals"
									checked={numberOfDecimals !== null}
									onChange={(e) => {
										const isChecked = e.target.checked
										const nextValue = isChecked ? 0 : null
										handleUpdateNumberOfDecimals(nextValue)
									}}
								/>
								{numberOfDecimals !== null && (
									<TextField
										label="Number of decimals"
										fullWidth
										type="number"
										autoComplete="off"
										value={numberOfDecimals}
										onChange={(e) => handleUpdateNumberOfDecimals(Number(e.target.value))}
									/>
								)}
							</>
						)}
					</>
				)}
				{[TableTypes.FieldType.REFERENCE].includes(props.column.type) && (
					<Select
						label="Table"
						options={tableList
							.filter((_table) => _table._id !== tableConfig?._id)
							.map((_table) => ({
								_id: _table._id,
								label: _table.name,
								disabled: tableList.some(
									(_otherTable) =>
										_otherTable._id !== tableConfig?._id &&
										_otherTable.questionaire_reference_table === _table._id,
								),
							}))}
						value={props.column.reference}
						onChange={(event) => {
							onUpdateColumn(props.colDef.field, {
								reference: event.target.value as string,
							})
						}}
					/>
				)}
				{props.column.type === TableTypes.FieldType.ROLE && (
					<Select
						label="Related contact column"
						options={contactColumns}
						value={props.column.linked_column}
						onChange={(event) => {
							onUpdateColumn(props.colDef.field, {
								linked_column: event.target.value as string,
							})
						}}
					/>
				)}
				{columnsWithSettings.includes(props.column.type) && (
					<Button variant="text" fullWidth onClick={() => props.openSettings(props.column._id)}>
						Settings
					</Button>
				)}
			</Box>
		</>
	)
}

const DeleteColumnMenuItem: ComponentType<GridColumnMenuItemProps> = (props) => {
	const { onDeleteColumn } = useContext(TableContext)
	const [isDeleting, setIsDeleting] = useState(false)

	const handleDeleteColumn = async () => {
		const confirmed = confirm('Are you sure you want to delete this column?')
		if (confirmed) {
			setIsDeleting(true)
			await onDeleteColumn(props.colDef.field)
			setIsDeleting(false)
		}
	}

	return (
		<MenuItem onClick={handleDeleteColumn}>
			<ListItemIcon>
				<TrashIcon width={16} />
			</ListItemIcon>
			{isDeleting ? <PulseLoader size={4} /> : <ListItemText>Delete column</ListItemText>}
		</MenuItem>
	)
}

const PrincipalColumnSelectMenuItem: ComponentType<GridColumnMenuItemProps> = (props) => {
	const { updateTableConfig, tableConfig, mappedColumns } = useContext(TableContext)

	const updatePrincipalColumn = async () => {
		let columnOrder: string[] = [...(tableConfig?.column_order ?? [])]
		if (!tableConfig?.column_order) {
			columnOrder = [...mappedColumns.keys()]
		}
		const oldIndex = columnOrder.findIndex((_fieldId) => _fieldId === props.colDef.field)
		if (oldIndex < 0 || !tableConfig) {
			return
		}
		const removedColumn = columnOrder.splice(oldIndex, 1)[0]
		columnOrder.splice(0, 0, removedColumn)

		await updateTableConfig(
			{ principal_field: props.colDef.field, column_order: columnOrder },
			true,
		)
	}

	if (props.isPrincipalColumn) {
		return null
	}

	return (
		<MenuItem onClick={updatePrincipalColumn} sx={{ mt: 1 }}>
			<ListItemIcon>
				<FlagIcon width={16} />
			</ListItemIcon>
			<ListItemText>Make principal column</ListItemText>
		</MenuItem>
	)
}

const ShowAnswersMenuItem: ComponentType<GridColumnMenuItemProps> = (props) => {
	const dispatch = useDispatch()
	const { tableConfig, mappedColumns } = useContext(TableContext)
	const answersConfig = useSelector(persistedConfigSelectors.getAnswersConfig)
	const isShowingAnswers = !!answersConfig?.[tableConfig?._id ?? '']?.[props.colDef.field]
	const column = mappedColumns.get(props.colDef.field)

	const handleToggleShowAnswers = () => {
		dispatch(
			persistedConfigActions.setShowAnswers({
				tableId: tableConfig?._id ?? '',
				columnId: props.colDef.field,
				showAnswers: !isShowingAnswers,
			}),
		)
	}

	if (column?.type !== TableTypes.FieldType.QUESTIONAIRE_REFERENCE) {
		return null
	}

	return (
		<MenuItem onClick={handleToggleShowAnswers} sx={{ mt: 1 }}>
			<ListItemIcon>
				<QuestionAnswerIcon width={16} />
			</ListItemIcon>
			<ListItemText>{isShowingAnswers ? 'Hide' : 'Show'} answers</ListItemText>
		</MenuItem>
	)
}

const slots = {
	columnMenuPinningItem: null,
	columnMenuColumnsItem: null,
	columnMenuFieldSelector: FieldTypeSelector,
	columnMenuNameItem: ColumnNameField,
	columnMenuDeleteColumnItem: DeleteColumnMenuItem,
	columnPrincipalColumnSelect: PrincipalColumnSelectMenuItem,
	columnShowAnswers: ShowAnswersMenuItem,
}

export const CustomColumnMenu: ComponentType<GridColumnMenuProps> = (props) => {
	const { tableConfig, mappedColumns } = useContext(TableContext)

	const column = useMemo(
		() => mappedColumns.get(props.colDef.field),
		[mappedColumns, props.colDef.field],
	)

	if (!column) {
		return <GridColumnMenu {...props} />
	}

	return (
		<>
			<GridColumnMenu
				{...props}
				slots={slots}
				slotProps={{
					columnMenuFieldSelector: {
						displayOrder: 15,
						column: column,
						openSettings: props.slotProps?.openSettings,
					},
					columnMenuNameItem: {
						name: column.name,
						displayOrder: 14,
					},
					columnMenuDeleteColumnItem: {
						displayOrder: 21,
					},
					columnPrincipalColumnSelect: {
						displayOrder: 16,
						isPrincipalColumn: props.colDef.field === tableConfig?.principal_field,
					},
					columnShowAnswers: {
						displayOrder: 20,
					},
				}}
			/>
		</>
	)
}
