import { GridFilterModel, GridValidRowModel } from '@mui/x-data-grid-premium'
import { useContext, useMemo } from 'react'
import { TableTypes } from '@cango-app/sdk/types'

import { applyFilterModelToRow } from 'src/modules/tables/utils'
import { TableContext } from 'src/providers'
import { ColumnFilterList, ResolvedRowData } from 'src/store/modules/tables'

export type Hierarchy = { _id: string; label: string }[]

export type ViewRow = GridValidRowModel & {
	hierarchy: Hierarchy
}

export const getSanitizedGroupedValue = (groupedValue: any) => {
	if (!groupedValue) {
		return ''
	}

	if (typeof groupedValue !== 'string') {
		return String(groupedValue)
	}
	return groupedValue.replace(/[^a-zA-Z0-9]/g, '_')
}

export const getRows = ({
	groupingFields,
	columnFilterList,
	mappedColumns,
	filters,
	resolvedRows,
	applyFilters = true,
}: {
	groupingFields: TableTypes.GroupedField[]
	columnFilterList: ColumnFilterList
	mappedColumns: Map<string, TableTypes.Field>
	filters: GridFilterModel
	resolvedRows: ResolvedRowData[]
	applyFilters?: boolean
}): { rows: ViewRow[]; groupNames: Map<string, string> } => {
	const resolvedRecords = resolvedRows.reduce((_records: ViewRow[], resolvedRecord) => {
		const recordData: ViewRow = { ...resolvedRecord, hierarchy: [], _id: resolvedRecord._id }
		if (filters.items.length && applyFilters) {
			const filteredRow = applyFilterModelToRow({
				columns: columnFilterList,
				row: recordData,
				filterModel: filters,
			})
			if (!filteredRow) {
				return _records
			}
		}
		return [..._records, recordData]
	}, [])

	if (!groupingFields.length) {
		return {
			rows: resolvedRecords,
			groupNames: new Map(),
		}
	}

	const groupNames = new Map<string, string>()
	const rows = resolvedRecords.reduce((_records: ViewRow[], recordData) => {
		for (const groupingField of groupingFields) {
			const value = recordData[groupingField.groupColumnId]
			const groupedColumn = mappedColumns.get(groupingField.groupColumnId)

			const valueOption = groupedColumn?.valueOptions?.find(
				(_option) => _option._id === value,
			)?.label
			const groupedValue = valueOption ?? recordData[groupingField.groupColumnId]
			const sanitizedGroupedValue = getSanitizedGroupedValue(groupedValue)
			if (!groupNames.has(sanitizedGroupedValue)) {
				groupNames.set(sanitizedGroupedValue, groupedValue)
			}
			recordData.hierarchy.push({
				_id: sanitizedGroupedValue,
				label: groupedValue,
			})
		}
		if (recordData.hierarchy.length === 0) {
			return _records
		}

		const sanitizedGroupedValue = getSanitizedGroupedValue(recordData._id)
		if (!groupNames.has(sanitizedGroupedValue)) {
			groupNames.set(sanitizedGroupedValue, recordData._id)
		}
		recordData.hierarchy.push({
			_id: sanitizedGroupedValue,
			label: recordData._id,
		})
		return [..._records, recordData]
	}, [])

	return {
		rows,
		groupNames,
	}
}

export const useRows = ({
	showChildren,
	groupingFields,
	filters,
	applyFilters = true,
}: {
	showChildren: boolean
	groupingFields: TableTypes.GroupedField[]
	filters: GridFilterModel
	applyFilters?: boolean
}): {
	rows: ViewRow[]
	groupNames: Map<string, string>
} => {
	const { tableConfig, mappedColumns, resolvedRows, columnFilterList } = useContext(TableContext)
	return useMemo((): { rows: ViewRow[]; groupNames: Map<string, string> } => {
		if (!tableConfig) {
			return {
				rows: [],
				groupNames: new Map(),
			}
		}

		return getRows({
			groupingFields,
			columnFilterList,
			mappedColumns,
			filters,
			resolvedRows,
			applyFilters,
		})
	}, [columnFilterList, showChildren, JSON.stringify(groupingFields), resolvedRows, filters])
}
