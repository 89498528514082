import { createContext } from 'react'

import { TaskProviderChildProps } from 'src/providers/table-provider/types'

export default createContext<TaskProviderChildProps>({
	tableConfig: undefined,
	isLoadingTable: false,
	hardSave: async () => {},
	isUpdatingTable: false,
	updateRecords: async () => [],
	discardAllChanges: () => {},
	onAddRow: async () => {},
	onAddColumn: async () => {},
	columnList: [],
	columnFilterList: [],
	mappedColumns: new Map(),
	mappedRecords: new Map(),
	mappedRowData: new Map(),
	onUpdateColumn: async () => {},
	onDeleteColumn: async () => {},
	onDeleteRecords: async () => {},
	updateTableConfig: async () => {},
	mappedValueOptions: {},

	// table browser
	linkedTable: undefined,
	resolvedRows: [],
	hardUpdateRecords: async () => {},
	columns: [],
	cachedRowIds: new Set(),
	referenceTables: {},
	saveNewReferenceTables: () => {},
	cacheRowUpdates: () => {},
	blueprintRows: [],
})
