import { MenuItem, Menu } from '@mui/material'
import clipboard from 'clipboardy'
import _isEmpty from 'lodash/isEmpty'
import { ComponentType, useCallback, useContext, useMemo, useState } from 'react'
import { TableTypes } from '@cango-app/sdk/types'
import _has from 'lodash/has'
import { useSelector } from 'react-redux'

import { showSnackbar } from 'src/helpers/snackbarManager'
import { TableContext } from 'src/providers/table-provider'
import { Modal } from 'src/components'
import { selectors as authSelectors } from 'src/store/modules/auth'
import { getNewUniqueId, getRowReferences } from 'src/modules/tables/utils'
import { selectors as projectSelectors } from 'src/store/modules/projects-v3'

import { CalculationModal } from '../column-settings/calculation-modal/calculation-modal'

import { COPY_CLIPBOARD } from './utils'

type Props = {
	contextMenu: { mouseX: number; mouseY: number } | null
	handleClose: () => void
	cellData: { rowId: string; columnId: string } | undefined
}

const environment = import.meta.env.VITE_ENVIRONMENT as string

export const RightClickMenuActions: ComponentType<Props> = ({
	contextMenu,
	handleClose,
	cellData = { rowId: '', columnId: '' },
}) => {
	const authHeaders = useSelector(authSelectors.getAuthHeaders)
	const [openRowCalculationModal, setOpenRowCalculationModal] = useState(false)
	const {
		mappedRecords,
		mappedColumns,
		updateRecords,
		tableConfig,
		referenceTables,
		resolvedRows,
	} = useContext(TableContext)
	const selectedProjectId = useSelector(projectSelectors.getSelectedProjectId)
	const row = mappedRecords.get(cellData.rowId)
	const column = mappedColumns.get(cellData.columnId)

	const copyToClipboard = useCallback(
		async (type: COPY_CLIPBOARD['type']) => {
			const selectedRow = mappedRecords.get(cellData.rowId)
			if (!selectedRow) {
				showSnackbar('Row not found', { variant: 'error' })
				return
			}
			switch (type) {
				case 'data': {
					const selectedCell = selectedRow.data[cellData.columnId]
					const dataInfo = JSON.stringify({
						type,
						data: selectedCell,
					})
					await clipboard.write(dataInfo)
					break
				}
				case 'row_calculation': {
					const selectedCell = selectedRow.calculations[cellData.columnId]
					const dataInfo = JSON.stringify({
						type,
						data: selectedCell,
					})
					await clipboard.write(dataInfo)
					break
				}
				case 'descendants': {
					if (!selectedRow.descendants) {
						return
					}
					const dataInfo = JSON.stringify({
						type,
						data: selectedRow.descendants,
					})
					await clipboard.write(dataInfo)
					break
				}
				case 'cell': {
					const dataInfo = JSON.stringify({
						type,
						data: cellData,
					})
					await clipboard.write(dataInfo)
					break
				}
			}
		},
		[cellData, mappedRecords],
	)

	const handleAssignUniqueId = useCallback(async () => {
		if (!row || !column) return
		const existingValue = row.data[column._id]
		if (existingValue) {
			const confirm = window.confirm(
				`This will overwrite the existing value of ${existingValue}. Are you sure you want to continue?`,
			)
			if (!confirm) return
		}

		const uniqueId = getNewUniqueId({
			columnId: column._id,
			resolvedRows,
		})

		await updateRecords({
			projectId: selectedProjectId,
			rows: [
				{
					oldRow: row,
					newRow: {
						...row,
						data: {
							...row.data,
							[column._id]: uniqueId,
						},
					},
				},
			],
		})
		handleClose()
	}, [row, column, resolvedRows])

	const hasCellCalculations = useMemo(() => {
		if (!cellData) return false
		const selectedRow = mappedRecords.get(cellData.rowId)
		if (!selectedRow) return false
		return _isEmpty(selectedRow.calculations?.[cellData.columnId])
	}, [cellData, mappedRecords])

	const hasCellDescendants = useMemo(() => {
		if (!cellData) return false
		const selectedRow = mappedRecords.get(cellData.rowId)
		if (!selectedRow) return false
		return _isEmpty(selectedRow.descendants)
	}, [cellData, mappedRecords])

	const selectedRow = useMemo(() => {
		return mappedRecords.get(cellData?.rowId)
	}, [mappedRecords, cellData])

	const onClearOverrodeValues = useCallback(async () => {
		if (!selectedRow) return
		const originalRecord = mappedRecords.get(selectedRow._id)
		if (!originalRecord) {
			return
		}
		const newOverrides = { ...selectedRow.overrides }
		delete newOverrides[cellData.columnId]
		const newRecord = {
			...originalRecord,
			overrides: newOverrides,
		}

		const { newReferences } = await getRowReferences({
			record: newRecord,
			records: [...mappedRecords.values()],
			columnsChanged: [cellData.columnId],
			mappedColumns,
			vLookupTables: tableConfig?.vLookupTables,
			authHeaders,
			referenceTables,
			projectId: selectedProjectId,
		})

		newRecord.references = newReferences

		updateRecords({
			rows: [
				{
					oldRow: originalRecord,
					newRow: {
						...originalRecord,
						overrides: newOverrides,
					},
				},
			],
			save: true,
			projectId: selectedProjectId,
		})
	}, [selectedRow, cellData])

	return (
		<>
			<Menu
				open={contextMenu !== null}
				onClose={handleClose}
				anchorReference="anchorPosition"
				anchorPosition={
					contextMenu !== null
						? {
								top: contextMenu.mouseY,
								left: contextMenu.mouseX,
							}
						: undefined
				}
			>
				<MenuItem
					onClick={() => copyToClipboard('data')}
					disabled={column?.type === TableTypes.FieldType.QUESTIONAIRE_REFERENCE}
				>
					Copy Cell
				</MenuItem>
				<MenuItem
					disabled={hasCellCalculations}
					onClick={() => {
						copyToClipboard('row_calculation')
						handleClose()
					}}
				>
					Copy calculation
				</MenuItem>
				<MenuItem
					disabled={hasCellDescendants}
					onClick={() => {
						copyToClipboard('descendants')
						handleClose()
					}}
				>
					Copy descendants
				</MenuItem>
				<MenuItem
					onClick={onClearOverrodeValues}
					disabled={!_has(row?.overrides, cellData.columnId)}
				>
					Clear override values
				</MenuItem>
				{environment === 'development' && (
					<MenuItem
						onClick={() => {
							copyToClipboard('cell')
							// eslint-disable-next-line
							console.log('CELL DATA: ', cellData)
							handleClose()
						}}
					>
						[dev] Copy cellData
					</MenuItem>
				)}
				{cellData?.rowId && (
					<MenuItem onClick={() => setOpenRowCalculationModal(true)}>Row Calculation</MenuItem>
				)}
				{column?.type === TableTypes.FieldType.UNIQUE_ID && (
					<MenuItem onClick={handleAssignUniqueId}>Assign unique ID</MenuItem>
				)}
			</Menu>
			{cellData && (
				<Modal open={openRowCalculationModal} onClose={() => setOpenRowCalculationModal(false)}>
					<CalculationModal
						defaultCalculation={selectedRow?.calculations?.[cellData.columnId] ?? []}
						columnId={cellData?.columnId ?? ''}
						onClose={() => setOpenRowCalculationModal(false)}
						rowId={cellData?.rowId}
					/>
				</Modal>
			)}
		</>
	)
}
