import { useNavigate, useParams } from 'react-router-dom'

import { RouteId } from 'src/constants/routes'

export const useProject = () => {
	const navigate = useNavigate()
	const { projectId, selectedTaskId } = useParams<{
		projectId: string
		selectedTaskId?: string
	}>()

	const goToTask = (taskId?: string) => {
		if (!taskId) {
			navigate(`/${RouteId.Project}/${projectId}`)
			return
		}

		navigate(`/${RouteId.Project}/${projectId}/${taskId}`)
	}

	return {
		projectId,
		selectedTaskId,
		goToTask,
	}
}
