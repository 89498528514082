import { FilesSdk, V3ProjectSdk, MyTasksV3Sdk } from '@cango-app/sdk/api'
import { ChainTypes, ProjectTypes, TaskTypes } from '@cango-app/sdk/types'

import { ListedStep } from 'src/modules/chains/types'

export type ListedProject = MyTasksV3Sdk.Project
export type ActiveProject = ProjectTypes.PopulatedProject

export enum ProjectFilesState {
	Loading = 'loading',
	Error = 'error',
	Idle = 'idle',
}

export enum ProjectsLoadingState {
	Loading = 'loading',
	Error = 'error',
	Idle = 'idle',
	Fetched = 'fetched',
}

export enum TaskListType {
	Active = 'active',
	ProjectTasks = 'project-tasks',
}

export interface ProjectsStateV3 {
	allProjects: {
		[_id: string]: ListedProject
	}
	project: ActiveProject | undefined
	tableId?: string
	project_wide_chains: { _id: string; label: string }[]
	database_ctas: ChainTypes.DatabaseCTAsChains[]
	tasks: TaskTypes.PopulatedTask[]
	projectCards: V3ProjectSdk.Card[]
	projectFiles: FilesSdk.GetFilesByFolderIdResponse
	projectFilesState: ProjectFilesState
	flaggedTaskId?: string
	isChatModalOpen: boolean
	filesToUpload: number
	blockOptions: MyTasksV3Sdk.BlockOptionsResponse
	isLoadingBlockOptions: boolean
	loadingProjectState: ProjectsLoadingState
	loadingProjectsState: ProjectsLoadingState
	tasksState: ProjectsLoadingState
	myTaskListType: TaskListType
	project_steps: ListedStep[]
	chain_starters: Pick<ChainTypes.BlueprintChain, '_id' | 'begins_with'>[]
	cachedQuestionnaireAnswers: ProjectTypes.QuestionaireAnswer[]
}

export enum SectionBlockedReason {
	None = 'none',
	TasksAssignedToOtherUser = 'tasks-assigned-to-other-user',
}

export type CompleteTaskResponse = Omit<V3ProjectSdk.CompleteTaskResponse, 'sectionCount'> & {
	projectId: string
	userId: string
	taskId: string
	nextTaskId: string | undefined
}
