import { ComponentType } from 'react'
import InputAdornment from '@mui/material/InputAdornment'
import ClearIcon from '@mui/icons-material/Clear'
import IconButton from '@mui/material/IconButton'

import { Box } from '../box'
import { TextField } from '../text-field'
import { Select } from '../select'
import { Grid } from '../grid'

export enum SortMethod {
	AZ = '1',
	ZA = '2',
	DateCreatedOldestFirst = '3',
	DateCreatedNewestFirst = '4',
}

type MenuFiltersProps = {
	onSortMethodChange: (method: SortMethod) => void
	selectedSortMethod: string
	onFilterByNameChange: (name: string) => void
	filterByName: string
}

export const MenuFilters: ComponentType<MenuFiltersProps> = ({
	onSortMethodChange,
	selectedSortMethod,
	onFilterByNameChange,
	filterByName,
}) => {
	return (
		<Box px={4} py={2}>
			<Grid container>
				<Grid item xs={4} />
				<Grid item xs={4}>
					<TextField
						label="Search"
						size="small"
						containerProps={{ mb: 0 }}
						fullWidth
						value={filterByName}
						onChange={(event) => onFilterByNameChange(event.target.value)}
						InputProps={{
							sx: {
								px: 0,
								bgcolor: 'white',
							},
							endAdornment: (
								<InputAdornment position="end">
									<IconButton onClick={() => onFilterByNameChange('')}>
										<ClearIcon fontSize="small" />
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
				</Grid>
				<Grid item xs={4} display="flex" justifyContent="flex-end">
					<Select
						label="Sort by"
						fullWidth
						size="small"
						disableOrdering
						containerProps={{ width: 300 }}
						value={selectedSortMethod}
						onChange={(event) => onSortMethodChange(event.target.value as SortMethod)}
						options={[
							{ _id: SortMethod.AZ, label: 'A-Z' },
							{ _id: SortMethod.ZA, label: 'Z-A' },
							{ _id: SortMethod.DateCreatedNewestFirst, label: 'Date created (new -> old)' },
							{ _id: SortMethod.DateCreatedOldestFirst, label: 'Date created (old -> new)' },
						]}
					/>
				</Grid>
			</Grid>
		</Box>
	)
}
