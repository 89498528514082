import { ThunkDispatch, Action } from '@reduxjs/toolkit'

import { ImagesState } from 'src/store/modules/images'

import { NotesState } from './modules/notes'
import { AuthState } from './modules/auth'
import { UserState } from './modules/user'
import { RolesState } from './modules/roles'
import { ContactState } from './modules/contacts'
import { ConfigState } from './modules/config'
import { ProjectsStateV3 } from './modules/projects-v3'
import { PersistedConfigState } from './modules/persisted-config'
import { RemoteConfigState } from './modules/remote-config'
import { SessionConfigState } from './modules/session-config'
import { FilesState } from './modules/persisted-files'
import { TablesState } from './modules/tables'

export enum CangoReduxModuleName {
	CangoAuth = 'cangoAuth',
	CangoUser = 'cangoUser',
	CangoRoles = 'cangoRoles',
	CangoContacts = 'cangoContacts',
	CangoConfig = 'cangoConfig',
	CangoProjectsV3 = 'cangoProjectsV3',
	CangoNotes = 'cangoNotes',
	CangoPersistedConfig = 'cangoPersistedConfig',
	CangoRemoteConfig = 'cangoRemoteConfig',
	CangoSession = 'cangoSession',
	CangoPersistedFiles = 'cangoPersistedFiles',
	CangoImages = 'cangoImages',
	CangoTables = 'cangoTables',
}

export type RootState = {
	[CangoReduxModuleName.CangoAuth]: AuthState
	[CangoReduxModuleName.CangoConfig]: ConfigState
	[CangoReduxModuleName.CangoUser]: UserState
	[CangoReduxModuleName.CangoRoles]: RolesState
	[CangoReduxModuleName.CangoContacts]: ContactState
	[CangoReduxModuleName.CangoProjectsV3]: ProjectsStateV3
	[CangoReduxModuleName.CangoNotes]: NotesState
	[CangoReduxModuleName.CangoPersistedConfig]: PersistedConfigState
	[CangoReduxModuleName.CangoRemoteConfig]: RemoteConfigState
	[CangoReduxModuleName.CangoSession]: SessionConfigState
	[CangoReduxModuleName.CangoPersistedFiles]: FilesState
	[CangoReduxModuleName.CangoImages]: ImagesState
	[CangoReduxModuleName.CangoTables]: TablesState
}

export type AsyncDispatchType = ThunkDispatch<RootState, void, Action>
