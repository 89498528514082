import { Dispatch, createAsyncThunk } from '@reduxjs/toolkit'
import { RoleSdk } from '@cango-app/sdk/api'

import { showSnackbar } from 'src/helpers/snackbarManager'
import { errorHandler } from 'src/helpers/api'

import { RootState } from '../../types'
import { selectors as authSelectors } from '../auth'
import { selectors as configSelectors } from '../config'

export const getRoles = createAsyncThunk<
	RoleSdk.GetRolesResponse,
	void,
	{ rejectValue: string; state: RootState; dispatch: Dispatch }
>('roles/getRoles', async (_, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState()
		const headers = authSelectors.getAuthHeaders(state)
		const response = await RoleSdk.getAll(import.meta.env.VITE_API as string, headers)
		return response
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue((error as Error).message)
	}
})

export const addRole = createAsyncThunk<
	{ role: RoleSdk.CreateRoleResponse; organisationId: string | undefined },
	RoleSdk.CreateRoleRequest,
	{ state: RootState; rejectValue: string; dispatch: Dispatch }
>(
	'roles/addRole',
	async (role: RoleSdk.CreateRoleRequest, { getState, rejectWithValue, dispatch }) => {
		try {
			const state = getState()
			const headers = authSelectors.getAuthHeaders(state)
			const organisationId = configSelectors.getOrganisationId(state)
			const response = await RoleSdk.create(import.meta.env.VITE_API as string, headers, role)
			showSnackbar('Role added', { variant: 'success' })
			return { role: response, organisationId }
		} catch (error) {
			errorHandler({ error, dispatch })
			return rejectWithValue((error as Error).message)
		}
	},
)

export const updateRole = createAsyncThunk<
	{ roles: RoleSdk.UpdateRoleResponse; organisationId: string | undefined },
	RoleSdk.UpdateRoleRequest,
	{ state: RootState; rejectValue: string; dispatch: Dispatch }
>('roles/updateRole', async (role, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState()
		const headers = authSelectors.getAuthHeaders(state)
		const organisationId = configSelectors.getOrganisationId(state)
		const response = await RoleSdk.update(import.meta.env.VITE_API as string, headers, role)
		showSnackbar('Role updated', { variant: 'success' })
		return { roles: response, organisationId }
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue((error as Error).message)
	}
})

export const deleteRole = createAsyncThunk<
	{ roleId: string; organisationId: string | undefined },
	string,
	{ rejectValue: string; state: RootState }
>('roles/deleteRole', async (roleId, { getState, rejectWithValue }) => {
	try {
		const state = getState()
		const headers = authSelectors.getAuthHeaders(state)
		const organisationId = configSelectors.getOrganisationId(state)
		await RoleSdk.deleteRole(import.meta.env.VITE_API as string, headers, { _id: roleId })
		showSnackbar('Role deleted', { variant: 'success' })
		return { roleId, organisationId }
	} catch (error: any) {
		showSnackbar("Cannot delete role while it's in use", { variant: 'error' })
		return rejectWithValue((error as Error).message)
	}
})

export const getRolesWithUsage = createAsyncThunk<
	{ organisationId: string | undefined; rolesWithUsage: RoleSdk.GetRolesWithUsageResponse },
	void,
	{ rejectValue: string; state: RootState; dispatch: Dispatch }
>('roles/get-with-usage', async (_, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState()
		const headers = authSelectors.getAuthHeaders(state)
		const organisationId = configSelectors.getOrganisationId(state)
		const response = await RoleSdk.getRolesWithUsage(import.meta.env.VITE_API as string, headers)
		return { organisationId, rolesWithUsage: response }
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue((error as Error).message)
	}
})
