import { TaskTypes } from '@cango-app/sdk/types'
import { ComponentType, useCallback, useEffect } from 'react'
import { Stack } from '@mui/material'
import juice from 'juice'
import { Page, Document, usePDF, Font } from '@react-pdf/renderer'
import { Html } from 'react-pdf-html'
import { useSelector } from 'react-redux'

import { Box, Button, Text, TipTapContentWithStyles } from 'src/components'
import { useResolveTemplate } from 'src/hooks/useResolveTemplate'
import { TableProvider } from 'src/providers'
import { useRichEditor, EditorProvider } from 'src/modules/templates-editor/useRichEditor'
import { showSnackbar } from 'src/helpers/snackbarManager'
import { EditorOptions } from 'src/modules/templates-editor/editor-options'
import { selectors as projectSelectors } from 'src/store/modules/projects-v3'

Font.register({
	family: 'Open Sans',
	src: 'https://fonts.gstatic.com/s/opensans/v18/mem8YaGs126MiZpBA-UFVZ0e.ttf',
})

const EditorComponent = ({ templateName }: { templateName: string }) => {
	const { editor } = useRichEditor()
	const [instance, setInstance] = usePDF()

	const getContentWithStyles = useCallback(async () => {
		if (!editor) return ''
		const htmlContent = editor.getHTML()

		const response = await fetch('/rich-editor-styles.css')

		if (!response.ok) {
			showSnackbar('Error getting table styles', { variant: 'error' })
			return ''
		}
		const styles = await response.text()

		return `
        <style>${styles}</style>
      <div class="tableWrapper">
      ${htmlContent}</div>
    `
	}, [editor])

	const onCopyContent = useCallback(async () => {
		const fullHtmlContent = await getContentWithStyles()
		const htmlWithInlineStyles = juice(fullHtmlContent)
		const blob = new Blob([htmlWithInlineStyles], { type: 'text/html' })
		const clipboardItem = new ClipboardItem({ 'text/html': blob })
		navigator.clipboard
			.write([clipboardItem])
			.then(() => {
				showSnackbar('Template copied!', { variant: 'success' })
			})
			.catch(() => {
				showSnackbar('Failed to copy template', { variant: 'error' })
			})
	}, [getContentWithStyles])

	if (!editor) return null

	useEffect(() => {
		if (instance.blob) {
			const link = document.createElement('a')
			link.href = URL.createObjectURL(instance.blob)
			link.download = `${templateName}.pdf`
			document.body.appendChild(link)
			link.click()
			document.body.removeChild(link)
		}
	}, [instance])

	const handleDownloadPDF = useCallback(async () => {
		const response = await fetch('/rich-editor-styles.css')

		if (!response.ok) {
			showSnackbar('Error getting table styles', { variant: 'error' })
			return
		}
		const styles = await response.text()

		const html = `
      <html>
        <body>
          <style>${styles}</style>
          <div class="tableWrapper template">${editor?.getHTML()}</div>
        </body>
      </html>`

		const newContent = (
			<Document>
				<Page size="LETTER" style={{ padding: 20 }}>
					<Html resetStyles={true}>{html}</Html>
				</Page>
			</Document>
		)
		setInstance(newContent)
	}, [editor])
	return (
		<Box width="100%">
			<EditorOptions />
			<TipTapContentWithStyles editor={editor} />
			<Stack marginTop={2} width="450px" direction="row" spacing={2}>
				<Button onClick={onCopyContent}>Copy Template</Button>
				<Button onClick={handleDownloadPDF}>Download PDF</Button>
			</Stack>
		</Box>
	)
}

type Props = {
	action: TaskTypes.TaskAction
}

const TemplateComponent: ComponentType<Props> = ({ action }) => {
	const { content } = useResolveTemplate({ template: action.template })

	if (!action.template || !content) return null

	return (
		<>
			<EditorProvider defaultContent={content}>
				<EditorComponent templateName={action.template.template.name} />
			</EditorProvider>
		</>
	)
}

export const Template: ComponentType<Props> = (props) => {
	const projectTableId = useSelector(projectSelectors.getSelectedProjectTableId)

	if (!projectTableId) {
		return (
			<Box>
				<Text>No project table selected.</Text>
			</Box>
		)
	}
	return (
		<TableProvider tableId={projectTableId}>
			<TemplateComponent {...props} />
		</TableProvider>
	)
}
