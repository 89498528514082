import { V3ProjectSdk } from '@cango-app/sdk/api'
import { ComponentType, useCallback, useContext, useEffect, useState } from 'react'
import { StepTypes, TaskTypes, ProjectTypes } from '@cango-app/sdk/types'
import { useDispatch, useSelector } from 'react-redux'
import PulseLoader from 'react-spinners/PulseLoader'

import { Box, DriveUploadButton } from 'src/components'
import { DriveFilesContext, TaskContext } from 'src/providers'
import { selectors as authSelectors } from 'src/store/modules/auth'
import { errorHandler } from 'src/helpers/api'
import { AsyncDispatchType } from 'src/store/types'
import { showSnackbar } from 'src/helpers/snackbarManager'

import { TaskFiles } from './task-files'

type TemplateProject = Pick<ProjectTypes.Project, 'name' | 'google_drive_folder_id'>

type FileTemplateProps = {
	task: Pick<
		TaskTypes.PopulatedTask,
		'_id' | 'name' | 'project_id' | 'section' | 'actions' | 'attachments'
	>
	project?: TemplateProject
	action: TaskTypes.TaskAction
	isAttachment?: boolean
}

export const FileTemplate: ComponentType<FileTemplateProps> = ({
	task,
	project: _project,
	action,
	isAttachment = false,
}) => {
	const dispatch = useDispatch<AsyncDispatchType>()
	const { parentFolderId } = useContext(DriveFilesContext)
	const [isProjectLoading, setIsProjectLoading] = useState(false)
	const [project, setProject] = useState<TemplateProject | undefined>(_project)
	const authHeaders = useSelector(authSelectors.getAuthHeaders)
	const { updateTask } = useContext(TaskContext)
	const typeOfTaskElement: keyof Pick<TaskTypes.PopulatedTask, 'actions' | 'attachments'> =
		isAttachment ? 'attachments' : 'actions'
	const fileTemplateIndex = task[typeOfTaskElement].findIndex(
		(_task) => _task.type === StepTypes.Action.ActionEnum.FileTemplate,
	)
	const actionIndex = task[typeOfTaskElement].findIndex(({ _id }) => _id === action._id)

	const fetchProject = useCallback(async () => {
		if (!task.project_id) return
		try {
			setIsProjectLoading(true)
			const response = await V3ProjectSdk.getProject({
				baseURL: import.meta.env.VITE_API as string,
				authHeaders,
				projectId: task.project_id,
			})

			setProject({
				name: response.project.name,
				google_drive_folder_id: response.project.google_drive_folder_id,
			})
		} catch (error) {
			errorHandler({ error, dispatch })
		} finally {
			setIsProjectLoading(false)
		}
	}, [task?.project_id])

	const handleChangeFileIds = async (fileIds: string[]) => {
		try {
			const response = await V3ProjectSdk.addFileIdsToTask(
				import.meta.env.VITE_API as string,
				authHeaders,
				{
					taskId: task._id,
					fileIds,
					actionIndex: fileTemplateIndex,
					isAttachment,
				},
			)
			updateTask(`${typeOfTaskElement}.${fileTemplateIndex}.file_ids`, fileIds, {
				localStateKey: `${typeOfTaskElement}[${fileTemplateIndex}].file_ids`,
			})
			updateTask(
				`${typeOfTaskElement}.${fileTemplateIndex}.file_history`,
				response.file_history ?? [],
				{
					localStateKey: `${typeOfTaskElement}[${fileTemplateIndex}].file_history`,
				},
			)
		} catch (error) {
			showSnackbar('Error adding file(s)', { variant: 'error' })
		}
	}

	useEffect(() => {
		if (!_project) {
			fetchProject()
		}
	}, [])

	if (isProjectLoading) {
		return (
			<Box>
				<PulseLoader size={4} />
			</Box>
		)
	}

	if (!parentFolderId || !project) {
		return null
	}
	return (
		<Box>
			<Box mb={1} display="flex" justifyContent={{ mobile: 'center', laptop: 'flex-start' }}>
				<DriveUploadButton
					onFileIdsChange={handleChangeFileIds}
					selectedFilesIds={task[typeOfTaskElement][fileTemplateIndex]?.file_ids ?? []}
					ctaVariant={
						task[typeOfTaskElement][fileTemplateIndex]?.file_ids.length ? 'replaceIcon' : 'button'
					}
				/>
			</Box>
			<TaskFiles
				action={action}
				actionIndex={actionIndex}
				typeOfTaskElement={isAttachment ? 'attachments' : 'actions'}
			/>
		</Box>
	)
}
