import { FilesSdk } from '@cango-app/sdk/api'
import * as Sentry from '@sentry/react'
import { createAsyncThunk, Dispatch } from '@reduxjs/toolkit'
import workerpool, { Pool } from 'workerpool'

import { errorHandler } from 'src/helpers/api'
import { showSnackbar } from 'src/helpers/snackbarManager'

import { selectors as authSelectors } from '../auth'
import { RootState, AsyncDispatchType } from '../../types'

import { selectors as persistedFilesSelector } from './selectors'
// @ts-ignore
// eslint-disable-next-line import/default
import WorkerURL from './upload-file-to-google-worker?url&worker'
import { ErrorEvent, UpdateStagedFilesEvent } from './types'
import { actions as persistedFilesActions } from './index'

const WORKER_POOLS = new Map<string, Pool>()

export const addNewFilesToCollection = createAsyncThunk<
	{ id: string; files: FilesSdk.File[]; parentFolderName?: string },
	{ parentFolderId: string; parentFolderName?: string },
	{
		rejectValue: string
		state: RootState
		dispatch: Dispatch
	}
>(
	'files/addNewFilesToCollection',

	async ({ parentFolderId, parentFolderName }, { getState, dispatch, rejectWithValue }) => {
		try {
			const state = getState()
			const authHeaders = authSelectors.getAuthHeaders(state)
			const projectFiles = persistedFilesSelector.getProjectFiles(state, parentFolderId)
			const files = await FilesSdk.getFilesByFolderId(
				import.meta.env.VITE_API as string,
				authHeaders,
				{
					parentFolderId,
				},
			)

			const newFiles = files.filter(
				(_file) => !projectFiles.some((_projectFile) => _projectFile.id === _file.id),
			)

			return {
				parentFolderName,
				id: parentFolderId,
				files: newFiles,
			}
		} catch (error) {
			errorHandler({ error, dispatch })
			Sentry.captureException(error)
			return rejectWithValue((error as Error).message)
		}
	},
)

export const fetchFiles = createAsyncThunk<
	{ id: string; files: FilesSdk.File[]; parentFolderName: string },
	{ parentFolderId: string; parentFolderName: string },
	{
		rejectValue: string
		state: RootState
		dispatch: Dispatch
	}
>(
	'files/fetchFiles',
	async ({ parentFolderId, parentFolderName }, { getState, dispatch, rejectWithValue }) => {
		try {
			const state = getState()
			const authHeaders = authSelectors.getAuthHeaders(state)
			const files = await FilesSdk.getFilesByFolderId(
				import.meta.env.VITE_API as string,
				authHeaders,
				{
					parentFolderId,
				},
			)
			return { id: parentFolderId, files, parentFolderName }
		} catch (error) {
			errorHandler({ error, dispatch, message: 'Could not fetch files' })
			return rejectWithValue((error as Error).message)
		}
	},
)

export const initWorkerPool = createAsyncThunk<
	void,
	{ parentFolderId: string },
	{
		state: RootState
		dispatch: Dispatch
	}
>('files/initWorkerPool', async ({ parentFolderId }, { dispatch }) => {
	try {
		if (WORKER_POOLS.has(parentFolderId)) {
			return
		}
		const pool = workerpool.pool(WorkerURL, {
			workerTerminateTimeout: 2400000,
			/** @ts-ignore */
			workerOpts: {
				type: import.meta.env.PROD ? undefined : 'module',
			},
		})

		WORKER_POOLS.set(parentFolderId, pool)
	} catch (error) {
		errorHandler({ error, dispatch })
	}
})

export const terminateWorkerPool = createAsyncThunk<
	{ id: string },
	{ parentFolderId: string; force?: boolean; parentFolderName: string },
	{
		rejectValue: string
		state: RootState
		dispatch: AsyncDispatchType
	}
>(
	'files/terminateWorkerPool',
	async ({ parentFolderId, force = false, parentFolderName }, { dispatch, rejectWithValue }) => {
		try {
			const pool = WORKER_POOLS.get(parentFolderId)
			if (pool) {
				await pool.terminate(force)
				// In case the worker was not forced to terminate, it will finish the uploads before terminating
				// so we need to fetch the files again to get the updated list
				if (!force) {
					await dispatch(fetchFiles({ parentFolderId, parentFolderName }))
				}
			}
			WORKER_POOLS.delete(parentFolderId)
			return { id: parentFolderId }
		} catch (error) {
			errorHandler({ error, dispatch })
			return rejectWithValue((error as Error).message)
		}
	},
)

export const triggerUploadInWorkerPool = createAsyncThunk<
	void,
	{ parentFolderId: string; googleAccessToken: string; parentFolderName?: string },
	{
		state: RootState
		dispatch: Dispatch
	}
>(
	'files/triggerUploadInWorkerPool',
	async ({ parentFolderId, googleAccessToken, parentFolderName }, { dispatch, getState }) => {
		try {
			const pool = WORKER_POOLS.get(parentFolderId)
			if (!pool) {
				// eslint-disable-next-line no-console
				console.warn('Skipping upload: workers pool is unavailable')
				return
			}
			const state = getState()
			const stagedFiles = persistedFilesSelector.getStagedFilesWithBinaryData(state, parentFolderId)
			await pool.exec('uploadFiles', [{ stagedFiles, googleAccessToken, parentFolderId }], {
				on: (message: UpdateStagedFilesEvent | ErrorEvent) => {
					if (message.name === 'update_staged_files') {
						const newStagedFiles = message.data.stagedFiles.map(
							// eslint-disable-next-line @typescript-eslint/no-unused-vars
							({ file, ...stagedFile }) => stagedFile,
						)

						dispatch(
							persistedFilesActions.setStagedFiles({
								parentFolderName,
								id: parentFolderId as string,
								stagedFiles: newStagedFiles,
							}),
						)
					}
					if (message.name === 'error') {
						showSnackbar(message.error.message, { variant: 'error' })
					}
				},
			})
		} catch (error) {
			if ((error as Error).message === 'Worker terminated') {
				return
			}
			// eslint-disable-next-line no-console
			console.error('Error uploading files', error)
		}
	},
)
