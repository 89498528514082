import { createSlice } from '@reduxjs/toolkit'
import _merge from 'lodash/merge'

import * as thunks from './thunks'
import { RolesState } from './types'

const initialState: RolesState = {
	roles: {},
}

export const roleSlice = createSlice({
	name: 'roles',
	initialState,
	reducers: {
		endSession: () => initialState,
	},
	extraReducers: (builder) => {
		builder.addCase(thunks.getRoles.fulfilled, (state: RolesState, action) => {
			state.roles = _merge(state.roles, action.payload)
		})
		builder.addCase(thunks.getRolesWithUsage.fulfilled, (state: RolesState, action) => {
			const { organisationId, rolesWithUsage } = action.payload
			if (!organisationId) {
				return
			}
			state.roles[organisationId] = rolesWithUsage
		})
		builder.addCase(thunks.addRole.fulfilled, (state: RolesState, action) => {
			const { role, organisationId } = action.payload
			if (!organisationId) return
			const rolesInOrganisation = state.roles[organisationId]
			state.roles = {
				...state.roles,
				[organisationId]: [...rolesInOrganisation, role],
			}
		})
		builder.addCase(thunks.updateRole.fulfilled, (state: RolesState, action) => {
			const { roles, organisationId } = action.payload
			if (!organisationId) return
			const organisationRoles = roles
			if (!organisationRoles) return
			state.roles = {
				...state.roles,
				[organisationId]: organisationRoles.map((_role) => {
					const foundOldRole = state.roles[organisationId].find(({ _id }) => _id === _role._id)
					return {
						..._role,
						usage: foundOldRole?.usage,
					}
				}),
			}
		})
		builder.addCase(thunks.deleteRole.fulfilled, (state: RolesState, action) => {
			const { organisationId, roleId } = action.payload
			if (!organisationId) {
				return
			}
			const roleIndex = state.roles[organisationId].map(({ _id }) => _id).indexOf(roleId)
			state.roles[organisationId].splice(roleIndex, 1)
		})
	},
})
