/**
 * Common API handlers - ultimately axios interceptors will be used
 */

import { AuthSdk } from '@cango-app/sdk/api'
import { Dispatch } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'

import { AnalyticsEvent, analytics } from 'src/biz'
import { actions as authActions } from 'src/store/modules/auth'
import { CangoSchemaState, actions as configActions } from 'src/store/modules/config'

import { showSnackbar } from './snackbarManager'

export const errorHandler = ({
	error,
	dispatch,
	message,
}: {
	error: any
	dispatch: Dispatch<any>
	message?: string
}) => {
	if (message) {
		showSnackbar(message, { variant: 'error' })
		analytics.track({
			eventName: AnalyticsEvent.error,
			properties: {
				message,
			},
		})
		return
	}

	const axiosMessage = (error as AxiosError<{ message: string }>).response?.data?.message

	if (axiosMessage) {
		analytics.track({
			eventName: AnalyticsEvent.error,
			properties: {
				message: axiosMessage,
			},
		})
	}

	if (axiosMessage === AuthSdk.AuthErrorMessages.WrongOrganisation) {
		showSnackbar('Wrong organization', { variant: 'error' })
		return
	} else if (axiosMessage === AuthSdk.AuthErrorMessages.Unauthorised) {
		dispatch(authActions.logoutUser())
		return
	} else if (axiosMessage === AuthSdk.AuthErrorMessages.UserDbNotFound) {
		dispatch(authActions.logoutUser())
		return
	} else if (axiosMessage === AuthSdk.AuthErrorMessages.RequiresMigration) {
		dispatch(configActions.setCangoSchemaState({ state: CangoSchemaState.RequiresMigration }))
		return
	} else if (axiosMessage) {
		showSnackbar(axiosMessage, { variant: 'error' })
		return
	}

	if (message) {
		showSnackbar(message, { variant: 'error' })
		analytics.track({
			eventName: AnalyticsEvent.error,
			properties: {
				message,
			},
		})
		return
	}

	if ((error as Error).message) {
		analytics.track({
			eventName: AnalyticsEvent.error,
			properties: {
				message: (error as Error).message,
			},
		})
		showSnackbar((error as Error).message, { variant: 'error' })
		return
	}

	showSnackbar('Something went wrong', { variant: 'error' })
}
