import { ComponentType, useContext, useMemo, useState } from 'react'
import { TableTypes } from '@cango-app/sdk/types'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { v4 } from 'uuid'
import uniqBy from 'lodash/uniqBy'
import { Stack } from '@mui/material'
import Alert from '@mui/material/Alert'

import { TableContext } from 'src/providers/table-provider'
import { Box, Button, Divider, Text, Toggle } from 'src/components'
import { selectors as tableSelectors } from 'src/store/modules/tables'

import { getCleanedUpSingleSelectData } from '../../utils'
import { SingleSelectForm } from '../types'

import { CustomOptions } from './custom-options'
import { ValueOptionFilters } from './value-option-filters'
import { LookupOptions } from './lookup-options'

const SingleSelectSettings: ComponentType<{
	defaultOptions: TableTypes.Field['valueOptions']
	valueOptionFilters: TableTypes.Field['valueOptionFilters']
	columnId: string
	onClose: () => void
	defaultSingleSelectLookup:
		| {
				tableId: string
				fieldId: string
		  }
		| undefined
}> = ({ defaultOptions, columnId, onClose, defaultSingleSelectLookup, valueOptionFilters }) => {
	const { onUpdateColumn, isUpdatingTable, tableConfig, mappedColumns } = useContext(TableContext)
	const tableList = useSelector(tableSelectors.selectTableList)

	const methods = useForm<SingleSelectForm>({
		defaultValues: {
			options: defaultOptions ?? [],
			single_select_lookup: defaultSingleSelectLookup,
			valueOptionFilters,
		},
	})
	const [toogleValue, setToogleValue] = useState<'custom' | 'lookup' | 'filters'>(
		defaultSingleSelectLookup?.tableId ? 'lookup' : 'custom',
	)
	const { control, watch, handleSubmit, setValue } = methods

	const lookupSelected = watch('single_select_lookup')

	const options = watch('options')

	const handleSubmitOptions = async (data: SingleSelectForm) => {
		const cleanedUpData = getCleanedUpSingleSelectData(data, {
			options: defaultOptions,
			single_select_lookup: defaultSingleSelectLookup,
			valueOptionFilters,
		})
		await onUpdateColumn(columnId, {
			...cleanedUpData,
		})
		onClose()
	}

	const toogleOptions = useMemo(() => {
		return [
			{
				label: 'Custom',
				value: 'custom',
				disabled: !!lookupSelected?.tableId,
			},
			{
				label: 'Table lookup',
				value: 'lookup',
				disabled: options.length > 1 && toogleValue !== 'filters',
			},
			{
				label: 'Filters',
				value: 'filters',
				disabled: !options.length,
			},
		]
	}, [mappedColumns, lookupSelected, options, toogleValue, columnId])

	return (
		<FormProvider {...methods}>
			<Box pb={2}>
				<Text variant="h6">Single select options</Text>
				<Divider />
				<Controller
					control={control}
					name="single_select_lookup"
					render={() => {
						const handleToggleChange = (value: string) => {
							if (value === 'custom') {
								setToogleValue('custom')
								lookupSelected.tableId && setValue('options', [{ _id: v4(), label: '' }])
								setValue(
									'single_select_lookup',
									{ tableId: '', fieldId: '' },
									{ shouldDirty: true },
								)
								return
							}
							if (value === 'lookup') {
								setToogleValue('lookup')
								!lookupSelected.tableId &&
									setValue('single_select_lookup', { tableId: tableConfig?._id ?? '', fieldId: '' })
								setValue('options', [])

								return
							}

							if (value === 'filters') {
								setToogleValue('filters')
								setValue('options', [...defaultOptions])
							}
						}

						return (
							<Toggle
								onChange={(value) => handleToggleChange(value)}
								value={toogleValue}
								options={toogleOptions}
								disabled={!tableList.length}
								sx={{ mt: 1 }}
							/>
						)
					}}
				/>
				{toogleValue === 'custom' && <CustomOptions />}
				{toogleValue === 'lookup' && <LookupOptions />}
				{!['custom', 'lookup'].includes(toogleValue) && <ValueOptionFilters columnId={columnId} />}
				<Stack direction="row" spacing={2} width={400} my={2}>
					<Button
						fullWidth
						onClick={handleSubmit(handleSubmitOptions)}
						isLoading={isUpdatingTable}
						disabled={
							(!!lookupSelected?.tableId && !lookupSelected?.fieldId) ||
							(!!options.length && options.length !== uniqBy(options, 'label').length)
						}
					>
						Save
					</Button>
					{['custom', 'lookup'].includes(toogleValue) && (
						<Button
							variant="text"
							fullWidth
							onClick={() => {
								setValue('options', [])
								setValue(
									'single_select_lookup',
									{ tableId: '', fieldId: '' },
									{ shouldDirty: true },
								)
							}}
						>
							Clear All
						</Button>
					)}
				</Stack>
				{!!options.length && options.length !== uniqBy(options, 'label').length && (
					<Alert severity="error" sx={{ mb: 2 }}>
						You have duplicates in your options, please remove them
					</Alert>
				)}
			</Box>
		</FormProvider>
	)
}

export default SingleSelectSettings
