import React, { ChangeEvent, ComponentType, useState } from 'react'
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { FilesSdk } from '@cango-app/sdk/api'

import { CameraIcon, CloseIcon, VideoCameraIcon } from 'src/assets/icons'
import { colors } from 'src/theme/colors'
import { StagedFileWithBinaryData } from 'src/store/modules/persisted-files/types'
import { useFileUpload } from 'src/hooks/useFileUpload'
import googleDriveLogo from 'src/assets/images/google_drive_logo.png'

import { Button } from '../button'
import { Modal } from '../modal'
import { Box } from '../box'

import { UploadPanel } from './panels/upload-panel'
import { FilesPanel } from './panels/files-panel'
import { DriveUploadPanelProps } from './types'
import { UploadConfirmDialog } from './panels/upload-confirm-dialog'

enum DriveUploadView {
	Options = 0,
	Select = 1,
	Upload = 2,
	Photo = 3,
}

export const DriveUploadPanelMobile: ComponentType<DriveUploadPanelProps> = ({
	parentFolderId,
	parentFolderName,
	allFiles,
	setIsOpen,
	isOpen,
	onFileIdsChange,
	selectedFilesIds,
}) => {
	const [showConfirmClose, setShowConfirmClose] = useState<boolean>(false)
	const [view, setView] = useState<DriveUploadView>(DriveUploadView.Options)
	const [selectedFiles, setSelectedFiles] = useState<string[]>(selectedFilesIds)
	const [stagedCameraFiles, setStagedCameraFiles] = useState<StagedFileWithBinaryData[]>([])
	const fileUploadProps = useFileUpload({
		parentFolderId,
		parentFolderName,
		defaultFilesToUpload: stagedCameraFiles,
	})

	const onConfirmClose = (forceStop: boolean) => {
		fileUploadProps.terminateUpload(forceStop)
		setView(DriveUploadView.Options)
		setStagedCameraFiles([])
		setIsOpen(false)
	}

	const handleCloseModal = () => {
		if (fileUploadProps.areFilesUploading) {
			setShowConfirmClose(true)
			return
		}
		onConfirmClose(true)
	}

	const onFilesUploaded = async (uploadedFileIds: string[]) => {
		await onFileIdsChange(uploadedFileIds)
		setSelectedFiles((s) => [...s, ...uploadedFileIds])
		handleCloseModal()
	}

	const handleSubmit = async (fileIds: string[]) => {
		await onFileIdsChange(fileIds)
		handleCloseModal()
	}

	const handleCapture = (event: ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files ? event.target.files[0] : null
		if (file) {
			setStagedCameraFiles([
				...stagedCameraFiles,
				{
					file,
					uploadProgress: 0,
					fileModifiedAt: new Date().getTime(),
					state: FilesSdk.FileUploadState.Pending,
				},
			])
		}
	}

	const handleDeleteStagedCameraFile = (index: number) => {
		const stagedFilesCopy = [...stagedCameraFiles]
		stagedFilesCopy.splice(index, 1)
		setStagedCameraFiles([...stagedFilesCopy])
	}

	const handleSubmitCameraFiles = () => {
		setView(DriveUploadView.Upload)
	}

	const content = () => {
		if (view === DriveUploadView.Options) {
			return (
				<Box>
					<List>
						{!stagedCameraFiles.length && (
							<ListItem>
								<ListItemButton onClick={() => setView(DriveUploadView.Select)}>
									<ListItemIcon>
										<img src={googleDriveLogo} width={20} />
									</ListItemIcon>
									<ListItemText>Select from Google Drive</ListItemText>
								</ListItemButton>
							</ListItem>
						)}
						{!stagedCameraFiles.length && (
							<ListItem>
								<ListItemButton onClick={() => setView(DriveUploadView.Upload)}>
									<ListItemIcon>
										<img src={googleDriveLogo} width={20} />
									</ListItemIcon>
									<ListItemText>Upload to Google Drive</ListItemText>
								</ListItemButton>
							</ListItem>
						)}
						<ListItem>
							<label htmlFor="photo-input" style={{ width: '100%' }}>
								<ListItemButton>
									<ListItemIcon>
										<CameraIcon />
									</ListItemIcon>
									<ListItemText primary="Take a photo" />
									<input
										id="photo-input"
										type="file"
										accept="image/*"
										capture="environment"
										onChange={handleCapture}
										style={{ display: 'none' }} // Hide the actual input
									/>
								</ListItemButton>
							</label>
						</ListItem>
						<ListItem>
							<label htmlFor="video-input" style={{ width: '100%' }}>
								<ListItemButton>
									<ListItemIcon>
										<VideoCameraIcon />
									</ListItemIcon>
									<ListItemText primary="Take a video" />
									<input
										id="video-input"
										type="file"
										accept="video/*"
										capture="environment"
										onChange={handleCapture}
										style={{ display: 'none' }} // Hide the actual input
									/>
								</ListItemButton>
							</label>
						</ListItem>
					</List>
					<Box display="flex" flexWrap="wrap">
						{stagedCameraFiles.map(({ file }, index) => (
							<Box key={`${file.name}-${index}`} position="relative" m={1}>
								<img src={URL.createObjectURL(file)} alt="staged" width="75px" />
								<Box
									position="absolute"
									right={2}
									top={2}
									bgcolor={colors.neutral['30']}
									width={30}
									height={30}
									p={0.5}
									display="flex"
									justifyContent="center"
									alignItems="center"
									borderRadius={30}
									onClick={() => handleDeleteStagedCameraFile(index)}
								>
									<CloseIcon />
								</Box>
							</Box>
						))}
					</Box>
					{!!stagedCameraFiles.length && (
						<Box sx={{ mx: 2 }}>
							<Button fullWidth onClick={handleSubmitCameraFiles}>
								Submit
							</Button>
						</Box>
					)}
				</Box>
			)
		}

		if (view === DriveUploadView.Upload) {
			return (
				<Box>
					<UploadPanel onFilesUploaded={onFilesUploaded} fileUploadProps={fileUploadProps} />
					<UploadConfirmDialog
						showConfirmClose={showConfirmClose}
						setShowConfirmClose={() => setShowConfirmClose(false)}
						onConfirmClose={onConfirmClose}
					/>
				</Box>
			)
		}

		if (view === DriveUploadView.Select) {
			return (
				<FilesPanel
					files={allFiles}
					onSubmit={handleSubmit}
					parentFolderName={parentFolderName}
					selectedFiles={selectedFiles}
					setSelectedFiles={setSelectedFiles}
				/>
			)
		}

		return null
	}

	return (
		<Modal open={isOpen} onClose={handleCloseModal}>
			<Box width={700} minHeight={400} maxHeight={600} maxWidth="100%" bgcolor="white">
				{content()}
			</Box>
		</Modal>
	)
}
