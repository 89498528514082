import { TaskTypes, StepTypes } from '@cango-app/sdk/types'

import { ListedStep } from 'src/modules/chains/types'

const isParentComplete = ({
	_task,
	step,
	completingParentStep,
	sectionSteps,
	selectedTask,
	projectTasks,
}: {
	_task: Pick<TaskTypes.PopulatedTask, '_id' | 'lifecycle' | 'isMultiUse'>
	step: string
	completingParentStep: TaskTypes.PopulatedStep.ProjectStep | undefined | null
	sectionSteps: Map<string, string>
	selectedTask: TaskTypes.PopulatedTask
	projectTasks: TaskTypes.PopulatedTask[]
}) => {
	if (selectedTask._id === _task._id && _task.isMultiUse) return true
	if (!completingParentStep) return true
	if (!_task.lifecycle.complete) {
		return false
	}
	const associatedParentId = sectionSteps.get(step) || step

	const completedOptionSteps = completingParentStep.descendants.reduce((acc: string[], child) => {
		if (!child.step) {
			return acc
		}

		if (child.step?._id !== associatedParentId || !child.option_condition) {
			return [...acc, child.step._id]
		}
		let completedOptions = _task.lifecycle.completed_options || []
		if (child.option_condition.from && child.option_condition.from !== completingParentStep._id) {
			const parentTask = projectTasks.find(
				(_task) => _task.step?._id && _task.step._id === child.option_condition?.from,
			)
			if (parentTask) {
				completedOptions = parentTask.lifecycle.completed_options || []
			}
		}

		if (!completedOptions.length) {
			return acc
		}

		if (
			child.option_condition.operator === StepTypes.ChildConditionOperator.Is &&
			!completedOptions.some((option) => child.option_condition?.values.includes(option._id))
		) {
			return acc
		}

		if (
			child.option_condition.operator === StepTypes.ChildConditionOperator.IsNot &&
			completedOptions.some((option) => child.option_condition?.values.includes(option._id))
		) {
			return acc
		}

		return [...acc, child.step._id]
	}, [])

	const completingStepRequiresOptionsSelected = completingParentStep.descendants.some((_child) => {
		if (_child.step?._id !== associatedParentId) {
			return false
		}
		if (_child.option_condition) {
			return true
		}
	})

	return !completingStepRequiresOptionsSelected || completedOptionSteps.includes(associatedParentId)
}

export const isStepReady = ({
	step,
	sectionSteps,
	blueprintSteps,
	projectTasks,
	activeTask,
	completed_options,
	chainTasks,
}: {
	step: { _id: string; requiresEveryParent: boolean }
	sectionSteps: Map<string, string>
	blueprintSteps: ListedStep[]
	projectTasks: TaskTypes.PopulatedTask[]
	activeTask: TaskTypes.PopulatedTask
	completed_options: { _id: string; label: string }[]
	chainTasks: TaskTypes.PopulatedTask[]
}) => {
	const associatedParentId = sectionSteps.get(step._id) || step._id
	const associatedParentSteps = blueprintSteps.filter((_step) => {
		return _step.descendants.some((_parentDesc) => {
			const _parentDescStep = blueprintSteps.find(({ _id }) => _id === _parentDesc.step)
			return _parentDescStep?._id === associatedParentId
		})
	})

	const parentTasks = projectTasks.filter((_task) => {
		if (!_task.step) return false
		return associatedParentSteps.some(({ _id }) => _task.step?._id === _id)
	})

	const allStepsHaveBeenCreatedAsTasks = associatedParentSteps.every((_step) =>
		parentTasks.some((_pTask) => _pTask.step?._id === _step._id),
	)

	const allParentsHaveBeenCreatedAsTasks =
		allStepsHaveBeenCreatedAsTasks &&
		associatedParentSteps.every((_step) => {
			const tasks = projectTasks.filter((_task) => _task.step?._id === _step._id)

			const originalStepOfChain = blueprintSteps.find((_bpStep) =>
				blueprintSteps.some((_bBpStep) =>
					_bBpStep.descendants.some(({ step: subStepId, _id }) => {
						const subStep = blueprintSteps.find(({ _id }) => _id === subStepId)
						return (
							tasks.some(({ chain }) => chain?.original_descendant_id === _id) &&
							subStep?._id === _bpStep._id
						)
					}),
				),
			)

			const tasksOfOriginalStep = projectTasks.filter(
				({ step }) => step?._id === originalStepOfChain?._id,
			)

			return tasksOfOriginalStep.every(({ chain }) => {
				return tasks.some(({ chain: _pChain }) => chain?._id === _pChain?._id)
			})
		})

	if (
		step.requiresEveryParent &&
		(!allStepsHaveBeenCreatedAsTasks ||
			!allParentsHaveBeenCreatedAsTasks ||
			parentTasks.length < associatedParentSteps.length)
	) {
		return false
	}

	let requiredParentTasksComplete: boolean

	if (!step.requiresEveryParent) {
		requiredParentTasksComplete = parentTasks.some((_task) => {
			const associatedStep = associatedParentSteps.find(
				({ _id }) => _task.step && _task.step._id === _id,
			)

			const parentComplete = isParentComplete({
				_task: {
					..._task,
					...((_task._id === activeTask._id || associatedStep?.isSection) && {
						lifecycle: {
							complete: true,
							completed_options: completed_options || [],
							completed_meta: [],
						},
					}),
					_id: _task._id,
				},
				step: step._id,
				completingParentStep: activeTask.step,
				sectionSteps,
				selectedTask: activeTask,
				projectTasks: chainTasks,
			})

			return parentComplete
		})
	} else {
		requiredParentTasksComplete = parentTasks.every((_task) => {
			const associatedStep = associatedParentSteps.find(
				({ _id }) => _task.step && _task.step._id === _id,
			)

			return isParentComplete({
				_task: {
					..._task,
					...((_task._id === activeTask._id || associatedStep?.isSection) && {
						lifecycle: {
							complete: true,
							completed_options: completed_options || [],
							completed_meta: [],
						},
					}),
					_id: _task._id,
				},
				step: step._id,
				completingParentStep: activeTask.step,
				sectionSteps,
				selectedTask: activeTask,
				projectTasks: chainTasks,
			})
		})
	}

	return requiredParentTasksComplete
}

export const getRequiredTables = (task: TaskTypes.PopulatedTask) => {
	const tables: string[] = []
	task.actions.forEach((_action) => {
		if (_action.table_view?.table) {
			tables.push(_action.table_view.table)
		}
	})

	task.step?.descendants.forEach((descendant) => {
		if (descendant.database_chain_logic?.table) {
			tables.push(descendant.database_chain_logic.table)
		}
	})

	if (task.step?.complete_options?.table_id) {
		tables.push(task.step.complete_options.table_id)
	}

	return tables
}
