import { TableTypes } from '@cango-app/sdk/types'

export const getDefaultValues = ({
	tableViews,
	viewId,
}: {
	tableViews: TableTypes.TableView[]
	viewId: string | undefined
}): Omit<TableTypes.TableView, '_id'> => {
	const defaultVal: Omit<TableTypes.TableView, '_id'> = {
		displayChildren: true,
		filters: {
			items: [],
		},
		name: '',
		withVerticalTableBorder: true,
		withHorizontalTableBorder: true,
		groupedFields: [],
		actions: [],
		column_order: [],
		column_widths: {},
	}
	if (!tableViews.length || !viewId) {
		return defaultVal
	}

	const foundView = tableViews.find((_view) => _view._id === viewId)

	if (!foundView) {
		return defaultVal
	}

	return {
		name: foundView.name,
		displayChildren: foundView.displayChildren ?? true,
		filters: foundView.filters ?? {
			items: [],
		},
		withVerticalTableBorder: foundView?.withVerticalTableBorder ?? true,
		withHorizontalTableBorder: foundView?.withHorizontalTableBorder ?? true,
		groupedFields: foundView?.groupedFields ?? [],
		actions: foundView?.actions ?? [],
		column_order: foundView?.column_order ?? [],
		column_widths: foundView?.column_widths ?? {},
	}
}
