import { TableTypes } from '@cango-app/sdk/types'

import { Question } from 'src/components/question-flow/types'

import { convertRowValueToArray } from '../utils'

export const convertRowValueToQuestionaireOption = (rowValue: any) => {
	const defaultValue: {
		answerType: TableTypes.AnswerType
		options: TableTypes.ListOption[]
	} = {
		answerType: TableTypes.AnswerType.SingleSelect,
		options: [],
	}
	if (!rowValue) {
		return defaultValue
	}

	if (!rowValue['answerType'] || !rowValue['options']) {
		return defaultValue
	}

	return {
		answerType: rowValue['answerType'],
		options: convertRowValueToArray(rowValue['options']),
	}
}

/**
 * Sorts nodes dynamically based on an array of column IDs.
 * @param nodes - Array of nodes to sort.
 * @param sortBy - Array of column IDs to sort by, in hierarchical order.
 * @returns A sorted array of nodes.
 */
export function sortNodesByFields(nodes: Question[], sortBy: string[]): Question[] {
	return [...nodes].sort((a, b) => {
		for (const field of sortBy) {
			const aValue = a.hierarchy[field] || '' // Handle missing values
			const bValue = b.hierarchy[field] || ''
			if (aValue < bValue) return -1
			if (aValue > bValue) return 1
		}
		return 0 // Equal for all fields in sortBy
	})
}
