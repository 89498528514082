import { TableTypes } from '@cango-app/sdk/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { getLinkedTable } from 'src/modules/tables/utils'

import * as thunks from './thunks'
import { MenuTableWithLinkedTables, TablesState } from './types'

const initialState: TablesState = {
	isFetchingTableList: false,
	tableList: [],
	tables: {},
	fetchingTables: {},
}

export const tablesSlice = createSlice({
	name: 'tables',
	initialState,
	reducers: {
		endSession: () => initialState,
		discardAllChanges: (state, action: PayloadAction<{ tableId: string; projectId?: string }>) => {
			const tableState = state.tables[action.payload.tableId]
			if (!tableState) {
				return
			}

			const rowCache = { ...tableState.previousRowCache }
			const newRowIds = tableState.newRowIds

			const resetRows = tableState.rows.reduce((_allRows: TableTypes.TableRow[], _row) => {
				const previousRow = rowCache[_row._id]
				if (previousRow) {
					return [..._allRows, previousRow]
				}

				if (newRowIds.includes(_row._id)) {
					return _allRows
				}

				return [..._allRows, _row]
			}, [])

			const resetOverrides =
				tableState.project_overrides?.[action.payload.projectId ?? '']?.rows.reduce(
					(_allRows: TableTypes.TableRow[], _row) => {
						const previousRow = rowCache[_row._id]
						if (previousRow) {
							return [..._allRows, previousRow]
						}

						if (newRowIds.includes(_row._id)) {
							return _allRows
						}

						return [..._allRows, _row]
					},
					[],
				) ?? []

			state.tables[action.payload.tableId] = {
				...tableState,
				previousRowCache: {},
				rows: resetRows,
				...(tableState.project_overrides && action.payload.projectId
					? {
							project_overrides: {
								...tableState.project_overrides,
								[action.payload.projectId]: {
									rows: resetOverrides,
								},
							},
						}
					: {}),
			}
		},
		saveNewReferenceTables: (
			state,
			action: PayloadAction<{
				tableId: string
				referenceTables: Record<string, TableTypes.PopulatedCangoTable>
			}>,
		) => {
			const tableState = state.tables[action.payload.tableId]
			if (!tableState) {
				return
			}
			state.tables[action.payload.tableId]!.referenceTables = {
				...tableState.referenceTables,
				...action.payload.referenceTables,
			}
		},
		softUpdateRows: (
			state,
			action: PayloadAction<{
				tableId: string
				updates: {
					oldRow: TableTypes.TableRow
					newRow: TableTypes.TableRow
				}[]
				newRows?: TableTypes.TableRow[]
				discardPreviousChanges?: boolean
				projectId?: string
			}>,
		) => {
			let tableState = state.tables[action.payload.tableId]
			if (!tableState) {
				return
			}

			if (action.payload.discardPreviousChanges) {
				const previousRowCache = { ...tableState.previousRowCache }
				const previousNewRowIds = [...tableState.newRowIds]
				const resetRows = tableState.rows.reduce((_allRows: TableTypes.TableRow[], _row) => {
					const previousRow = previousRowCache[_row._id]
					if (previousRow) {
						return [..._allRows, previousRow]
					}

					if (previousNewRowIds.includes(_row._id)) {
						return _allRows
					}

					return [..._allRows, _row]
				}, [])

				const resetOverrides =
					tableState.project_overrides?.[action.payload.projectId ?? '']?.rows.reduce(
						(_allRows: TableTypes.TableRow[], _row) => {
							const previousRow = previousRowCache[_row._id]
							if (previousRow) {
								return [..._allRows, previousRow]
							}

							if (previousNewRowIds.includes(_row._id)) {
								return _allRows
							}

							return [..._allRows, _row]
						},
						[],
					) ?? []

				tableState = {
					...tableState,
					previousRowCache: {},
					rows: resetRows,
					...(tableState.project_overrides && action.payload.projectId
						? {
								project_overrides: {
									...tableState.project_overrides,
									[action.payload.projectId]: {
										rows: resetOverrides,
									},
								},
							}
						: {}),
				}
			}

			const rowCache = { ...tableState.previousRowCache }

			action.payload.updates.forEach(({ oldRow, newRow }) => {
				if (!rowCache[oldRow._id]) {
					rowCache[oldRow._id] = oldRow
				}
				const indexOfRow = tableState.rows.findIndex(({ _id }) => _id === newRow._id)
				if (indexOfRow >= 0) {
					tableState.rows[indexOfRow] = newRow
					return
				}
				if (!newRow.projectId) {
					return
				}
				const indexOfOverride =
					tableState.project_overrides?.[newRow.projectId]?.rows.findIndex(
						({ _id }) => _id === newRow._id,
					) ?? -1
				if (indexOfOverride >= 0) {
					tableState.project_overrides![newRow.projectId].rows[indexOfOverride] = newRow
				}
			})
			tableState.previousRowCache = rowCache

			const newRows = action.payload.newRows ?? []
			const newRowIds = [...tableState.newRowIds]

			newRows.forEach((_row) => {
				if (!newRowIds.includes(_row._id)) {
					newRowIds.push(_row._id)
				}
				const projectId = _row.projectId as string
				if (!projectId) {
					tableState.rows.push(_row)
					return
				}
				if (tableState.project_overrides && tableState.project_overrides?.[projectId]?.rows) {
					tableState.project_overrides![projectId].rows.push(_row)
				}
			})
			tableState.newRowIds = newRowIds

			state.tables[action.payload.tableId] = tableState
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(thunks.getTableList.pending, (state) => {
				state.isFetchingTableList = true
			})
			.addCase(thunks.getTableList.fulfilled, (state, action) => {
				state.tableList = action.payload
				state.isFetchingTableList = false
			})
			.addCase(thunks.getTableList.rejected, (state) => {
				state.isFetchingTableList = false
			})
		builder
			.addCase(thunks.saveChanges.pending, (state, action) => {
				if (!action.meta.arg || !state.tables[action.meta.arg]) {
					return
				}
				state.tables[action.meta.arg]!.isUpdating = true
			})
			.addCase(thunks.saveChanges.fulfilled, (state, action) => {
				if (!action.meta.arg || !state.tables[action.meta.arg]) {
					return
				}
				state.tables[action.meta.arg] = {
					...state.tables[action.meta.arg]!,
					isUpdating: false,
					previousRowCache: {},
					newRowIds: [],
				}
			})
			.addCase(thunks.saveChanges.rejected, (state, action) => {
				if (!action.meta.arg || !state.tables[action.meta.arg]) {
					return
				}
				state.tables[action.meta.arg]!.isUpdating = false
			})
		builder
			.addCase(thunks.saveAllProjectChanges.pending, (state) => {
				Object.keys(state.tables).forEach((tableId) => {
					state.tables[tableId].isUpdating = true
				})
			})
			.addCase(thunks.saveAllProjectChanges.fulfilled, (state) => {
				Object.keys(state.tables).forEach((tableId) => {
					state.tables[tableId].isUpdating = false
					state.tables[tableId].previousRowCache = {}
					state.tables[tableId].newRowIds = []
				})
			})
			.addCase(thunks.saveAllProjectChanges.rejected, (state) => {
				Object.keys(state.tables).forEach((tableId) => {
					state.tables[tableId].isUpdating = false
				})
			})
		builder.addCase(thunks.updateRecords.fulfilled, (state, action) => {
			const tableId = action.meta.arg.tableId
			action.payload.updatedRows.forEach((updatedRow) => {
				const indexOfRow = state.tables[tableId].rows.findIndex(({ _id }) => _id === updatedRow._id)
				if (indexOfRow >= 0) {
					state.tables[tableId].rows[indexOfRow] = updatedRow
					return
				}
				if (!updatedRow.projectId) {
					return
				}
				const indexOfOverride =
					state.tables[tableId].project_overrides?.[updatedRow.projectId].rows.findIndex(
						({ _id }) => _id === updatedRow._id,
					) ?? -1
				if (indexOfOverride >= 0) {
					state.tables[tableId].project_overrides![updatedRow.projectId].rows[indexOfOverride] =
						updatedRow
				}
			})
			state.tables[tableId].rows.push(
				...action.payload.newRows.filter(({ projectId }) => !projectId),
			)
		})
		builder
			.addCase(thunks.hardUpdateRecords.pending, (state, action) => {
				const tableId = action.meta.arg.tableId
				const rows = action.meta.arg.rows
				const tableState = state.tables[tableId]
				if (!tableState) {
					return
				}
				state.tables[tableId]!.isUpdating = true
				const previousRows = [...tableState.rows]

				const updatedRows = previousRows.map((_record) => {
					const updatedRecord = rows.find(({ _id }) => _record._id === _id)
					if (updatedRecord) {
						return { ..._record, ...updatedRecord }
					}
					return _record
				})
				state.tables[tableId]!.rows = updatedRows
			})
			.addCase(thunks.hardUpdateRecords.fulfilled, (state, action) => {
				state.tables[action.meta.arg.tableId].isUpdating = false
			})
			.addCase(thunks.hardUpdateRecords.rejected, (state, action) => {
				state.tables[action.meta.arg.tableId].isUpdating = false
				if (!action.payload) {
					return
				}
				state.tables[action.meta.arg.tableId].rows = action.payload
			})
		builder
			.addCase(thunks.updateTableListItem.pending, (state, action) => {
				state.isFetchingTableList = true
				const tableId = action.meta.arg.tableId
				const data = action.meta.arg.data
				state.tableList = action.meta.arg.previousList.reduce(
					(_acc: MenuTableWithLinkedTables[], _table) => {
						if (data._action === 'delete' && _table._id === tableId) {
							return _acc
						}
						if (_table._id === tableId) {
							return [
								..._acc,
								{
									..._table,
									...data,
								},
							]
						}
						return [..._acc, _table]
					},
					[],
				)
			})
			.addCase(thunks.updateTableListItem.fulfilled, (state) => {
				state.isFetchingTableList = false
			})
			.addCase(thunks.updateTableListItem.rejected, (state, action) => {
				state.isFetchingTableList = false
				if (!action.payload) {
					return
				}
				state.tableList = action.payload
			})
		builder
			.addCase(thunks.deleteRecords.pending, (state, action) => {
				const tableId = action.meta.arg.tableId
				const selectedRows = action.meta.arg.selectedRows
				const tableState = state.tables[tableId]
				if (!tableState) {
					return
				}
				const updatedRows = tableState.rows.reduce((_acc: TableTypes.TableRow[], _record) => {
					if (selectedRows.has(_record._id)) {
						return _acc
					}
					if (_record.descendants?.some(({ row }) => row && selectedRows.has(row))) {
						return [
							..._acc,
							{
								..._record,
								descendants: _record.descendants.filter(({ row }) => row && !selectedRows.has(row)),
							},
						]
					}
					return [..._acc, _record]
				}, [])
				state.tables[tableId] = {
					...tableState,
					rows: updatedRows,
					isUpdating: true,
				}
			})
			.addCase(thunks.deleteRecords.fulfilled, (state, action) => {
				const tableId = action.meta.arg.tableId
				if (!state.tables[tableId]) {
					return
				}
				state.tables[tableId].isUpdating = false
			})
			.addCase(thunks.deleteRecords.rejected, (state, action) => {
				const tableId = action.meta.arg.tableId
				const previousRows = action.meta.arg.previousRows
				const tableState = state.tables[tableId]
				if (!tableState) {
					return
				}
				state.tables[tableId] = {
					...tableState,
					rows: previousRows,
					isUpdating: false,
				}
			})
		builder
			.addCase(thunks.updateTableConfig.pending, (state, action) => {
				const { tableId, data } = action.meta.arg
				const tableState = state.tables[tableId]
				if (!tableState?.config) {
					return
				}
				const tableListIndex = state.tableList.findIndex(({ _id }) => _id === tableId)
				const copiedTableList = [...state.tableList]
				copiedTableList[tableListIndex] = {
					...copiedTableList[tableListIndex],
					...data,
				}
				const newTableList = copiedTableList.map((_table) => {
					return {
						..._table,
						linkedTable: getLinkedTable(_table, copiedTableList),
					}
				})

				state.tables[tableId] = {
					...tableState,
					isUpdating: true,
					config: {
						...tableState.config,
						...data,
					},
				}
				state.tableList = newTableList
			})
			.addCase(thunks.updateTableConfig.fulfilled, (state, action) => {
				const { tableId, save } = action.meta.arg
				if (!state.tables[tableId]) {
					return
				}

				state.tables[tableId].isUpdating = false

				if (!save) {
					return
				}

				const updatedRows = action.payload

				if (updatedRows) {
					state.tables[tableId].rows = state.tables[tableId].rows.map((_record) => {
						const updatedRecord = updatedRows.find(({ _id }) => _record._id === _id)
						if (updatedRecord) {
							return { ..._record, ...updatedRecord }
						}
						return _record
					})
				}
			})
			.addCase(thunks.updateTableConfig.rejected, (state, action) => {
				const { tableId, previousConfig } = action.meta.arg
				const tableState = state.tables[tableId]
				if (!tableState?.config) {
					return
				}
				state.tables[tableId] = {
					...tableState,
					isUpdating: false,
					config: previousConfig,
				}
			})
		builder
			.addCase(thunks.addRow.pending, (state, action) => {
				const { tableId, data } = action.meta.arg
				const tableState = state.tables[tableId]
				if (!tableState.config) {
					return
				}
				state.tables[tableId].isUpdating = true
				if (data.newRow.projectId) {
					state.tables[tableId].project_overrides = {
						...(state.tables[tableId].project_overrides ?? {}),
						[data.newRow.projectId]: {
							rows: [
								...(state.tables[tableId].project_overrides?.[data.newRow.projectId]?.rows ?? []),
								data.newRow,
							],
						},
					}
				} else {
					state.tables[tableId].rows.push(data.newRow)
				}
				state.tables[tableId].config.row_order = data.rowOrder
			})
			.addCase(thunks.addRow.fulfilled, (state, action) => {
				const {
					tableId,
					data: { parentId },
				} = action.meta.arg
				const { newDescendant } = action.payload
				state.tables[tableId].isUpdating = false
				state.tables[tableId].rows = state.tables[tableId].rows.map((_row) => {
					if (_row._id === parentId && newDescendant) {
						return {
							..._row,
							descendants: [...(_row.descendants ?? []), newDescendant],
						}
					}
					return _row
				})
			})
			.addCase(thunks.addRow.rejected, (state, action) => {
				const { tableId, data } = action.meta.arg
				const tableState = state.tables[tableId]
				if (!tableState.config) {
					return
				}
				state.tables[tableId].isUpdating = false
				state.tables[tableId].rows = state.tables[tableId].rows.filter(
					(_row) => _row._id !== data.newRow._id,
				)
				state.tables[tableId].config = {
					...tableState.config,
					row_order: tableState.config.row_order.filter((_id) => _id !== data.newRow._id),
				}
			})
		builder
			.addCase(thunks.addColumn.pending, (state, action) => {
				const { tableId } = action.meta.arg
				const tableState = state.tables[tableId]
				if (!tableState.config) {
					return
				}
				state.tables[tableId].isUpdating = true
			})
			.addCase(thunks.addColumn.fulfilled, (state, action) => {
				const { tableId } = action.meta.arg
				const tableState = state.tables[tableId]
				if (!tableState.config) {
					return
				}
				state.tables[tableId].isUpdating = false
				state.tables[tableId].fields.push(...action.payload)
				state.tables[tableId].config = {
					...tableState.config,
					column_order: [
						...tableState.config.column_order,
						...action.payload.map(({ _id }) => _id),
					],
				}
			})
			.addCase(thunks.addColumn.rejected, (state, action) => {
				const { tableId } = action.meta.arg
				const tableState = state.tables[tableId]
				if (!tableState) {
					return
				}
				state.tables[tableId].isUpdating = false
			})
		builder
			.addCase(thunks.deleteColumn.pending, (state, action) => {
				const { tableId } = action.meta.arg
				const tableState = state.tables[tableId]
				if (!tableState.config) {
					return
				}
				state.tables[tableId].isUpdating = true
				state.tables[tableId].fields = state.tables[tableId].fields.filter(
					(_field) => _field._id !== action.meta.arg.column._id,
				)
				state.tables[tableId].config.column_order = state.tables[
					tableId
				].config.column_order.filter((_id) => _id !== action.meta.arg.column._id)
			})
			.addCase(thunks.deleteColumn.fulfilled, (state, action) => {
				const { tableId } = action.meta.arg
				const tableState = state.tables[tableId]
				if (!tableState.config) {
					return
				}
				state.tables[tableId].isUpdating = false
			})
			.addCase(thunks.deleteColumn.rejected, (state, action) => {
				const { tableId, indexOfColumn, column } = action.meta.arg
				const tableState = state.tables[tableId]
				if (!tableState.config) {
					return
				}
				state.tables[tableId].isUpdating = false
				state.tables[tableId].fields.splice(indexOfColumn, 0, column)
				state.tables[tableId].config.column_order.splice(indexOfColumn, 0, column._id)
			})
		builder
			.addCase(thunks.updateColumn.pending, (state, action) => {
				const { tableId, updates, fieldId } = action.meta.arg
				if (fieldId.includes('_answer')) {
					return
				}
				const tableState = state.tables[tableId]
				if (!tableState.config) {
					return
				}
				state.tables[tableId].isUpdating = true
				const indexOfColumn = tableState.fields.findIndex((_field) => _field._id === fieldId)
				state.tables[tableId].fields[indexOfColumn] = {
					...tableState.fields[indexOfColumn],
					...updates,
				}
			})
			.addCase(thunks.updateColumn.fulfilled, (state, action) => {
				const { tableId, fieldId } = action.meta.arg
				const { field, rows } = action.payload
				if (fieldId.includes('_answer')) {
					return
				}
				const tableState = state.tables[tableId]
				if (!tableState.config) {
					return
				}
				state.tables[tableId].isUpdating = false
				if (field) {
					const indexOfColumn = tableState.fields.findIndex((_field) => _field._id === field._id)
					state.tables[tableId].fields[indexOfColumn] = field
				}
				if (rows) {
					state.tables[tableId].rows = rows
				}
			})
			.addCase(thunks.updateColumn.rejected, (state, action) => {
				const { tableId, fieldId, previousColumn } = action.meta.arg
				const tableState = state.tables[tableId]
				if (!tableState.config) {
					return
				}
				state.tables[tableId].isUpdating = false
				const indexOfColumn = tableState.fields.findIndex((_field) => _field._id === fieldId)
				state.tables[tableId].fields[indexOfColumn] = previousColumn
			})
		builder
			.addCase(thunks.fetchTable.pending, (state, action) => {
				const { newTableId, clearCache } = action.meta.arg
				delete state.tables[newTableId]
				state.fetchingTables[newTableId] = {
					isFetching: true,
					numberOfFetchTableRetries:
						state.fetchingTables[newTableId]?.numberOfFetchTableRetries ?? 0,
					hasError: false,
				}
				if (clearCache) {
					state.tables = {}
				}
			})
			.addCase(thunks.fetchTable.fulfilled, (state, action) => {
				const { rows, fields, config, projectId } = action.payload
				state.tables[action.meta.arg.newTableId] = {
					isUpdating: false,
					config,
					rows: rows.filter((_row) => !_row.projectId),
					fields,
					previousRowCache: {},
					newRowIds: [],
					referenceTables: {},
					...(projectId
						? {
								project_overrides: {
									...(state.tables[action.meta.arg.newTableId]?.project_overrides ?? {}),
									[projectId]: {
										rows: rows.filter((_row) => _row.projectId),
									},
								},
							}
						: {}),
				}
				delete state.fetchingTables[action.meta.arg.newTableId]
			})
			.addCase(thunks.fetchTable.rejected, (state, action) => {
				state.fetchingTables[action.meta.arg.newTableId] = {
					isFetching: false,
					numberOfFetchTableRetries:
						(state.fetchingTables[action.meta.arg.newTableId]?.numberOfFetchTableRetries ?? 0) + 1,
					hasError: true,
				}
			})
	},
})
