import Stack from '@mui/material/Stack'
import { v4 } from 'uuid'
import { TableTypes } from '@cango-app/sdk/types'
import { ComponentType } from 'react'

import { Box, Chip, Text } from 'src/components'

import { arithmeticOperators } from './utils'

type MathematicalOperatorsProps = {
	onOperatorClick: (data: TableTypes.FormulaSlice) => void
}

export const MathematicalOperators: ComponentType<MathematicalOperatorsProps> = ({
	onOperatorClick,
}) => {
	const handleOperatorClick = (_id: string) => {
		onOperatorClick({
			id: v4(),
			type: TableTypes.FormulaSliceType.OPERATOR,
			value: _id,
		})
	}

	return (
		<Stack direction="column" spacing={1}>
			<Text variant="overline">Mathematical operators</Text>
			<Stack
				direction="row"
				sx={{
					overflowX: 'auto',
				}}
			>
				{arithmeticOperators.map(({ _id, label }) => (
					<Box key={_id} marginRight={1}>
						<Chip
							color="primary"
							variant="outlined"
							label={label}
							onClick={() => handleOperatorClick(_id)}
						/>
					</Box>
				))}
			</Stack>
		</Stack>
	)
}
