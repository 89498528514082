import { TableTypes } from '@cango-app/sdk/types'
import { GridRowId, GridValidRowModel } from '@mui/x-data-grid-premium'
import { Dispatch, createAsyncThunk } from '@reduxjs/toolkit'
import { TablesSdk } from '@cango-app/sdk/api'
import { AxiosError } from 'axios'
import _isEmpty from 'lodash/isEmpty'
import _toString from 'lodash/toString'
import { v4 } from 'uuid'

import { errorHandler } from 'src/helpers/api'
import { showSnackbar } from 'src/helpers/snackbarManager'
import { getLinkedTable } from 'src/modules/tables/utils'
import { snackbarActions } from 'src/providers/snackbar-actions'
import {
	selectors as projectSelectors,
	actions as projectActions,
} from 'src/store/modules/projects-v3'

import { AsyncDispatchType, RootState } from '../../types'
import { selectors as authSelectors } from '../auth'

import { selectTableState, selectResolvedTableRows, selectAllTableIds } from './selectors'
import {
	MenuTableWithLinkedTables,
	TableConfig,
	TableState,
	UpdateRecordsRequestParams,
} from './types'

export const getTableList = createAsyncThunk<
	MenuTableWithLinkedTables[],
	void,
	{ rejectValue: string; dispatch: Dispatch; state: RootState }
>('tables/get-table-list', async (_, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState()
		const headers = authSelectors.getAuthHeaders(state)
		const response = await TablesSdk.getTables(import.meta.env.VITE_API as string, headers)
		return response.map((_table) => ({
			..._table,
			linkedTable: getLinkedTable(_table, response),
		}))
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue((error as Error).message)
	}
})

export const saveChanges = createAsyncThunk<
	void,
	string,
	{
		state: RootState
		rejectValue: string
		dispatch: Dispatch
	}
>('tables/save-changes', async (tableId, { getState, rejectWithValue, dispatch }) => {
	try {
		const reduxState = getState()
		const authHeaders = authSelectors.getAuthHeaders(reduxState)
		const selectedProjectId = projectSelectors.getSelectedProjectId(reduxState)
		const tableState = selectTableState(reduxState, tableId)
		const rows = selectResolvedTableRows(reduxState, tableId)

		if (!tableState?.config) {
			throw new Error('No config')
		}

		if (_isEmpty(tableState.previousRowCache)) {
			throw new Error('Missing updates')
		}

		const updatedRecords = Object.keys(tableState.previousRowCache).reduce(
			(_records: TableTypes.TableRow[], _id) => {
				const resolvedRow = rows.find((_record) => _record._id === _id)
				if (!resolvedRow) {
					return _records
				}

				if (selectedProjectId && !resolvedRow.projectId) {
					return [
						..._records,
						{
							...resolvedRow,
							_id: v4(),
							projectId: selectedProjectId,
							originalRowId: _id,
						},
					]
				}

				return [
					..._records,
					{
						...resolvedRow,
						calculations: resolvedRow.calculations ?? {},
					},
				]
			},
			[],
		)

		const newRecords = tableState.newRowIds.reduce((_newRows: TableTypes.TableRow[], _newRowId) => {
			const resolvedRow = rows.find((_record) => _record._id === _newRowId)
			if (!resolvedRow) {
				return _newRows
			}

			return [
				..._newRows,
				{
					...resolvedRow,
					calculations: resolvedRow.calculations ?? {},
				},
			]
		}, [])

		updatedRecords.push(...newRecords)

		if (updatedRecords.length) {
			await TablesSdk.updateRecords(import.meta.env.VITE_API as string, authHeaders, {
				rows: updatedRecords,
				projectId: selectedProjectId,
			})
		}
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue((error as Error).message)
	}
})

export const saveAllProjectChanges = createAsyncThunk<
	void,
	void,
	{
		state: RootState
		dispatch: Dispatch
	}
>('tables/save-all-project-changes', async (_, { getState, dispatch }) => {
	try {
		const reduxState = getState()
		const authHeaders = authSelectors.getAuthHeaders(reduxState)
		const selectedProjectId = projectSelectors.getSelectedProjectId(reduxState)
		const tables = selectAllTableIds(reduxState)

		const allUpdatedRecords: TableTypes.TableRow[] = []

		for (const tableId of tables) {
			const tableState = selectTableState(reduxState, tableId)
			const rows = selectResolvedTableRows(reduxState, tableId)

			if (!tableState?.config || _isEmpty(tableState.previousRowCache)) {
				continue
			}

			const updatedRecords = Object.keys(tableState.previousRowCache).reduce(
				(_records: TableTypes.TableRow[], _id) => {
					const resolvedRow = rows.find((_record) => _record._id === _id)
					if (!resolvedRow) {
						return _records
					}

					if (selectedProjectId && !resolvedRow.projectId) {
						return [
							..._records,
							{
								...resolvedRow,
								_id: v4(),
								projectId: selectedProjectId,
								originalRowId: _id,
							},
						]
					}

					return [
						..._records,
						{
							...resolvedRow,
							calculations: resolvedRow.calculations ?? {},
						},
					]
				},
				[],
			)
			allUpdatedRecords.push(...updatedRecords)

			const newRecords = tableState.newRowIds.reduce(
				(_newRows: TableTypes.TableRow[], _newRowId) => {
					const resolvedRow = rows.find((_record) => _record._id === _newRowId)
					if (!resolvedRow) {
						return _newRows
					}

					return [
						..._newRows,
						{
							...resolvedRow,
							calculations: resolvedRow.calculations ?? {},
						},
					]
				},
				[],
			)

			allUpdatedRecords.push(...newRecords)
		}

		if (allUpdatedRecords.length) {
			await TablesSdk.updateRecords(import.meta.env.VITE_API as string, authHeaders, {
				rows: allUpdatedRecords,
				projectId: selectedProjectId,
			})
		}
	} catch (error) {
		errorHandler({ error, dispatch })
		throw error
	}
})

export const updateRecords = createAsyncThunk<
	{
		updatedRows: TableTypes.TableRow[]
		newRows: TableTypes.TableRow[]
	},
	UpdateRecordsRequestParams & { tableId: string },
	{
		state: RootState
		rejectValue: TableTypes.TableRow[]
		dispatch: Dispatch
	}
>(
	'tables/update-records',
	async ({ tableId, rows: rowUpdates, newRows }, { getState, rejectWithValue, dispatch }) => {
		try {
			const state = getState()
			const authHeaders = authSelectors.getAuthHeaders(state)
			const projectId = projectSelectors.getSelectedProjectId(state)

			const tableRows = selectResolvedTableRows(state, tableId)

			const rowsToUpdate = [...rowUpdates.map((_update) => _update.newRow), ...(newRows ?? [])]

			const updatedRecords = rowsToUpdate.reduce((_records: TableTypes.TableRow[], row) => {
				const resolvedRow = tableRows.find((_record) => _record._id === row._id)
				if (!resolvedRow) {
					return _records
				}

				if (projectId && !resolvedRow.projectId) {
					return [
						..._records,
						{
							...row,
							_id: v4(),
							projectId,
							originalRowId: row._id,
						},
					]
				}

				return [..._records, row]
			}, [])

			if (updatedRecords.length) {
				await TablesSdk.updateRecords(import.meta.env.VITE_API as string, authHeaders, {
					rows: updatedRecords,
					projectId,
				})
			}

			return {
				updatedRows: rowUpdates.map((_update) => _update.newRow),
				newRows: newRows ?? [],
			}
		} catch (error) {
			errorHandler({ error, dispatch })
			return rejectWithValue(rowUpdates.map((_update) => _update.oldRow))
		}
	},
)

export const hardUpdateRecords = createAsyncThunk<
	void,
	{
		tableId: string
		rows: Omit<TableTypes.TableRow, 'references'>[]
		previousRows: TableTypes.TableRow[]
	},
	{ rejectValue: TableTypes.TableRow[]; dispatch: Dispatch; state: RootState }
>(
	'tables/hard-update-records',
	async ({ tableId, rows, previousRows }, { getState, rejectWithValue, dispatch }) => {
		const state = getState()
		const authHeaders = authSelectors.getAuthHeaders(state)
		const tableState = selectTableState(state, tableId)
		const selectedProjectId = projectSelectors.getSelectedProjectId(state)

		try {
			if (!tableState?.config) {
				throw new Error('No config')
			}

			await TablesSdk.updateRecords(import.meta.env.VITE_API as string, authHeaders, {
				rows,
				projectId: selectedProjectId,
			})
		} catch (error) {
			errorHandler({ error, dispatch })
			return rejectWithValue(previousRows)
		}
	},
)

export const updateTableListItem = createAsyncThunk<
	void,
	{
		tableId: string
		data: Partial<TablesSdk.MenuTable & { _action: 'delete' | 'new' }>
		previousList: MenuTableWithLinkedTables[]
	},
	{ rejectValue: MenuTableWithLinkedTables[]; dispatch: Dispatch; state: RootState }
>(
	'tables/update-table-list-item',
	async ({ tableId, data, previousList }, { getState, rejectWithValue, dispatch }) => {
		const state = getState()
		const authHeaders = authSelectors.getAuthHeaders(state)
		try {
			if (!data._action) {
				await TablesSdk.updateTableConfig(
					import.meta.env.VITE_API as string,
					authHeaders,
					tableId,
					data,
				)
			}
		} catch (error) {
			errorHandler({ error, dispatch })
			return rejectWithValue(previousList)
		}
	},
)

export const deleteRecords = createAsyncThunk<
	void,
	{
		tableId: string
		selectedRows: Map<GridRowId, GridValidRowModel>
		previousRows: TableTypes.TableRow[]
	},
	{ rejectValue: TableTypes.TableRow[]; dispatch: Dispatch; state: RootState }
>(
	'tables/delete-records',
	async ({ selectedRows, previousRows }, { getState, rejectWithValue, dispatch }) => {
		const state = getState()
		const authHeaders = authSelectors.getAuthHeaders(state)
		try {
			const selectedProjectId = projectSelectors.getSelectedProjectId(state)
			const recordUpdates = previousRows.reduce<TablesSdk.UpdateRecordsRequest['rows']>(
				(acc: Omit<TableTypes.TableRow, 'references'>[], record) => {
					if (record.descendants?.some(({ row }) => row && selectedRows.has(row))) {
						return [
							...acc,
							{
								...record,
								_id: record._id,
								descendants: record.descendants.filter(({ row }) => row && !selectedRows.has(row)),
							},
						]
					}

					if (!selectedRows.has(record._id)) {
						return acc
					}

					return [
						...acc,
						{
							...record,
							_id: record._id,
							calculations: {},
							data: {
								...record.data,
								_action: 'delete',
							},
						},
					]
				},
				[],
			)

			await TablesSdk.updateRecords(import.meta.env.VITE_API as string, authHeaders, {
				rows: recordUpdates,
				projectId: selectedProjectId,
			})
		} catch (error) {
			errorHandler({ error, dispatch })
			return rejectWithValue(previousRows)
		}
	},
)

export const updateTableConfig = createAsyncThunk<
	TableTypes.TableRow[] | undefined,
	{
		tableId: string
		data: TablesSdk.UpdateTableConfigRequest
		previousConfig: TableConfig
		save?: boolean
	},
	{ rejectValue: TableConfig; dispatch: Dispatch; state: RootState }
>(
	'tables/update-table-config',
	async ({ tableId, data, save, previousConfig }, { getState, rejectWithValue, dispatch }) => {
		const state = getState()
		const authHeaders = authSelectors.getAuthHeaders(state)
		const tableState = selectTableState(state, tableId)
		try {
			if (!tableState?.config?._id) {
				throw new Error('No table state')
			}

			if (save) {
				const response = await TablesSdk.updateTableConfig(
					import.meta.env.VITE_API as string,
					authHeaders,
					tableState.config._id,
					data,
				)

				return response.rows
			}
		} catch (error) {
			errorHandler({ error, dispatch })
			return rejectWithValue(previousConfig)
		}
	},
)

export const addRow = createAsyncThunk<
	{
		newDescendant: TableTypes.Descendant | undefined
	},
	{
		tableId: string
		data: {
			parentId?: string
			newRow: TableTypes.TableRow
			rowOrder: string[]
		}
	},
	{ rejectValue: string; dispatch: AsyncDispatchType; state: RootState }
>('tables/add-row', async ({ tableId, data }, { getState, rejectWithValue, dispatch }) => {
	const state = getState()
	const authHeaders = authSelectors.getAuthHeaders(state)
	const tableState = selectTableState(state, tableId)
	try {
		if (!tableState?.config?._id) {
			throw new Error('No table state')
		}
		const selectedProject = projectSelectors.getSelectedProject(state)
		const projectConfig = projectSelectors.getSelectedProjectTableConfig(
			state,
			tableState.config._id,
		)
		const { newDescendant } = await TablesSdk.addRow(
			import.meta.env.VITE_API as string,
			authHeaders,
			tableState.config._id,
			{
				parentId: data.parentId,
				row: data.newRow,
				row_order: data.rowOrder,
			},
		)

		if (data.newRow.projectId && selectedProject) {
			await dispatch(
				projectActions.updateProject({
					projectId: data.newRow.projectId,
					[`table_config.${tableState.config._id}`]: {
						...(projectConfig ?? {
							column_order: tableState.config.column_order,
							row_density: tableState.config.row_density,
						}),
						row_order: data.rowOrder,
					},
				}),
			)
		}

		return {
			newDescendant,
		}
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue((error as Error).message)
	}
})

export const addColumn = createAsyncThunk<
	TableTypes.Field[],
	{
		tableId: string
		numberOfColumns?: number
	},
	{ rejectValue: string; dispatch: Dispatch; state: RootState }
>(
	'tables/add-column',
	async ({ tableId, numberOfColumns = 1 }, { getState, rejectWithValue, dispatch }) => {
		const state = getState()
		const authHeaders = authSelectors.getAuthHeaders(state)
		const tableState = selectTableState(state, tableId)
		try {
			if (!tableState?.config?._id) {
				throw new Error('No table state')
			}
			const { columns } = await TablesSdk.addColumn(
				import.meta.env.VITE_API as string,
				authHeaders,
				tableState.config._id,
				{
					numberOfColumns: _toString(numberOfColumns),
				},
			)

			return columns
		} catch (error) {
			let errorMessage = 'Could not add column'
			if ((error as AxiosError<{ message?: string }>).response?.data?.message) {
				errorMessage = (error as AxiosError<{ message?: string }>).response?.data?.message as string
			}
			errorHandler({ error, dispatch, message: errorMessage })
			return rejectWithValue((error as Error).message)
		}
	},
)

export const deleteColumn = createAsyncThunk<
	void,
	{
		tableId: string
		column: TableTypes.Field
		indexOfColumn: number
	},
	{ rejectValue: string; dispatch: Dispatch; state: RootState }
>('tables/delete-column', async ({ tableId, column }, { getState, rejectWithValue, dispatch }) => {
	const state = getState()
	const authHeaders = authSelectors.getAuthHeaders(state)
	const tableState = selectTableState(state, tableId)
	try {
		if (!tableState?.config?._id) {
			throw new Error('No table state')
		}

		await TablesSdk.deleteField(import.meta.env.VITE_API as string, authHeaders, {
			tableId: tableState.config._id,
			fieldId: column._id,
		})
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue((error as Error).message)
	}
})

export const updateColumn = createAsyncThunk<
	{ result: 'success'; field?: TableTypes.Field; rows?: TableTypes.TableRow[] },
	{
		tableId: string
		fieldId: string
		updates: Omit<TablesSdk.UpdateFieldRequest, 'tableId'>
		otherFields?: Partial<Omit<TableState, 'fields' | 'records'>>
		previousColumn: TableTypes.Field
	},
	{
		rejectValue: { result: 'error'; field: TableTypes.Field }
		dispatch: Dispatch
		state: RootState
	}
>(
	'tables/update-column',
	async (
		{ tableId, fieldId, updates, previousColumn },
		{ getState, rejectWithValue, dispatch },
	) => {
		const state = getState()
		const authHeaders = authSelectors.getAuthHeaders(state)
		const tableState = selectTableState(state, tableId)
		try {
			if (!tableState?.config?._id) {
				throw new Error('No table state')
			}

			if (fieldId.includes('_answer')) {
				return { result: 'success' }
			}

			const response = await TablesSdk.updateField(
				import.meta.env.VITE_API as string,
				authHeaders,
				tableState.config._id,
				fieldId,
				updates,
			)

			return {
				result: 'success',
				field: response.field,
				rows: response.rows,
			}
		} catch (error) {
			errorHandler({ error, dispatch })
			return rejectWithValue({
				result: 'error',
				field: previousColumn,
			})
		}
	},
)

export const fetchTable = createAsyncThunk<
	{
		config: TableConfig
		rows: TableTypes.TableRow[]
		fields: TableTypes.Field[]
		projectId: string | undefined
	},
	{
		newTableId: string
		forceFetch?: boolean
		clearCache?: boolean
	},
	{ rejectValue: string; dispatch: AsyncDispatchType; state: RootState }
>(
	'tables/fetch-table',
	async ({ newTableId, forceFetch }, { getState, rejectWithValue, dispatch }) => {
		const state = getState()
		const authHeaders = authSelectors.getAuthHeaders(state)
		const selectedProjectId = projectSelectors.getSelectedProjectId(state)
		try {
			const {
				table: { fields, rows, ...config },
				circular_reference,
			} = await TablesSdk.getTable(import.meta.env.VITE_API as string, authHeaders, newTableId, {
				build_type: forceFetch ? 'build' : undefined,
				project_id: selectedProjectId,
			})

			if (circular_reference) {
				showSnackbar(`Circular reference detected\n${circular_reference}`, {
					variant: 'error',
					autoHideDuration: 10000,
					action: snackbarActions,
					style: { whiteSpace: 'pre-line' },
				})
			}

			return {
				config,
				rows,
				fields,
				projectId: selectedProjectId,
			}
		} catch (error) {
			if ((error as AxiosError).response?.status === 404) {
				showSnackbar('Table not found', { variant: 'error' })
				return rejectWithValue('Table not found')
			}
			errorHandler({ error, dispatch })
			return rejectWithValue((error as Error).message)
		}
	},
)
