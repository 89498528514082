import { ComponentType, useCallback, useContext, useEffect, useState } from 'react'
import { TableTypes, ProjectTypes } from '@cango-app/sdk/types'
import { useDispatch, useSelector } from 'react-redux'
import { v4 } from 'uuid'
import { GridFilterModel } from '@mui/x-data-grid-premium'

import { RootState } from 'src/store/types'
import { selectors as tableSelectors, actions as tableActions } from 'src/store/modules/tables'
import {
	selectors as projectSelectors,
	actions as projectActions,
} from 'src/store/modules/projects-v3'
import { TableContext } from 'src/providers'
import { applyFilterItem } from 'src/modules/tables/utils'

import { Box } from '../box'

import { QuestionFlowContainer } from './question-flow-container'
import { Question } from './types'

type QuestionFlowLogicContainerProps = {
	filters: GridFilterModel
	acceptedQuestionColumns: string[]
	questionScope: string[]
}

const getMatchingCondition = (
	question: Question,
	answer: ProjectTypes.QuestionaireResponseItem[],
) => {
	const childrenWithConditions = question.descendants.filter(
		(_descendant) => _descendant.option_condition?.values.length,
	)

	let successfulCondition = childrenWithConditions.find((_desc) => {
		const optionCondition = _desc.option_condition
		if (!optionCondition) {
			return
		}

		return optionCondition.values.some((_val) => {
			return answer.some((_ans) =>
				applyFilterItem('string', optionCondition.operator, _ans.option_id ?? _ans.text, _val),
			)
		})
	})

	if (!successfulCondition) {
		successfulCondition = question.descendants.find(
			(_descendant) => !_descendant.option_condition?.values.length,
		)
	}

	return successfulCondition
}

export const QuestionFlowLogicContainer: ComponentType<QuestionFlowLogicContainerProps> = ({
	filters,
	acceptedQuestionColumns,
	questionScope,
}) => {
	const dispatch = useDispatch()
	const { tableConfig, isLoadingTable } = useContext(TableContext)
	const answers = useSelector(projectSelectors.getQuestionnaireAnswers)
	const questions = useSelector((state: RootState) =>
		tableSelectors.selectQuestions(
			state,
			tableConfig?._id ?? '',
			filters,
			acceptedQuestionColumns,
			questionScope,
		),
	)
	const [activeQuestionIndex, setActiveQuestionIndex] = useState(0)
	const [stepHistory, setStepHistory] = useState<number[]>([])
	const questionnaireTable = useSelector((state: RootState) =>
		tableSelectors.selectTableState(state, tableConfig?.questionaire_reference_table ?? ''),
	)
	const isFetchingQuestionnaireTable = useSelector((state: RootState) =>
		tableSelectors.isFetchingTable(state, tableConfig?.questionaire_reference_table ?? ''),
	)

	const goToNextQuestion = (
		question: Question,
		answer: ProjectTypes.QuestionaireResponseItem[],
	) => {
		const successfulCondition = getMatchingCondition(question, answer)

		const updateActiveIndex = (index: number) => {
			setActiveQuestionIndex(index)
			setStepHistory((prev) => [...prev, activeQuestionIndex])
		}

		if (successfulCondition) {
			const nextQuestionIndex = questions.findIndex(
				(_ques) => _ques.questionRowId === successfulCondition.row,
			)

			if (nextQuestionIndex >= 0) {
				updateActiveIndex(nextQuestionIndex)
			} else {
				const nextQuestionOutsideScope = questions[activeQuestionIndex + 1]
				if (nextQuestionOutsideScope) {
					updateActiveIndex(activeQuestionIndex + 1)
				}
			}
		} else {
			const nextQuestionOutsideScope = questions[activeQuestionIndex + 1]
			if (nextQuestionOutsideScope) {
				updateActiveIndex(activeQuestionIndex + 1)
			}
		}
	}

	const handleAnswerClick = useCallback(
		(
			answer: ProjectTypes.QuestionaireResponseItem[],
			existingResponse: ProjectTypes.QuestionaireAnswer | undefined,
		) => {
			if (questions.length === 0 || !tableConfig) return
			const question = questions[activeQuestionIndex]
			const questionScope = Object.entries(question.hierarchy).map(([column_id, value]) => ({
				column_id,
				value,
			}))
			let newAnswers = [...answers]
			const existingAnswerIndex = newAnswers.findIndex((_ans) => _ans._id === existingResponse?._id)
			if (existingAnswerIndex >= 0) {
				newAnswers[existingAnswerIndex] = {
					...newAnswers[existingAnswerIndex],
					answer,
				}
			} else {
				newAnswers.push({
					_id: v4(),
					question_id: question.questionRowId,
					answer,
					scope: questionScope,
				})
			}

			const childrenRowIds = (
				questionId: string,
				visitedQuestions = new Set<string>(),
			): string[] => {
				if (visitedQuestions.has(questionId)) {
					return []
				}
				visitedQuestions.add(questionId)
				const parentQuestion = questions.find((_ques) => questionId === _ques.questionRowId)
				if (!parentQuestion) {
					return []
				}
				return parentQuestion.descendants.reduce((_childIds: string[], _desc) => {
					if (!_desc.row) {
						return _childIds
					}
					return [..._childIds, _desc.row, ...childrenRowIds(_desc.row, visitedQuestions)]
				}, [])
			}

			const matchingCondition = getMatchingCondition(question, answer)
			const allFailedConditions = question.descendants.filter(
				(_desc) => _desc._id !== matchingCondition?._id,
			)

			const childrenOfFailedConditions = allFailedConditions.reduce((_acc: string[], _desc) => {
				if (!_desc.row) {
					return _acc
				}
				return [..._acc, _desc.row, ...childrenRowIds(_desc.row)]
			}, [])

			newAnswers = newAnswers.filter((_ans) => {
				if (!childrenOfFailedConditions.includes(_ans.question_id)) {
					return true
				}
				if (_ans.scope.length < questionScope.length) {
					return true
				}

				return !_ans.scope.every(({ column_id, value }) => question.hierarchy[column_id] === value)
			})

			dispatch(projectActions.updateQuestionnaireAnswers(newAnswers))

			if (question.type === TableTypes.AnswerType.SingleSelect) {
				goToNextQuestion(question, answer)
			}
		},
		[questions, tableConfig, activeQuestionIndex, answers],
	)

	useEffect(() => {
		if (
			!isLoadingTable &&
			tableConfig?.questionaire_reference_table &&
			!questionnaireTable &&
			!isFetchingQuestionnaireTable
		) {
			dispatch(tableActions.fetchTable({ newTableId: tableConfig.questionaire_reference_table }))
		}
	}, [isLoadingTable, questionnaireTable, isFetchingQuestionnaireTable])

	if (isFetchingQuestionnaireTable || isLoadingTable || !questions.length) {
		return (
			<Box display="flex" flexDirection="row" alignItems="center">
				{isFetchingQuestionnaireTable
					? 'Building questionaire...'
					: isLoadingTable
						? 'Fetching table...'
						: 'No questions to display'}
			</Box>
		)
	}

	return (
		<QuestionFlowContainer
			questions={questions}
			onAnswerClick={handleAnswerClick}
			activeQuestionIndex={activeQuestionIndex}
			goBack={() => {
				const lastStep = stepHistory.pop()
				if (lastStep !== undefined) {
					setActiveQuestionIndex(lastStep)
				}
			}}
			goForward={goToNextQuestion}
		/>
	)
}
