import { TableTypes } from '@cango-app/sdk/types'
import { GridFilterModel } from '@mui/x-data-grid-premium'
import _uniq from 'lodash/uniq'
import _uniqBy from 'lodash/uniqBy'
import { createCachedSelector } from 're-reselect'
import { createSelector } from 'reselect'

import { getFieldType } from 'src/modules/tables/mui-formatter'
import { getLayout } from 'src/modules/tables/questionaire-logic/get-layout'
import { resolveAnyRowCalculations } from 'src/modules/tables/utils'
import { selectors as contactSelectors } from 'src/store/modules/contacts'
import { selectors as imageSelectors } from 'src/store/modules/images'
import { selectors as projectSelectors } from 'src/store/modules/projects-v3'
import { RoleWithUsage, selectors as roleSelectors } from 'src/store/modules/roles'
import { getQuestionaireQuestions, sortNodesWithDescendants } from 'src/store/modules/tables/utils'
import { CangoReduxModuleName, RootState } from 'src/store/types'

import { LinkedTable, ResolvedRowData, TableConfig, TablesState, TableState } from './types'

type TableSelector<T> = (state: RootState, tableId: string) => T

const selectTableProviderState: (state: RootState) => TablesState = createSelector(
	(state: RootState) => state[CangoReduxModuleName.CangoTables],
	(tables) => tables,
)

export const selectAllTableIds = createSelector(selectTableProviderState, ({ tables }) =>
	Object.keys(tables),
)

export const selectTableState: (state: RootState, tableId: string) => TableState | undefined =
	createCachedSelector(
		selectTableProviderState,
		(state: RootState, tableId: string) => tableId,
		(state, tableId) => state.tables[tableId],
	)((state, tableId) => tableId)

export const selectTableConfig: TableSelector<TableConfig | undefined> = createCachedSelector(
	selectTableState,
	(state) => state?.config,
)((state, tableId) => tableId)

const selectTableFields: TableSelector<TableTypes.Field[]> = createCachedSelector(
	selectTableState,
	(state) => state?.fields ?? [],
)((state, tableId) => tableId)

const selectOptionsColumnId: TableSelector<string> = createSelector(
	selectTableFields,
	(fields) => fields.find((field) => field.type === TableTypes.FieldType.OPTIONS)?._id ?? '',
)

const selectTableRoles: TableSelector<RoleWithUsage[]> = createCachedSelector(
	selectTableState,
	(state) => state,
	(table, state) => {
		const config = table?.config
		if (!config) {
			return []
		}
		return roleSelectors.getRolesForSelect(state, config.organisationId)
	},
)((state, tableId) => tableId)

export const selectPopulatedTableFields: TableSelector<TableTypes.Field[]> = createCachedSelector(
	selectTableFields,
	contactSelectors.getContactsForSelect,
	selectTableRoles,
	(fields, contactList, roleList) => {
		return fields.map((_field) => {
			if (_field.type === TableTypes.FieldType.ROLE) {
				return { ..._field, valueOptions: roleList }
			}
			if (_field.type === TableTypes.FieldType.CONTACT) {
				return { ..._field, valueOptions: contactList }
			}
			return _field
		})
	},
)((state, tableId) => tableId)

export const selectValueOptions = createSelector(selectPopulatedTableFields, (fields) => {
	return fields.reduce((acc: Record<string, Map<string, string>>, _field) => {
		if (!_field.valueOptions) {
			return acc
		}
		acc[_field._id] = new Map(_field.valueOptions.map(({ _id, label }) => [_id, label]))
		return acc
	}, {})
})

export const selectMappedColumns = createSelector(selectPopulatedTableFields, (fields) => {
	return new Map(fields.map((_field) => [_field._id, _field]))
})

export const selectColumnList = createSelector(selectPopulatedTableFields, (fields) => {
	return fields.map(({ _id, name }) => ({
		_id,
		label: name,
	}))
})

export const selectColumnFilterList = createSelector(selectPopulatedTableFields, (fields) => {
	return fields.map(({ _id, type, returnType, valueOptions }) => ({
		_id,
		type: getFieldType(type, returnType),
		valueOptions,
	}))
})

const selectProjectRowOverrides = createSelector(
	selectTableState,
	projectSelectors.getSelectedProjectId,
	(state, projectId) => {
		const overrides = state?.project_overrides
		if (!projectId || !overrides || !overrides[projectId]) {
			return []
		}
		return overrides[projectId].rows ?? []
	},
)

export const selectBlueprintTableRows: (
	state: RootState,
	tableId: string,
) => TableTypes.TableRow[] = createSelector(selectTableState, (state) => {
	return _uniqBy(state?.rows ?? [], '_id') ?? []
})

const selectTableRows: TableSelector<TableTypes.TableRow[]> = createSelector(
	selectBlueprintTableRows,
	selectProjectRowOverrides,
	(originalRows, rowOverrides) => {
		const newRows = rowOverrides.filter((_row) => !_row.originalRowId)
		const overrideMap: Map<string, TableTypes.TableRow> = new Map(
			rowOverrides.filter((_row) => _row.originalRowId).map((_row) => [_row.originalRowId!, _row]),
		)
		const updatedRows = originalRows.reduce((_acc: TableTypes.TableRow[], row) => {
			const override = overrideMap.get(row._id)
			if (override) {
				return [..._acc, override]
			}
			return [..._acc, row]
		}, [])
		return [...updatedRows, ...newRows]
	},
)

const selectSortedRecordIds: (state: RootState, tableId: string) => string[] = createSelector(
	selectTableConfig,
	projectSelectors.getSelectedProjectTableConfig,
	selectTableRows,
	selectOptionsColumnId,
	(config, projectConfig, uniqueRecords, optionsColumnId) => {
		if (!config) {
			return uniqueRecords.map((row) => row._id)
		}

		const rowOrder = projectConfig?.row_order || config.row_order

		if (config.type === TableTypes.TableType.Questionaire) {
			const questionColumnId = config.principal_field ?? ''
			const { nodes } = getLayout({ rows: uniqueRecords, optionsColumnId, questionColumnId })
			const nodeIds = nodes.map((node) => node.id)
			return uniqueRecords
				.slice()
				.sort((a, b) => nodeIds.indexOf(a._id) - nodeIds.indexOf(b._id))
				.map((row) => row._id)
		} else if (rowOrder) {
			return uniqueRecords
				.slice()
				.sort((a, b) => {
					const aIndex = rowOrder.indexOf(a._id)
					const bIndex = rowOrder.indexOf(b._id)

					if (aIndex === -1 && bIndex === -1) return 0
					if (aIndex === -1) return 1
					if (bIndex === -1) return -1
					return aIndex - bIndex
				})
				.map((row) => row._id)
		}

		return uniqueRecords.map((row) => row._id)
	},
)

const selectSortedRecords = createSelector(
	selectTableRows,
	selectSortedRecordIds,
	(uniqueRecords, sortedIds) => {
		const recordMap = uniqueRecords.reduce((acc: Record<string, TableTypes.TableRow>, row) => {
			acc[row._id] = row
			return acc
		}, {})
		return sortedIds.map((id) => recordMap[id]).filter((row) => row)
	},
)

const selectResolveRow: (
	state: RootState,
	tableId: string,
	row: TableTypes.TableRow,
) => ResolvedRowData = createCachedSelector(
	selectPopulatedTableFields,
	selectTableConfig,
	projectSelectors.getQuestionnaireAnswers,
	imageSelectors.getMappedImages,
	(state: RootState, tableId: string | undefined, row: TableTypes.TableRow) => {
		return row
	},
	(fields, config, answers, images, row) =>
		resolveAnyRowCalculations({
			row,
			fields,
			referenceColumns: config?.referenceColumnNames ?? {},
			questionaireAnswers: answers,
			images,
			vLookupTables: config?.vLookupTables ?? {},
		}),
)(
	(state: RootState, tableId: string | undefined, row: TableTypes.TableRow) => row._id, // Cache key by row ID
)

export const selectResolvedRowData: (state: RootState, tableId: string) => ResolvedRowData[] =
	createSelector(
		selectSortedRecords,
		(state: RootState) => state,
		(state: RootState, tableId: string) => tableId,
		(sortedRecords, state, tableId) => {
			return sortedRecords.map((row) => {
				return selectResolveRow(state, tableId, row)
			})
		},
	)

export const selectMappedRowData: (
	state: RootState,
	tableId: string,
) => Map<string, ResolvedRowData & { _id: string }> = createSelector(
	selectResolvedRowData,
	(rows) => {
		return new Map(rows.map((_row) => [_row._id, { ..._row, _id: _row._id }]))
	},
)

export const selectResolvedTableRows = createSelector(
	selectTableRows,
	selectMappedRowData,
	(rows, rowData) =>
		rows.map((_row) => ({
			..._row,
			data: rowData.get(_row._id) ?? _row.data,
		})),
)

export const selectMappedRecords = createSelector(selectResolvedTableRows, (rows) => {
	return new Map(rows.map((_row) => [_row._id, _row]))
})

const selectedCachedChanges = createSelector(
	(state: RootState, tableId: string) => selectTableState(state, tableId)?.previousRowCache,
	(cache) => Object.keys(cache ?? {}),
)

const selectedCachedNewRows = createSelector(
	(state: RootState, tableId: string) => selectTableState(state, tableId)?.newRowIds,
	(newRowIds) => newRowIds ?? [],
)

export const selectCachedRowIds = createSelector(
	selectedCachedChanges,
	selectedCachedNewRows,
	(rowsBeforeChange, newRowIds) => {
		return new Set<string>([...(rowsBeforeChange ?? []), ...newRowIds])
	},
)

export const selectTableList = createSelector(
	selectTableProviderState,
	({ tableList }) => tableList,
)

export const selectLinkedTable: (state: RootState, tableId?: string) => LinkedTable | undefined =
	createSelector(
		selectTableList,
		(state: RootState, tableId?: string) => tableId,
		(tableList, tableId) => {
			return tableList.find((_table) => _table._id === tableId)?.linkedTable
		},
	)

export const selectReferenceTables = createSelector(
	selectTableState,
	(state) => state?.referenceTables ?? {},
)

export const selectTableFetchingStates = createSelector(
	selectTableProviderState,
	({ fetchingTables }) => fetchingTables,
)

export const isFetchingTable: TableSelector<boolean> = createSelector(
	selectTableFetchingStates,
	(state: RootState, tableId: string) => tableId,
	(fetchingTables, tableId) => !!fetchingTables?.[tableId]?.isFetching,
)

export const isFetchingTableList = createSelector(
	selectTableProviderState,
	({ isFetchingTableList }) => isFetchingTableList,
)

export const selectAllConnectedTables: TableSelector<{ _id: string; label: string }[]> =
	createSelector(
		selectResolvedTableRows,
		selectPopulatedTableFields,
		selectTableList,
		(rows, fields, tableList) => {
			let distinctTableIds = fields.reduce((_distinctTableIds: string[], _field) => {
				const referenceRowTableIds = rows.reduce((_tableIds: string[], _row) => {
					if (!_row.calculations?.[_field._id]?.length) {
						return _tableIds
					}

					const referencedTableIds = _row.calculations[_field._id].reduce(
						(_tableIds: string[], _slice) => {
							if (_slice.vlookup?.targetTableId) {
								return [..._tableIds, _slice.vlookup.targetTableId]
							}
							if (_slice.lookup) {
								return [..._tableIds, _slice.lookup.tableId]
							}
							return _tableIds
						},
						[],
					)

					return _uniq([..._tableIds, ...referencedTableIds])
				}, [])

				_distinctTableIds.push(...referenceRowTableIds)

				if ([TableTypes.FieldType.REFERENCE].includes(_field.type) && _field.reference) {
					return [..._distinctTableIds, _field.reference]
				}

				if (
					_field.type === TableTypes.FieldType.SINGLE_SELECT &&
					_field.single_select_lookup?.tableId
				) {
					return [..._distinctTableIds, _field.single_select_lookup.tableId]
				}

				if (_field.calculation) {
					const referencedFieldTableIds = _field.calculation.reduce(
						(_tableIds: string[], _slice) => {
							if (_slice.vlookup?.targetTableId) {
								return [..._tableIds, _slice.vlookup.targetTableId]
							}
							if (_slice.lookup) {
								return [..._tableIds, _slice.lookup.tableId]
							}
							return _tableIds
						},
						[],
					)

					return [..._distinctTableIds, ..._uniq([...referencedFieldTableIds])]
				}

				return _distinctTableIds
			}, [])
			distinctTableIds = _uniq(distinctTableIds)

			return distinctTableIds.reduce((_acc: { _id: string; label: string }[], _tableId) => {
				const table = tableList.find((_table) => _table._id === _tableId)
				if (!table) {
					return _acc
				}
				return [..._acc, { _id: table._id, label: table.name }]
			}, [])
		},
	)

const selectQuestionnaireTable = createSelector(
	selectTableState,
	selectTableProviderState,
	(rootState: RootState) => rootState,
	(state, providerState, rootState) => {
		if (!state?.config?.questionaire_reference_table) {
			return undefined
		}
		return selectTableState(rootState, state.config.questionaire_reference_table)
	},
)

export const selectQuestions = createSelector(
	projectSelectors.getQuestionnaireAnswers,
	selectQuestionnaireTable,
	selectMappedColumns,
	selectColumnFilterList,
	selectResolvedRowData,
	selectValueOptions,
	(
		state: RootState,
		tableId: string,
		filters: GridFilterModel,
		acceptedQuestionColumns: string[],
		questionScope: string[],
	) => ({
		filters,
		acceptedQuestionColumns,
		questionScope,
	}),
	(
		answers,
		questionnaireTable,
		mappedColumns,
		columnFilterList,
		resolvedRows,
		mappedValueOptions,
		{ filters, acceptedQuestionColumns, questionScope },
	) => {
		if (!questionnaireTable) {
			return []
		}

		const questions = getQuestionaireQuestions({
			answers,
			questionTable: questionnaireTable,
			filters,
			mappedColumns,
			questionScope,
			acceptedQuestionColumns,
			columnFilterList,
			records: resolvedRows,
			mappedValueOptions,
		})

		return sortNodesWithDescendants(questions, questionScope)
	},
)
