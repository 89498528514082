import _isArray from 'lodash/isArray'
import { ComponentType, PropsWithChildren, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TableTypes } from '@cango-app/sdk/types'
import { GridRowId, GridValidRowModel } from '@mui/x-data-grid-premium'
import { TablesSdk } from '@cango-app/sdk/api'

import { usePrevious } from 'src/hooks/usePrevious'
import { getNewUniqueId } from 'src/modules/tables/utils'
import { selectors as authSelectors } from 'src/store/modules/auth'
import {
	actions as projectActions,
	selectors as projectSelectors,
} from 'src/store/modules/projects-v3'
import {
	selectors as tableSelectors,
	actions as tableActions,
	TableState,
	UpdateRecordsRequestParams,
	ResolvedRowData,
} from 'src/store/modules/tables'
import { AsyncDispatchType, RootState } from 'src/store/types'

import { TableProviderProps, TaskProviderChildProps } from './types'
import TableContext from './context'

const TableProviderContainer: ComponentType<PropsWithChildren<TableProviderProps>> = (props) => {
	const dispatch = useDispatch<AsyncDispatchType>()
	const organisationId = useSelector(authSelectors.getOrganisationId)
	const tableId = props.tableId
	const previousTableId = usePrevious(tableId)
	const selectedProject = useSelector(projectSelectors.getSelectedProject)
	const selectedProjectId = selectedProject?._id
	const questionnaireAnswers = useSelector(projectSelectors.getQuestionnaireAnswers)
	const projectTableConfig = useSelector((state: RootState) =>
		projectSelectors.getSelectedProjectTableConfig(state, tableId),
	)

	const allTableIds = useSelector(tableSelectors.selectAllTableIds)
	const columns = useSelector((state: RootState) =>
		tableSelectors.selectPopulatedTableFields(state, tableId),
	)
	const state = useSelector((state: RootState) => tableSelectors.selectTableState(state, tableId))
	const tableRows = useSelector((state: RootState) =>
		tableSelectors.selectResolvedTableRows(state, tableId),
	)
	const mappedColumns = useSelector((state: RootState) =>
		tableSelectors.selectMappedColumns(state, tableId),
	)
	const mappedRecords = useSelector((state: RootState) =>
		tableSelectors.selectMappedRecords(state, tableId),
	)

	const blueprintRows = useSelector((state: RootState) =>
		tableSelectors.selectBlueprintTableRows(state, tableId),
	)

	const mappedRowData = useSelector((state: RootState) =>
		tableSelectors.selectMappedRowData(state, tableId),
	)
	const resolvedRowData = useSelector((state: RootState) =>
		tableSelectors.selectResolvedRowData(state, tableId),
	)
	const columnList = useSelector((state: RootState) =>
		tableSelectors.selectColumnList(state, tableId),
	)
	const columnFilterList = useSelector((state: RootState) =>
		tableSelectors.selectColumnFilterList(state, tableId),
	)
	const mappedValueOptions = useSelector((state: RootState) =>
		tableSelectors.selectValueOptions(state, tableId),
	)

	const tableConfig = useSelector((state: RootState) =>
		tableSelectors.selectTableConfig(state, tableId),
	)
	const linkedQuestionaire = useSelector((state: RootState) =>
		tableSelectors.selectLinkedTable(state, tableId),
	)
	const cachedRowIds = useSelector((state: RootState) =>
		tableSelectors.selectCachedRowIds(state, tableId),
	)
	const referenceTables = useSelector((state: RootState) =>
		tableSelectors.selectReferenceTables(state, tableId),
	)
	const isFetchingTable = useSelector((state: RootState) =>
		tableSelectors.isFetchingTable(state, tableId),
	)

	const hardSaveChanges = async () => {
		if (!tableId) {
			return
		}
		if (selectedProjectId) {
			await dispatch(
				projectActions.updateProject({
					projectId: selectedProjectId,
					questionaire_answers: questionnaireAnswers,
				}),
			)
		}
		await dispatch(tableActions.saveChanges(tableId))
	}

	const updateRecords = async (params: UpdateRecordsRequestParams): Promise<ResolvedRowData[]> => {
		if (!tableConfig) {
			return []
		}
		const response = await dispatch(tableActions.updateRecords({ ...params, tableId }))
		const payload = response.payload

		if (!payload) {
			return []
		}

		if (_isArray(payload)) {
			return payload
		}

		return payload.updatedRows
	}

	const cacheRowUpdates = (
		updates: {
			oldRow: TableTypes.TableRow
			newRow: TableTypes.TableRow
		}[],
		newRows?: TableTypes.TableRow[],
	) => {
		dispatch(tableActions.softUpdateRows({ tableId, updates, newRows }))
	}

	const handleAddRow = async (
		data: { newRowId: string; parentId?: string },
		rowOrder: string[],
	) => {
		if (!organisationId) {
			return
		}

		const recordData: TableTypes.RecordData = {}

		const uniqueIdColumns = columns.reduce((_acc: string[], column) => {
			if (column.type === TableTypes.FieldType.UNIQUE_ID) {
				_acc.push(column._id)
			}
			return _acc
		}, [])

		if (uniqueIdColumns.length) {
			uniqueIdColumns.forEach((_colId) => {
				recordData[_colId] = getNewUniqueId({ resolvedRows: resolvedRowData, columnId: _colId })
			})
		}

		const newRow: TableTypes.TableRow = {
			_id: data.newRowId,
			data: recordData,
			tableId,
			organisationId,
			references: {},
			calculations: {},
			projectId: selectedProjectId,
		}
		await dispatch(
			tableActions.addRow({
				tableId,
				data: {
					newRow,
					parentId: data.parentId,
					rowOrder,
				},
			}),
		)
	}

	const handleAddColumn = async (numberOfColumns?: number) => {
		await dispatch(tableActions.addColumn({ tableId, numberOfColumns }))
	}

	const discardAllChanges = () => {
		dispatch(projectActions.updateQuestionnaireAnswers([]))
		dispatch(tableActions.discardAllChanges({ tableId, projectId: selectedProjectId }))
	}

	const handleUpdateColumn = async (
		fieldId: string,
		updates: Omit<TablesSdk.UpdateFieldRequest, 'tableId'>,
		otherFields?: Partial<Omit<TableState, 'fields' | 'records'>>,
	) => {
		const previousColumn = mappedColumns.get(fieldId)
		if (!previousColumn) {
			return
		}
		await dispatch(
			tableActions.updateColumn({
				tableId,
				fieldId,
				updates,
				otherFields,
				previousColumn,
			}),
		).unwrap()
	}

	const handleDeleteColumn = async (fieldId: string) => {
		const columnIndex = columns.findIndex((_col) => _col._id === fieldId)
		const column = columns[columnIndex]
		if (!column) {
			return
		}
		await dispatch(
			tableActions.deleteColumn({
				tableId,
				column,
				indexOfColumn: columnIndex,
			}),
		)
	}

	const handleDeleteRecords = async (selectedRows: Map<GridRowId, GridValidRowModel>) => {
		const previousRows = tableRows
		await dispatch(
			tableActions.deleteRecords({
				tableId,
				selectedRows,
				previousRows,
			}),
		)
	}

	const handleUpdateTableConfig = async (
		data: TablesSdk.UpdateTableConfigRequest,
		save?: boolean,
	) => {
		if (!tableConfig) {
			return
		}
		const previousConfig = tableConfig

		const isProjectTableConfigChange = data.row_order || data.column_order || data.row_density

		if (selectedProjectId && isProjectTableConfigChange) {
			await dispatch(
				projectActions.updateProject({
					projectId: selectedProjectId,
					table_config: {
						...selectedProject.table_config,
						[tableId]: {
							...(projectTableConfig ?? {
								column_order: tableConfig.column_order,
								row_order: tableConfig.row_order,
								row_density: tableConfig.row_density,
							}),
							...data,
						},
					},
				}),
			)
			return
		}

		await dispatch(tableActions.updateTableConfig({ tableId, data, save, previousConfig }))
	}

	const handleHardUpdateRecords = async (rows: Omit<TableTypes.TableRow, 'references'>[]) => {
		const previousRows = tableRows
		await dispatch(tableActions.hardUpdateRecords({ tableId, rows, previousRows }))
	}

	const handleSaveNewReferenceTables = (
		referenceTables: Record<string, TableTypes.PopulatedCangoTable>,
	) => {
		dispatch(tableActions.saveNewReferenceTables({ tableId, referenceTables }))
	}

	useEffect(() => {
		if (
			tableId &&
			tableId !== previousTableId &&
			!allTableIds.includes(tableId) &&
			!isFetchingTable
		) {
			dispatch(tableActions.fetchTable({ newTableId: tableId }))
		}
	}, [tableId, previousTableId, allTableIds])

	const contextValue: TaskProviderChildProps = {
		tableConfig,
		isLoadingTable: isFetchingTable,
		hardSave: hardSaveChanges,
		isUpdatingTable: !!state?.isUpdating,
		discardAllChanges,
		onAddRow: handleAddRow,
		onAddColumn: handleAddColumn,
		columnList,
		columnFilterList,
		mappedColumns,
		mappedRowData,
		mappedRecords,
		onUpdateColumn: handleUpdateColumn,
		onDeleteColumn: handleDeleteColumn,
		onDeleteRecords: handleDeleteRecords,
		updateTableConfig: handleUpdateTableConfig,
		resolvedRows: resolvedRowData,
		updateRecords,
		hardUpdateRecords: handleHardUpdateRecords,
		columns,
		cachedRowIds,
		saveNewReferenceTables: handleSaveNewReferenceTables,
		referenceTables,
		mappedValueOptions,
		cacheRowUpdates,
		linkedTable: linkedQuestionaire,
		blueprintRows,
	}

	return <TableContext.Provider value={contextValue}>{props.children}</TableContext.Provider>
}

export default TableProviderContainer
