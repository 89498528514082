import { TableTypes } from '@cango-app/sdk/types'
import {
	GridFooterContainer,
	GridSlotsComponentsProps,
	useGridApiContext,
} from '@mui/x-data-grid-premium'
import React, { ComponentType, useContext } from 'react'
import { v4 } from 'uuid'

import { PlusIcon } from 'src/assets/icons'
import { Button } from 'src/components'
import { TableContext } from 'src/providers'

export const CustomFooter: ComponentType<NonNullable<GridSlotsComponentsProps['footer']>> = ({
	isStatic,
}) => {
	const { onAddRow, tableConfig } = useContext(TableContext)
	const apiRef = useGridApiContext()

	const handleAddRow = () => {
		if (!tableConfig) {
			return
		}
		const newRowId = v4()
		if (tableConfig.type === TableTypes.TableType.Questionaire) {
			const selectedModel = apiRef.current.getCellSelectionModel()
			const selectedCells = Object.keys(selectedModel).reduce((acc: string[], key) => {
				if (selectedModel[key]) {
					acc.push(key)
				}
				return acc
			}, [])
			const rows = apiRef.current.getAllRowIds()
			const lastSelectedCell = selectedCells.reduce(
				(acc: { id: string; index: number }, rowId) => {
					const indexOfRow = rows.findIndex((_record) => _record === rowId)
					if (indexOfRow > acc.index) {
						acc.id = rowId
						acc.index = indexOfRow
					}
					return acc
				},
				{
					id: '',
					index: -1,
				},
			)

			const lastSelectedCellId = lastSelectedCell.id

			if (lastSelectedCellId) {
				onAddRow({ parentId: lastSelectedCellId, newRowId }, [])
				return
			}
		}

		const sortedRows = apiRef.current.getSortedRows()
		const rowIds = sortedRows.map((row) => row._id)

		onAddRow({ newRowId }, rowIds)
	}

	if (isStatic) {
		return null
	}

	return (
		<GridFooterContainer>
			<Button
				variant="text"
				size="small"
				sx={{ minWidth: 100, px: 2 }}
				startIcon={<PlusIcon width={16} />}
				onClick={handleAddRow}
			>
				New row
			</Button>
		</GridFooterContainer>
	)
}
