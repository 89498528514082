import { createAsyncThunk, Dispatch } from '@reduxjs/toolkit'
import { AuthSdk } from '@cango-app/sdk/api'
import { AxiosError } from 'axios'
import { decode as decodeJwt } from 'jsonwebtoken'
import size from 'lodash/size'
import { signInWithCustomToken } from 'firebase/auth'
import { UserTypes } from '@cango-app/sdk/types'

import { analytics } from 'src/biz'
import { errorHandler } from 'src/helpers/api'
import { firebaseAuth } from 'src/helpers/firebase'
import { showSnackbar } from 'src/helpers/snackbarManager'

import type { RootState, AsyncDispatchType } from '../../types'
import { actions as sessionConfig } from '../session-config'
import { actions as userActions } from '../user'
import { actions as rolesActions } from '../roles'
import { actions as contactActions } from '../contacts'
import { actions as configActions } from '../config'
import { actions as notesActions } from '../notes'
import { actions as persistedConfigActions } from '../persisted-config'
import { actions as projectActions } from '../projects-v3'
import { actions as authActions } from '../auth'

import { selectors as authSelectors } from './selectors'

const endSession = (dispatch: AsyncDispatchType) => {
	dispatch(rolesActions.endSession())
	dispatch(contactActions.endSession())
	dispatch(projectActions.endSession())
	dispatch(notesActions.endSession())
	dispatch(notesActions.endSession())
	dispatch(userActions.endSession())
	dispatch(configActions.endSession())
	dispatch(persistedConfigActions.endSession())
	dispatch(sessionConfig.endSession())
}

export const ssoLogin = createAsyncThunk<
	{
		jwt: string
		organisationId: string
		organisationName: string
		organisationCode: string
	},
	AuthSdk.SsoLoginRequest,
	{ rejectValue: string; dispatch: AsyncDispatchType }
>('auth/sso-login', async (data, { rejectWithValue, dispatch }) => {
	try {
		const response = await AuthSdk.ssoLogin(import.meta.env.VITE_API as string, data)
		const { token: jwt } = response
		const decodedJwt = decodeJwt(jwt) as AuthSdk.SsoTokenFields
		const { organisationName, organisationCode, ...rest } = decodedJwt
		analytics.identify(rest._id, {
			name: rest.name + rest.surname,
			email: rest.email,
			organisationName,
			organisationId: data.organisationId,
			permissions: rest.permissions,
			organisationTimezone: rest.organisationTimezone,
		})
		dispatch(userActions.setUser(rest))
		dispatch(sessionConfig.setSelectedOrganisationId(data.organisationId))
		return {
			jwt,
			organisationId: data.organisationId,
			organisationName,
			organisationCode,
		}
	} catch (err) {
		const responseMessage = (err as AxiosError<{ message: string }>)?.response?.data.message
		if (responseMessage) {
			showSnackbar(responseMessage, { variant: 'error' })
		} else if (
			[401, 403].includes((err as AxiosError<{ message: string }>)?.response?.status ?? 0)
		) {
			showSnackbar((err as AxiosError<{ message: string }>)?.response?.data.message, {
				variant: 'error',
			})
		} else {
			showSnackbar('Server error logging in', { variant: 'error' })
		}
		return rejectWithValue((err as Error).message)
	}
})

export const getOrganisations = createAsyncThunk<
	AuthSdk.GetOrganisationsResponse,
	{ authToken: string },
	{ rejectValue: string; dispatch: AsyncDispatchType }
>('auth/get-organisations', async ({ authToken }, { rejectWithValue, dispatch }) => {
	try {
		const response = await AuthSdk.getOrganisations(import.meta.env.VITE_API as string, {
			authToken,
		})

		if (size(response.organisations) === 1) {
			dispatch(
				ssoLogin({
					organisationId: Object.keys(response.organisations)[0],
					loginToken: response.loginToken,
					isManualLogin: false,
				}),
			)
		}

		return response
	} catch (err) {
		if ((err as any).response.status === 401) {
			showSnackbar((err as any).response.data.message, { variant: 'error' })
		} else {
			showSnackbar('Server error logging in', { variant: 'error' })
		}
		return rejectWithValue((err as Error).message)
	}
})

export const logoutUser = createAsyncThunk<void, void, { state: RootState; dispatch: Dispatch }>(
	'auth/logoutUser',
	async (_, { dispatch }) => {
		try {
			endSession(dispatch)
		} catch (error) {
			errorHandler({ error, dispatch })
		}
	},
)

export const switchOrganisation = createAsyncThunk<
	{
		jwt: string
		organisationId: string
		organisationName: string
		user: Omit<UserTypes.User, 'organisations'>
		organisationCode: string
	},
	{ organisationId: string },
	{ state: RootState; dispatch: AsyncDispatchType; rejectValue: string }
>(
	'auth/switch-organisation',
	async ({ organisationId }, { dispatch, rejectWithValue, getState }) => {
		try {
			const state = getState()
			dispatch(configActions.endSession())
			const authHeaders = authSelectors.getAuthHeaders(state)
			const response = await AuthSdk.switchOrganisation(
				import.meta.env.VITE_API as string,
				authHeaders,
				{ organisationId },
			)
			const { token: jwt } = response
			const decodedJwt = decodeJwt(jwt) as AuthSdk.SsoTokenFields
			const { organisationName, organisationCode, ...rest } = decodedJwt
			analytics.identify(rest._id, {
				name: rest.name + rest.surname,
				email: rest.email,
				organisationName,
				organisationId: organisationId,
				permissions: rest.permissions,
				organisationTimezone: rest.organisationTimezone,
			})
			endSession(dispatch)
			dispatch(sessionConfig.setSelectedOrganisationId(organisationId))
			dispatch(authActions.setLastVisitedOrganisationId(organisationId))
			return {
				jwt,
				organisationId: organisationId,
				organisationName,
				organisationCode,
				user: rest,
			}
		} catch (error) {
			return rejectWithValue((error as Error).message)
		}
	},
)

export const fetchFirebaseToken = createAsyncThunk<
	AuthSdk.FetchFirebaseTokenResponse,
	void,
	{ state: RootState; rejectValue: string }
>('auth/fetch-firebase-token', async (_, { rejectWithValue, getState }) => {
	try {
		const state = getState()
		const authHeaders = authSelectors.getAuthHeaders(state)
		const response = await AuthSdk.fetchFirebaseToken(
			import.meta.env.VITE_API as string,
			authHeaders,
		)
		await signInWithCustomToken(firebaseAuth, response.token)
		return response
	} catch (error) {
		return rejectWithValue((error as Error).message)
	}
})
