import React, { ComponentType, useContext } from 'react'
import { StepTypes, TaskTypes } from '@cango-app/sdk/types'
import { useSelector } from 'react-redux'

import { TableProvider, TaskContext } from 'src/providers'
import { selectors as projectSelectors } from 'src/store/modules/projects-v3'
import { FileTemplate } from 'src/modules/my-tasks-v3/components/actions/file-tasks/file-template'
import { Archive } from 'src/modules/my-tasks-v3/components/actions/archive'
import { ContactInfo } from 'src/modules/my-tasks-v3/components/actions/contact-info'
import { CreateContact } from 'src/modules/my-tasks-v3/components/actions/create-contact'
import { FileUpload } from 'src/modules/my-tasks-v3/components/actions/file-tasks/file-upload'
import { Note } from 'src/modules/my-tasks-v3/components/actions/note'
import { Software } from 'src/modules/my-tasks-v3/components/actions/software'
import { VideoEmbed } from 'src/modules/my-tasks-v3/components/actions/video-embed'
import { SetResources } from 'src/modules/my-tasks-v3/components/actions/set-resources'
import { TaskReference } from 'src/modules/my-tasks-v3/components/actions/task-reference'
import { Template } from 'src/modules/my-tasks-v3/components/actions/template-modal'
import { QuestionFlowLogicContainer } from 'src/components/question-flow/question-flow-logic-container'

export const ActionComponent: ComponentType<{
	action: TaskTypes.TaskAction
	isAttachment?: boolean
}> = ({ action, isAttachment = false }) => {
	const { task } = useContext(TaskContext)
	const project = useSelector(projectSelectors.getSelectedProject)
	const projectTable = useSelector(projectSelectors.getSelectedProjectTableId)
	if (!task || !project) return <></>

	switch (action.type) {
		case StepTypes.Action.ActionEnum.FileTemplate:
			return (
				<FileTemplate task={task} project={project} action={action} isAttachment={isAttachment} />
			)
		case StepTypes.Action.ActionEnum.Archive:
			return <Archive task={task} />
		case StepTypes.Action.ActionEnum.Invite:
		case StepTypes.Action.ActionEnum.Meeting:
		case StepTypes.Action.ActionEnum.Call:
		case StepTypes.Action.ActionEnum.Email:
			return <ContactInfo project={project} isAttachment={isAttachment} />
		case StepTypes.Action.ActionEnum.Contact:
			return <CreateContact task={task} />
		case StepTypes.Action.ActionEnum.FileUpload:
			return (
				<FileUpload project={project} task={task} action={action} isAttachment={isAttachment} />
			)
		case StepTypes.Action.ActionEnum.Note:
			return <Note note={action.note} />
		case StepTypes.Action.ActionEnum.Software:
			return <Software links={action.links} />
		case StepTypes.Action.ActionEnum.Video:
			return <VideoEmbed urls={action.links} />
		case StepTypes.Action.ActionEnum.SetResources:
			return (
				<TableProvider tableId={action.table_view?.table ?? projectTable ?? ''}>
					<SetResources action={action} />
				</TableProvider>
			)
		case StepTypes.Action.ActionEnum.TaskReference:
			return (
				<TaskReference
					taskReferences={action.task_references}
					project={project}
					isAttachment={isAttachment}
				/>
			)
		case StepTypes.Action.ActionEnum.Template:
			return <Template action={action} />
		case StepTypes.Action.ActionEnum.Questionaire:
			return (
				<TableProvider tableId={projectTable ?? ''}>
					<QuestionFlowLogicContainer
						filters={{
							...task.chain?.database_chain_logic?.filters,
							...action.questionaire?.filters,
							items: [
								...(task.chain?.database_chain_logic?.filters?.items ?? []),
								...(action.questionaire?.filters.items ?? []),
							],
						}}
						acceptedQuestionColumns={action.questionaire?.columns ?? []}
						questionScope={action.questionaire?.scope ?? []}
					/>
				</TableProvider>
			)
		default: {
			return <></>
		}
	}
}
