import { Stack } from '@mui/material'
import isUndefined from 'lodash/isUndefined'
import { useContext, useState } from 'react'
import { useSelector } from 'react-redux'

import { Button, TextField, Text, Modal } from 'src/components'
import { showSnackbar } from 'src/helpers/snackbarManager'
import { TableContext } from 'src/providers'
import { selectors as projectSelectors } from 'src/store/modules/projects-v3'

type Props = {
	isOpen: boolean
	columnName: string
	defaultValue: string
	onClose: () => void
	rowId: string
	fieldId: string
}

export const LongTextModal = ({
	isOpen,
	onClose,
	columnName,
	defaultValue,
	rowId,
	fieldId,
}: Props) => {
	const { mappedRecords, updateRecords } = useContext(TableContext)
	const [textArea, setTextArea] = useState(defaultValue)
	const selectedProjectId = useSelector(projectSelectors.getSelectedProjectId)

	const onSave = async () => {
		const foundRow = mappedRecords.get(rowId)
		if (isUndefined(foundRow)) {
			showSnackbar('Error saving text', { variant: 'error' })
			return
		}
		await updateRecords({
			rows: [
				{
					oldRow: foundRow,
					newRow: {
						...foundRow,
						data: {
							...foundRow?.data,
							[fieldId]: textArea,
						},
					},
				},
			],
			save: true,
			projectId: selectedProjectId,
		})
		onClose()
	}
	return (
		<Modal
			open={isOpen}
			onClose={(e) => {
				e.stopPropagation()
				onClose()
			}}
		>
			<Stack direction="column" spacing={2} width="550px">
				<Text>{columnName}</Text>
				<TextField
					fullWidth
					multiline
					rows={16}
					value={textArea}
					onChange={(e) => {
						e.stopPropagation()
						setTextArea(e.target.value)
					}}
				/>
				<Button onClick={onSave}>Save</Button>
			</Stack>
		</Modal>
	)
}
