import { Dispatch, createAsyncThunk } from '@reduxjs/toolkit'
import { ImagesSdk } from '@cango-app/sdk/api'

import { errorHandler } from 'src/helpers/api'

import { RootState } from '../../types'
import { selectors as authSelectors } from '../auth'

export const getImages = createAsyncThunk<
	ImagesSdk.GetBucketResponse,
	void,
	{ rejectValue: string; dispatch: Dispatch; state: RootState }
>('images/get-bucket', async (_, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState()
		const headers = authSelectors.getAuthHeaders(state)
		return await ImagesSdk.getBucket(import.meta.env.VITE_API as string, headers)
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue((error as Error).message)
	}
})
