import { TableTypes, TemplateTypes, TaskTypes, ContactTypes } from '@cango-app/sdk/types'
import { useCallback, useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import _isNumber from 'lodash/isNumber'

import { TableContext, TaskContext } from 'src/providers'
import { selectors as contactSelectors } from 'src/store/modules/contacts'
import { selectors as projectSelectors } from 'src/store/modules/projects-v3'
import { getRows, getSanitizedGroupedValue, ViewRow } from 'src/components/display-view/use-rows'
import { columnValueFormatter, columnValueGetter, getFilters } from 'src/modules/tables/utils'
import { getChainList } from 'src/helpers/chains'

type UseResolveTemplate = {
	template: TaskTypes.TaskAction['template']
}

const convertDataGridToHTMLTable = (columns: TableTypes.Field[], rows: ViewRow[]): string => {
	if (rows.length === 0) return '<p>No data available</p>'

	return `<table>
        <thead>
        <tr>${columns.map((col) => `<th style="background: #f4f4f4; padding: 8px;">${col.name}</th>`).join('')}</tr>
        </thead>
				<tbody>
	  ${rows
			.map(
				(row) => `
	    <tr>
	      ${columns
					.map((col: TableTypes.Field) => {
						return `<td>${(_isNumber(row[col._id]) ? row[col._id].toFixed(2) : row[col._id]) ?? ''}</td>`
					})
					.join('')}
	    </tr>
	  `,
			)
			.join('')}
    </table>`
}

const getFormattedChainOptions = ({
	mappedColumns,
	mappedContacts,
	chain,
}: {
	mappedColumns: Map<string, TableTypes.Field>
	mappedContacts: Map<string, ContactTypes.Contact>
	chain: TaskTypes.Chain.ProjectChain | undefined
}) => {
	if (!chain) return ''
	const { prefix, selected_option, columnType } = chain.label
	if (!columnType || !selected_option || !prefix) return ''
	if (
		[
			TableTypes.FieldType.CONTACT,
			TableTypes.FieldType.SINGLE_SELECT,
			TableTypes.FieldType.TABLE_SELECT,
			TableTypes.FieldType.REFERENCE,
		].includes(columnType)
	) {
		const column = mappedColumns.get(prefix)
		return column?.valueOptions.find(({ _id }) => selected_option === _id)?.label ?? ''
	}

	if (columnType === TableTypes.FieldType.ROLE) {
		const contact = mappedContacts.get(prefix)
		if (!contact) return ''
		return `${contact.name} ${contact.surname}`
	}
	return selected_option
}

export const useResolveTemplate = ({ template }: UseResolveTemplate) => {
	const contacts = useSelector(contactSelectors.getContactsForSelect)
	const { task } = useContext(TaskContext)
	const mappedContacts = useSelector(contactSelectors.mappedContacts)
	const {
		tableConfig,
		mappedColumns,
		resolvedRows,
		columnFilterList,
		columns,
		mappedValueOptions,
	} = useContext(TableContext)
	const projectTasks = useSelector(projectSelectors.getProjectTasks)
	const project = useSelector(projectSelectors.getSelectedProject)

	const views = useMemo(() => {
		if (!tableConfig) return []
		return tableConfig.views
	}, [tableConfig])

	const replaceVariables = useCallback(
		(taskTemplate: TaskTypes.TaskAction['template']): string => {
			const taskActionTemplateVariables = taskTemplate?.assigned_values ?? {}
			const template = taskTemplate?.template
			if (!views || !template) {
				return ''
			}

			return template.content.replace(/\{\{([^}]+)\}\}/g, (_, variableName: string): string => {
				const templateVariable = template.variables.find(({ name }) => name === variableName)
				const foundVariable = taskActionTemplateVariables[templateVariable?._id ?? '']
				const blueprintVariable = template.variables.find(
					(variable) => variable._id === templateVariable?._id,
				)
				if (blueprintVariable?.type === TemplateTypes.VariableType.project_name) {
					return project?.name ?? ''
				}

				if (!foundVariable || !blueprintVariable) {
					return `{{${variableName}}}`
				}

				switch (blueprintVariable.type) {
					case TemplateTypes.VariableType.text:
						return foundVariable.value ?? `{{${variableName}}}`
					case TemplateTypes.VariableType.contacts: {
						const foundContact = contacts.find(({ _id }) => _id === foundVariable.value)
						return `${foundContact?.name} ${foundContact?.surname}`
					}
					case TemplateTypes.VariableType.view: {
						const foundView = views.find(({ _id }) => foundVariable.value === _id)
						if (!foundView) {
							return `{{${variableName}}}`
						}
						const { rows } = getRows({
							groupingFields: foundView.groupedFields,
							columnFilterList,
							mappedColumns,
							filters: {
								items: getFilters({
									chains: getChainList(task?.chain),
									mappedColumns,
									viewFilters: foundView.filters.items,
								}),
							},
							resolvedRows,
						})

						const groupedRows = rows.reduce(
							(
								_acc: Record<
									string,
									{
										column_visibility?: string[]
										rows: ViewRow[]
									}
								>,
								row,
							) => {
								const { groupName, columnVisibility } = row.hierarchy.reduce(
									(
										_acc: {
											groupName: string
											columnVisibility?: string[]
										},
										_value,
										_hierarchyIndex,
									) => {
										const sanitisedRowId = getSanitizedGroupedValue(row._id)
										if (_value._id === sanitisedRowId) {
											return _acc
										}
										return {
											groupName: `${_acc.groupName ? ' --> ' : ''}${_value.label ?? ''}`,
											columnVisibility: foundView.groupedFields[_hierarchyIndex]?.column_visibility,
										}
									},
									{
										groupName: '',
									},
								)

								if (!_acc[groupName]) {
									_acc[groupName] = {
										rows: [],
									}
								}

								if (!_acc[groupName].column_visibility && columnVisibility) {
									_acc[groupName].column_visibility = columnVisibility
								}

								const formattedRow = Object.keys(row).reduce(
									(acc: ViewRow, key): ViewRow => {
										if (key === 'hierarchy' || key === '_id') {
											return acc
										}
										const column = mappedColumns.get(key)
										if (!column) {
											return acc
										}
										const rowValue = row[key]
										const value = columnValueGetter(rowValue, column, mappedValueOptions)
										const formattedValue = columnValueFormatter(value, column, mappedValueOptions)
										return {
											...acc,
											[key]: formattedValue,
										}
									},
									{
										hierarchy: row.hierarchy,
									},
								)

								_acc[groupName].rows.push(formattedRow)
								return _acc
							},
							{},
						)

						const tables = Object.entries(groupedRows).map(([groupName, groupedRows]) => {
							const filteredColumnVisibility = columns.filter(({ _id }) => {
								if (!groupedRows.column_visibility) {
									return true
								}
								return groupedRows.column_visibility.includes(_id)
							})
							return `
							<div>
								${!!groupName && `<h3>${groupName}</h3>`}
								${convertDataGridToHTMLTable(filteredColumnVisibility, groupedRows.rows)}
							</div>
							`
						})

						// return
						return `<div>${tables.join('')}</div>`
					}
					case TemplateTypes.VariableType.chain: {
						const chain = projectTasks.reduce(
							(_chain: TaskTypes.Chain.ProjectChain | undefined, _task) => {
								if (_chain) {
									return _chain
								}
								const taskDescendant = _task.step?.descendants?.find(
									(_desc) => _desc._id === foundVariable.value,
								)
								if (!taskDescendant) {
									return task?.chain
								}

								const childTasks = projectTasks.filter(
									({ parent, step }) =>
										parent?._id === _task._id && step?._id === taskDescendant.step?._id,
								)
								if (!childTasks.length) {
									return _chain
								}

								const foundChildTask = childTasks.find(({ _id }) => _id === task?._id)

								if (foundChildTask) {
									return foundChildTask.chain
								}
								return _chain
							},
							undefined,
						)
						return getFormattedChainOptions({
							mappedColumns,
							chain,
							mappedContacts,
						})
					}
					default:
						return `{{${variableName}}}`
				}
			})
		},
		[views, mappedColumns, task?.chain, tableConfig, resolvedRows, mappedContacts],
	)

	const content = useMemo(() => {
		if (!template) {
			return
		}
		return replaceVariables(template)
	}, [replaceVariables, template])

	return {
		content,
	}
}
