import { createSelector } from '@reduxjs/toolkit'
import { UserTypes, PermissionsTypes } from '@cango-app/sdk/types'
import { AuthSdk } from '@cango-app/sdk/api'

import { getNameInitials } from 'src/routing/navigation/utils'

import { selectors as authSelectors } from '../auth/selectors'
import { RootState } from '../../types'

import { UserState } from './types'

const getUserState = (state: RootState) => state.cangoUser

const getUserDetails: (state: RootState) => UserState['currentUser'] | undefined = createSelector(
	getUserState,
	({ currentUser }) => currentUser,
)

const getCurrentUserId: (state: RootState) => string = createSelector(
	getUserDetails,
	(user) => user?._id || '',
)

const getAllUsers: (state: RootState) => UserTypes.PopulatedUser[] = createSelector(
	getUserState,
	({ users }) => {
		return users
	},
)

const getMappedUsers: (state: RootState) => Map<string, UserTypes.PopulatedUser> = createSelector(
	getAllUsers,
	(users) => new Map(users.map((user) => [user._id, user])),
)

const getUserById: (state: RootState, userId?: string) => UserTypes.PopulatedUser | undefined =
	createSelector(
		getAllUsers,
		(state: RootState, user_id?: string) => user_id,
		(allUsers, userId) => allUsers.find(({ _id }) => _id === userId),
	)

const getSelectedOrganisationRoles = createSelector(
	getUserById,
	authSelectors.getOrganisationId,
	(user, organisationId) =>
		user?.organisations.find(({ organisation }) => organisationId === organisation)?.roles ?? [],
)

const getAllUsersForSelect: (
	state: RootState,
) => Array<UserTypes.PopulatedUser & { label: string }> = createSelector(getAllUsers, (users) =>
	users.map((user) => ({
		...user,
		label: `${user.name} ${user.surname}`,
		value: user._id,
	})),
)

const getAllUsersForSelectWithUnassigned: (state: RootState) => { label: string; _id: string }[] =
	createSelector(getAllUsersForSelect, (users) => [
		...users,
		{ label: 'Unassigned', _id: 'unassigned' },
	])

const getAllUsersForTagging: (state: RootState) => Array<{ id: string; display: string }> =
	createSelector(getAllUsers, (users) =>
		users
			.map((user) => ({
				id: user._id,
				display: `${user.name} ${user.surname}`,
			}))
			.sort((a, b) => a.display.localeCompare(b.display)),
	)

const getPermissions: (
	state: RootState,
) => Pick<PermissionsTypes.Permissions, '_id' | 'name' | 'routes'> | undefined = createSelector(
	getUserState,
	({ currentUser }) => {
		return currentUser?.permissions
	},
)

const getUserName: (state: RootState) => string | undefined = createSelector(
	getUserDetails,
	(user) => {
		if (!user?.name || !user?.surname) return undefined
		return `${user.name} ${user.surname}`
	},
)

const getUserEmail: (state: RootState) => string = createSelector(
	getUserDetails,
	(user) => user?.email || '',
)

const getUserInitials: (state: RootState) => string = createSelector(getUserDetails, (user) =>
	getNameInitials(user?.name, user?.surname),
)

const getChildrenOrganisations: (state: RootState) => AuthSdk.SharedOrganisation[] | undefined =
	createSelector(getUserDetails, (user) => user?.childrenOrganisations)

const getParentOrganisations: (state: RootState) => AuthSdk.SharedOrganisation[] | undefined =
	createSelector(getUserDetails, (user) => user?.parentOrganisations)

const isLoadingUsers = createSelector(getUserState, ({ isLoadingUsers }) => isLoadingUsers)

export const selectors = {
	getMappedUsers,
	getUserDetails,
	getAllUsersForSelect,
	getAllUsersForTagging,
	getPermissions,
	getAllUsers,
	getUserById,
	getCurrentUserId,
	getAllUsersForSelectWithUnassigned,
	getUserName,
	getUserInitials,
	getUserEmail,
	isLoadingUsers,
	getChildrenOrganisations,
	getParentOrganisations,
	getSelectedOrganisationRoles,
}
