import { Stack } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { useContext, useState } from 'react'

import { Button, Modal, Text, TextField } from 'src/components'
import { TableContext } from 'src/providers'

type Props = {
	open: boolean
	onClose: () => void
}

type Form = {
	numberOfColumns: number
}

const addMultipleColumnsSchema = z.object({
	numberOfColumns: z
		.string()
		.regex(/^\d+$/, 'Must be a valid number')
		.transform((val) => Number(val))
		.refine((num) => num >= 1 && num <= 20, {
			message: 'Cannot create this number of columns. Max 20',
		}),
})

export const AddMultipleColumnsModal = ({ onClose, open }: Props) => {
	const [isAddingColumns, setIsAddingColumns] = useState(false)
	const { control, handleSubmit } = useForm<Form>({
		defaultValues: {
			numberOfColumns: 1,
		},
		resolver: zodResolver(addMultipleColumnsSchema),
		mode: 'onChange',
	})
	const { onAddColumn } = useContext(TableContext)

	const onSubmit = async ({ numberOfColumns }: Form) => {
		setIsAddingColumns(true)
		await onAddColumn(numberOfColumns)
		setIsAddingColumns(false)
		onClose()
	}

	return (
		<Modal open={open} onClose={onClose}>
			<Stack direction="column" spacing={2}>
				<Text variant="h5">How many columns do you want to add?</Text>
				<Controller
					name="numberOfColumns"
					control={control}
					render={({ field: { onChange, value }, fieldState: { error } }) => (
						<TextField
							fullWidth
							label="Number of columns:"
							value={value}
							onChange={onChange}
							error={!!error}
							helperText={error?.message}
						/>
					)}
				/>
				<Button isLoading={isAddingColumns} onClick={handleSubmit(onSubmit)}>
					Add Columns
				</Button>
			</Stack>
		</Modal>
	)
}
