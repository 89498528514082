import { ComponentType } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { V3ProjectSdk } from '@cango-app/sdk/api'
import dayjs from 'dayjs'
import { TaskTypes } from '@cango-app/sdk/types'

import { AsyncDispatchType } from 'src/store/types'
import { actions as projectActions } from 'src/store/modules/projects-v3'
import { selectors as authSelectors } from 'src/store/modules/auth'
import { showSnackbar } from 'src/helpers/snackbarManager'

import { ArchiveButtons } from '../archive-buttons'

type ArchiveProps = {
	task: Pick<TaskTypes.PopulatedTask, '_id' | 'project_id' | 'lifecycle'>
}

export const Archive: ComponentType<ArchiveProps> = ({ task }) => {
	const dispatch = useDispatch<AsyncDispatchType>()
	const authHeaders = useSelector(authSelectors.getAuthHeaders)

	const handleArchiveProject = async () => {
		try {
			const archiveDate = dayjs().unix()
			await V3ProjectSdk.updateProject({
				baseURL: import.meta.env.VITE_API as string,
				authHeaders,
				projectId: task.project_id,
				data: { archived: { state: true, at: archiveDate } },
			})
			showSnackbar('Project archived', { variant: 'success' })
			dispatch(projectActions.fetchMyTasksProjects())
		} catch (error) {
			showSnackbar('Error archiving project', { variant: 'error' })
		}
	}

	const handleCompleteTaskClick = async () => {
		await dispatch(
			projectActions.completeTask({
				taskId: task._id,
				projectId: task.project_id,
				taskUpdates: {
					_id: task._id,
					lifecycle: {
						...task.lifecycle,
						complete: true,
					},
				},
				newTasks: [],
			}),
		)
	}

	return (
		<ArchiveButtons
			onArchiveProject={handleArchiveProject}
			onCompleteTask={handleCompleteTaskClick}
		/>
	)
}
