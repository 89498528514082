import React, { ComponentType, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Controller, useForm } from 'react-hook-form'
import { RoleTypes, ContactTypes } from '@cango-app/sdk/types'
import { RoleSdk } from '@cango-app/sdk/api'
import _debounce from 'lodash/debounce'
import { Autocomplete } from '@mui/material'

import { selectors as userSelectors } from 'src/store/modules/user'
import { selectors as contactSelectors } from 'src/store/modules/contacts'
import { Box, Button, Text, TextField, Select, Toggle } from 'src/components'
import { actions as roleActions, RoleWithUsage } from 'src/store/modules/roles'
import { selectors as authSelectors } from 'src/store/modules/auth'
import { showSnackbar } from 'src/helpers/snackbarManager'
import { AsyncDispatchType } from 'src/store/types'

type RoleFormProps = {
	role?: RoleWithUsage
	closeDrawer: () => void
	setShouldPromptDelete: (tf: boolean) => void
	onNewRoleAdded?: (roleId: string) => void
}

type RoleFormType = Omit<RoleTypes.ClientRole, '_id'>

export const RoleForm: ComponentType<RoleFormProps> = ({
	role,
	closeDrawer,
	setShouldPromptDelete,
	onNewRoleAdded,
}) => {
	const dispatch = useDispatch<AsyncDispatchType>()
	const [isLoading, setIsLoading] = useState(false)
	const { handleSubmit, control, watch, setValue } = useForm<RoleFormType>({
		defaultValues: {
			label: role?.label,
			internal: role?.internal,
			defaultUser: role?.defaultUser,
			defaultContact: role?.defaultContact,
		},
	})
	const watchLabel = watch('label')

	const [selectedInternalExternal] = watch(['internal'])
	const [availableRoles, setAvailableRoles] = useState<RoleSdk.GetRolesByLabelResponse>([])

	const users = useSelector(userSelectors.getAllUsersForSelect)
	const contacts = useSelector(contactSelectors.getContactsForSelect)
	const authHeaders = useSelector(authSelectors.getAuthHeaders)

	const fetchRoles = useCallback(
		_debounce(async () => {
			try {
				const response = await RoleSdk.getRolesbyLabel(
					import.meta.env.VITE_API as string,
					authHeaders,
					watchLabel,
				)
				setAvailableRoles(response)
			} catch (err) {
				showSnackbar('Error getting roles', { variant: 'error' })
			}
		}, 1000),
		[watchLabel],
	)

	useEffect(() => {
		if (!watchLabel || watchLabel.length < 3) return
		fetchRoles()
		return () => fetchRoles.cancel()
	}, [fetchRoles, watchLabel])

	const onSubmit = async (roleData: RoleFormType) => {
		setIsLoading(true)
		roleData.internal = !!roleData.internal
		if (role?._id) {
			const updateRoleResponse = await dispatch(
				roleActions.updateRole({ _id: role._id, ...roleData }),
			)
			if (updateRoleResponse.meta.requestStatus === 'fulfilled') {
				closeDrawer()
			}
		} else {
			const addRoleResponse = await dispatch(roleActions.addRole(roleData))
			if (addRoleResponse.meta.requestStatus === 'fulfilled') {
				if (
					onNewRoleAdded &&
					typeof addRoleResponse.payload !== 'string' &&
					typeof addRoleResponse.payload !== 'undefined'
				) {
					onNewRoleAdded(addRoleResponse.payload.role._id)
				}
				closeDrawer()
			}
		}
		setIsLoading(false)
	}

	return (
		<Box width={400} p={3} role="presentation">
			<Text sx={{ mb: 3 }} variant="h4">
				{role ? role.label : 'New Role'}
			</Text>
			<form>
				<Controller
					name={'internal'}
					control={control}
					rules={{
						validate: (value) => {
							if (value == null) {
								return 'Please, choose a value'
							}
							return true
						},
					}}
					render={({ field: { onChange, value }, fieldState: { error } }) => (
						<Toggle
							options={[
								{ label: 'Internal Role', value: true },
								{ label: 'External Role', value: false },
							]}
							value={value}
							onChange={onChange}
							containerProps={{ mb: 1 }}
							formControlProps={{
								error: !!error,
							}}
						/>
					)}
				/>
				<Controller
					name={'label'}
					control={control}
					rules={{ required: true }}
					render={({ field: { onChange, value }, fieldState: { error } }) =>
						role ? (
							<TextField
								error={!!error}
								fullWidth
								required
								onChange={onChange}
								value={value}
								label={'Role name'}
								containerProps={{ mb: 3, flex: 2 }}
							/>
						) : (
							<Autocomplete
								freeSolo
								options={availableRoles}
								getOptionLabel={(role) => (typeof role === 'string' ? role : role.label)}
								onInputChange={(e, value, reason) => {
									if (reason === 'reset') {
										// this is true when user selects from user email list
										const selectedRole = availableRoles.find(({ label }) => value === label)
										if (selectedRole) {
											setValue('label', selectedRole.label)
										}
									}
								}}
								renderInput={(params) => (
									<TextField
										{...params}
										error={!!error}
										disabled={!!role}
										onChange={onChange}
										value={value}
										helperText={!!error && error.message}
										required
										fullWidth
										label={'Label'}
										containerProps={{ mb: 3 }}
									/>
								)}
							/>
						)
					}
				/>
				{selectedInternalExternal ? (
					<Controller
						name={'defaultUser'}
						control={control}
						render={({ field: { onChange, value } }) => (
							<Select
								label="Who is the default user for this role?"
								onChange={onChange}
								value={value}
								withNoneOption
								options={users}
							/>
						)}
					/>
				) : (
					<Controller
						name={'defaultContact'}
						control={control}
						render={({ field: { onChange, value } }) => (
							<Select
								label="Who is the default contact for this role?"
								onChange={onChange}
								value={value}
								options={contacts.filter(({ type }) => type !== ContactTypes.ContactType.Internal)}
								withNoneOption
							/>
						)}
					/>
				)}
				<br />
				<Button variant="contained" onClick={handleSubmit(onSubmit)}>
					{role ? 'Update' : 'Create'}
				</Button>
				{role && (
					<Button
						onClick={() => setShouldPromptDelete(true)}
						variant="outlined"
						disabled={(role.usage ?? 0) > 0}
						color="error"
						sx={{ float: 'right' }}
						isLoading={isLoading}
					>
						{(role.usage ?? 0) > 0 ? 'Role in use' : 'Delete'}
					</Button>
				)}
			</form>
		</Box>
	)
}
