import { StepTypes } from '@cango-app/sdk/types'
import { ComponentType, useMemo } from 'react'

import { Box } from 'src/components'

import { Call } from './call'
import { Email } from './email'
import { Meeting } from './meeting'
import { Invite } from './invite'
import { FileTemplate } from './file-template'
import { FileUpload } from './file-upload'
import { Software } from './software'
import { Video } from './video'
import { Note } from './note'
import { Contact } from './contact'
import { Archive } from './archive'

type ActionIconProps = {
	actionType: StepTypes.Action.ActionEnum | string
	width: number
	stroke: string
}

export const ActionIcon: ComponentType<ActionIconProps> = ({ actionType, width, stroke }) => {
	const Icon = useMemo(() => {
		switch (actionType) {
			case StepTypes.Action.ActionEnum.Call:
				return Call
			case StepTypes.Action.ActionEnum.Email:
				return Email
			case StepTypes.Action.ActionEnum.Meeting:
				return Meeting
			case StepTypes.Action.ActionEnum.Invite:
				return Invite
			case StepTypes.Action.ActionEnum.FileTemplate:
				return FileTemplate
			case StepTypes.Action.ActionEnum.FileUpload:
				return FileUpload
			case StepTypes.Action.ActionEnum.Software:
				return Software
			case StepTypes.Action.ActionEnum.Video:
				return Video
			case StepTypes.Action.ActionEnum.Note:
				return Note
			case StepTypes.Action.ActionEnum.Contact:
				return Contact
			case StepTypes.Action.ActionEnum.Archive:
				return Archive
			default:
				return null
		}
	}, [actionType])

	if (!Icon) {
		return null
	}

	return (
		<Box display="flex">
			<Icon width={width} stroke={stroke} />
		</Box>
	)
}
