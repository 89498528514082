import React, { ComponentType, createContext, PropsWithChildren, useEffect, useMemo } from 'react'

import { useParentFolderFiles } from 'src/components/drive-upload/use-parent-folder-files'

export const DriveFilesContext = createContext<{
	parentFolderId?: string
	parentFolderName?: string
}>({
	parentFolderId: undefined,
	parentFolderName: undefined,
})

export const DriveFilesProvider: ComponentType<
	PropsWithChildren<{ parentFolderName?: string; parentFolderId?: string }>
> = (props) => {
	const { fetchFiles } = useParentFolderFiles(
		props.parentFolderId ?? '',
		props.parentFolderName ?? '',
	)
	const contextValue = useMemo(
		() => ({
			parentFolderId: props.parentFolderId,
			parentFolderName: props.parentFolderName,
		}),
		[props.parentFolderId, props.parentFolderName],
	)

	useEffect(() => {
		if (!props.parentFolderId) {
			return
		}
		fetchFiles()
	}, [])

	return (
		<DriveFilesContext.Provider value={contextValue}>{props.children}</DriveFilesContext.Provider>
	)
}
