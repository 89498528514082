import { Dispatch, createAsyncThunk } from '@reduxjs/toolkit'
import { ContactSdk } from '@cango-app/sdk/api'
import { ContactTypes } from '@cango-app/sdk/types'

import { showSnackbar } from 'src/helpers/snackbarManager'
import { errorHandler } from 'src/helpers/api'

import type { RootState } from '../../types'
import { selectors as authSelectors } from '../auth'

export const getContacts = createAsyncThunk<
	ContactTypes.Contact[],
	undefined,
	{ rejectValue: string; dispatch: Dispatch }
>('contacts/getContacts', async (_, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState() as RootState
		const headers = authSelectors.getAuthHeaders(state)
		return await ContactSdk.getAll(import.meta.env.VITE_API as string, headers)
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue((error as Error).message)
	}
})

export const createContact = createAsyncThunk<
	ContactTypes.Contact,
	ContactSdk.CreateContactRequest,
	{ rejectValue: string; state: RootState; dispatch: Dispatch }
>(
	'contacts/createContact',
	async (contact: ContactSdk.CreateContactRequest, { getState, rejectWithValue, dispatch }) => {
		try {
			const state = getState()
			const headers = authSelectors.getAuthHeaders(state)
			return await ContactSdk.create(import.meta.env.VITE_API as string, headers, contact)
		} catch (error) {
			errorHandler({ error, dispatch })
			return rejectWithValue((error as Error).message)
		}
	},
)

export const updateContact = createAsyncThunk<
	{ _id: string; update: Partial<ContactTypes.Contact> },
	{
		_id: string
		update: Partial<ContactTypes.Contact>
	},
	{ state: RootState; dispatch: Dispatch; rejectValue: any }
>('contacts/update', async ({ _id, update }, { getState, dispatch, rejectWithValue }) => {
	try {
		const state = getState()
		const headers = authSelectors.getAuthHeaders(state)
		await ContactSdk.update(import.meta.env.VITE_API as string, headers, _id, update)
		showSnackbar('Contact updated', { variant: 'success' })
		return { _id, update }
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue((error as Error).message)
	}
})

export const deleteContact = createAsyncThunk(
	'contacts/deleteContact',
	async (contactId: string, { getState, dispatch }) => {
		try {
			const state = getState() as RootState
			const headers = authSelectors.getAuthHeaders(state)
			await ContactSdk.deleteContact(import.meta.env.VITE_API as string, headers, contactId)
			showSnackbar('Contact deleted', { variant: 'success' })
			return contactId
		} catch (error) {
			errorHandler({ error, dispatch })
		}
	},
)
