import React, {
	useState,
	useCallback,
	ComponentType,
	createContext,
	useEffect,
	PropsWithChildren,
	useMemo,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Connection, Edge } from '@xyflow/react'
import { ChainsSdk, StepSdk } from '@cango-app/sdk/api'
import { ChainTypes, StepTypes, RoleTypes } from '@cango-app/sdk/types'
import _set from 'lodash/set'
import { createSelector } from 'reselect'
import { v4 } from 'uuid'

import { selectors as authSelectors } from 'src/store/modules/auth'
import { selectors as roleSelectors } from 'src/store/modules/roles'
import { errorHandler } from 'src/helpers/api'
import { usePrevious } from 'src/hooks/usePrevious'
import { showSnackbar } from 'src/helpers/snackbarManager'
import { GroupedSelectOption } from 'src/components'
import { RootState } from 'src/store/types'
import {
	selectors as tableSelectors,
	actions as tableActions,
	TableConfig,
} from 'src/store/modules/tables'

import { getThreads } from './components/utils'
import { ListedStep, AppNode, StepCycles, StandardNode, ChainReferenceNode } from './types'

export type ChainChildProps = {
	chain: ChainTypes.BlueprintChain | undefined
	isLoading: boolean
	steps: ListedStep[]
	onRemoveDeletedStep: (stepId: string) => void
	onAddStep: (parentId: string, callback?: () => void) => Promise<void>
	onUpdateConnection: (data: {
		_id: string
		connection: Pick<Connection, 'source' | 'target'>
		method: 'add' | 'remove' | 'update' | 'multiUse' | 'chain_complete_point'
		optionIds?: string[]
		createForEveryOption?: boolean
		thread?: StepTypes.Thread | null
		database_chain_logic?: StepTypes.Descendant['database_chain_logic']
		option_condition?: StepTypes.Descendant['option_condition']
		multiUseConfig?: StepTypes.Descendant['multi_use_config']
		databaseChanges?: StepTypes.Descendant['database_changes']
		chain_endings?: StepTypes.Descendant['chain_endings']
		create_conditions?: StepTypes.Descendant['create_conditions']
		duplication?: StepTypes.Descendant['duplication']
	}) => Promise<StepTypes.Descendant[] | undefined>
	onUpdateStep: (data: Partial<ListedStep>) => void
	updateStep: (stepId: string, data: StepSdk.UpdateStepRequest) => Promise<StepTypes.Step | void>
	extractChain: (data: {
		firstStepId: string
		newName: string
		onComplete: () => void
	}) => Promise<void>
	nodes: AppNode[]
	nodeMap: Map<string, StandardNode | ChainReferenceNode>
	groupListedNodes: GroupedSelectOption[]
	threadMap: Map<string, StepTypes.Thread[]>
	setNodes: React.Dispatch<React.SetStateAction<AppNode[]>>
	setAdjacencyList: React.Dispatch<React.SetStateAction<Map<string, Edge[]>>>
	adjacencyList: Map<string, Edge[]>
	chainIdLoading: string
	updateChain: (updates: ChainsSdk.UpdateChainRequest) => Promise<void>
	stepCycles: StepCycles
	setStepCycles: React.Dispatch<React.SetStateAction<StepCycles>>
	rolesForChain: RoleTypes.ClientRole[]
	connectedTables: { _id: string; label: string }[]
	tableConfig: TableConfig | undefined
}

export const ChainContext = createContext<ChainChildProps>({
	chain: undefined,
	connectedTables: [],
	tableConfig: undefined,
	isLoading: false,
	steps: [],
	onRemoveDeletedStep: () => {},
	onAddStep: async () => {},
	onUpdateConnection: async () => undefined,
	onUpdateStep: () => {},
	updateStep: async () => {},
	extractChain: async () => {},
	nodes: [],
	nodeMap: new Map(),
	groupListedNodes: [],
	setNodes: () => [],
	setAdjacencyList: () => new Map(),
	adjacencyList: new Map(),
	chainIdLoading: '',
	updateChain: async () => {},
	stepCycles: new Map(),
	setStepCycles: () => new Map(),
	threadMap: new Map(),
	rolesForChain: [],
})

const getNodeMap = createSelector(
	(nodes: AppNode[]) => nodes,
	(nodes): Map<string, StandardNode | ChainReferenceNode> => {
		return new Map(
			nodes.reduce((_acc: Map<string, StandardNode | ChainReferenceNode>, _node) => {
				if (_node.type === 'loop' || _node.type === 'chainComplete') {
					return _acc
				}
				_node = _node as StandardNode | ChainReferenceNode
				_acc.set(_node.id, _node)
				return _acc
			}, new Map()),
		)
	},
)

const getThreadMap = createSelector(
	(nodes: AppNode[]) => nodes,
	getNodeMap,
	(nodes, nodeMap) => {
		return new Map(nodes.map((_node) => [_node.id, getThreads({ nodeMap, nodeId: _node.id })]))
	},
)

const getGroupListedNodes = createSelector(
	(nodes: AppNode[]) => nodes,
	(nodes) => {
		return nodes.reduce((_acc: GroupedSelectOption[], _node) => {
			if (_node.type === 'loop' || _node.type === 'chainComplete') {
				return _acc
			}
			_node = _node as StandardNode | ChainReferenceNode
			if (_node.data.isSection) {
				return _acc
			}
			if (!_node.data.sections.length) {
				const existingNoSectionGroup = _acc.find((_group) => _group.groupName === 'No Section')
				if (existingNoSectionGroup) {
					existingNoSectionGroup.options.push({
						_id: _node.id,
						label: _node.data.name,
					})
					return _acc
				}
				_acc.push({
					groupName: 'No Section',
					options: [
						{
							_id: _node.id,
							label: _node.data.name,
						},
					],
				})
				return _acc
			}
			;(_node as StandardNode | ChainReferenceNode).data.sections.forEach(
				(_sectionName: string) => {
					const group = _acc.find((_group) => _group.groupName === _sectionName)
					if (group) {
						group.options.push({
							_id: _node.id,
							label: _node.data.name,
						})
						return _acc
					}
					_acc.push({
						groupName: _sectionName,
						options: [
							{
								_id: _node.id,
								label: _node.data.name,
							},
						],
					})
				},
			)
			return _acc
		}, [])
	},
)

export const ChainProvider: ComponentType<PropsWithChildren<{ chainId?: string }>> = (props) => {
	const dispatch = useDispatch()
	const [chainIdLoading, setChainIdLoading] = useState<string>('')
	const [chain, setChain] = useState<ChainTypes.BlueprintChain>()
	const [chainSteps, setChainSteps] = useState<ListedStep[]>([])
	const [nodes, setNodes] = useState<AppNode[]>([])
	const [adjacencyList, setAdjacencyList] = useState<Map<string, Edge[]>>(new Map())
	const [stepCycles, setStepCycles] = useState<StepCycles>(new Map())
	const previousChainId = usePrevious(props.chainId)
	const authHeaders = useSelector(authSelectors.getAuthHeaders)
	const rolesForChain = useSelector((state: RootState) =>
		roleSelectors.getRolesByOrgId(state, chain?.organisationId ?? ''),
	)

	const isFetchingTable = useSelector((state: RootState) =>
		tableSelectors.isFetchingTable(state, chain?.database_table ?? ''),
	)
	const tableConfig = useSelector((state: RootState) =>
		tableSelectors.selectTableConfig(state, chain?.database_table ?? ''),
	)
	const connectedTables = useSelector((state: RootState) =>
		tableSelectors.selectAllConnectedTables(state, chain?.database_table ?? ''),
	)

	const { nodeMap, threadMap, groupListedNodes } = useMemo(() => {
		const nodeMap = getNodeMap(nodes)
		const threadMap = getThreadMap(nodes)
		const groupListedNodes = getGroupListedNodes(nodes)
		return { nodeMap, threadMap, groupListedNodes }
	}, [nodes])

	const fetchChain = useCallback(async () => {
		if (!props.chainId) return
		try {
			setChainIdLoading(props.chainId)
			const chain = await ChainsSdk.get(
				import.meta.env.VITE_API as string,
				authHeaders,
				props.chainId,
			)
			const { steps } = await ChainsSdk.getSteps(import.meta.env.VITE_API as string, authHeaders, {
				chain_ids: [props.chainId],
			})
			setChain(chain)
			setChainSteps(steps)
		} catch (error) {
			errorHandler({ error, dispatch })
		} finally {
			setChainIdLoading('')
		}
	}, [props.chainId, authHeaders])

	const onRemoveDeletedStep = useCallback(
		(stepId: string) => {
			setChainSteps((prev) => prev.filter((step) => step._id !== stepId))
		},
		[authHeaders, chainSteps],
	)

	const updateChain = useCallback(
		async (updates: ChainsSdk.UpdateChainRequest) => {
			if (!chain?._id) return
			try {
				const response = await ChainsSdk.update(
					import.meta.env.VITE_API as string,
					authHeaders,
					chain._id,
					updates,
				)
				setChain(response)
			} catch (error) {
				errorHandler({ error, dispatch, message: 'Failed to update chain' })
			}
		},
		[chain],
	)

	const onAddStep = useCallback(
		async (parentId: string, callback?: () => void) => {
			if (!props.chainId) return
			try {
				const { newStep, newDescendant } = await StepSdk.create(
					import.meta.env.VITE_API as string,
					authHeaders,
					{
						chainId: props.chainId,
						parentId,
					},
				)
				setChainSteps((prev) => [
					...prev.map((_step) => {
						if (_step._id === parentId) {
							return {
								..._step,
								descendants: [..._step.descendants, newDescendant],
							}
						}
						return _step
					}),
					newStep,
				])
				if (callback) {
					callback()
				}
			} catch (error) {
				errorHandler({ error, dispatch, message: 'Failed to create step' })
			}
		},
		[authHeaders, props.chainId],
	)

	const onUpdateConnection = useCallback(
		async ({
			_id,
			connection,
			method,
			thread,
			createForEveryOption,
			database_chain_logic,
			option_condition,
			multiUseConfig,
			databaseChanges,
			chain_endings,
			create_conditions,
			duplication,
		}: {
			_id: string
			connection: Pick<Connection, 'source' | 'target'>
			method: 'add' | 'remove' | 'update' | 'multiUse' | 'chain_complete_point'
			thread?: StepTypes.Thread | null
			createForEveryOption?: boolean
			database_chain_logic?: StepTypes.Descendant['database_chain_logic']
			option_condition?: StepTypes.Descendant['option_condition']
			multiUseConfig?: StepTypes.Descendant['multi_use_config']
			databaseChanges?: StepTypes.Descendant['database_changes']
			chain_endings?: StepTypes.Descendant['chain_endings']
			create_conditions?: StepTypes.Descendant['create_conditions']
			duplication?: StepTypes.Descendant['duplication']
		}): Promise<StepTypes.Descendant[] | undefined> => {
			if (!connection.source) {
				return
			}

			const stepId = connection.source
			const step = chainSteps.find((_step) => _step._id === stepId)
			if (!step) {
				return
			}

			const previousDescendants = step.descendants

			try {
				const descendantsCopy = step.descendants.reduce(
					(
						_descObject: {
							[descendantId: string]: StepTypes.Descendant
						},
						_desc,
					) => {
						_descObject[_desc._id] = _desc
						return _descObject
					},
					{},
				)

				const target = connection.target
				if (method === 'chain_complete_point') {
					descendantsCopy[_id] = {
						_id: descendantsCopy[_id]?._id ?? _id,
						step: target,
						chain_complete_point: true,
						option_condition: option_condition,
						database_changes: databaseChanges,
					}
				}
				if (method === 'multiUse') {
					descendantsCopy[_id] = {
						_id: descendantsCopy[_id]?._id ?? _id,
						step: target,
						option_condition: option_condition,
						multi_use_config: multiUseConfig,
						database_changes: databaseChanges,
					}
				} else if (method === 'add') {
					if (previousDescendants.some(({ step }) => step === target)) {
						showSnackbar('Connection already exists', { variant: 'warning' })
						return
					}
					descendantsCopy[_id] = {
						step: target,
						_id: v4(),
					}
				} else if (method === 'remove') {
					delete descendantsCopy[_id]
				} else if (method === 'update') {
					descendantsCopy[_id] = {
						...descendantsCopy[_id],
						step: target,
						database_chain_logic: database_chain_logic,
						option_condition,
						createForEveryOption,
						thread: thread ? { ...thread, color: thread.color ?? '#c4def6' } : null,
						multi_use_config: multiUseConfig,
						database_changes: databaseChanges,
						_id: descendantsCopy[_id]._id,
						chain_endings,
						create_conditions,
						duplication,
					}
				}

				const formattedDescendants = Object.values(descendantsCopy)

				setChainSteps((prev) =>
					prev.map((_step) => {
						if (_step._id === stepId) {
							_set(_step, 'descendants', formattedDescendants)
						}
						return _step
					}),
				)

				await StepSdk.update(import.meta.env.VITE_API as string, authHeaders, stepId, {
					descendants: formattedDescendants,
				})

				return formattedDescendants
			} catch (error) {
				errorHandler({ error, dispatch, message: 'Failed to update step' })
				setChainSteps((prev) =>
					prev.map((_step) => {
						if (_step._id === stepId) {
							_set(_step, 'descendants', previousDescendants)
						}
						return _step
					}),
				)
			}
		},
		[chainSteps],
	)

	const extractChain = useCallback(
		async (_data: unknown) => {
			// if (!chain?._id) return
			// const firstStep = nodes.find((_node) => _node.id === firstStepId)?.data
			//
			// let chainStepIds =
			// 	getNodeDescendantsMap(nodes, adjacencyList)
			// 		.get(firstStepId)
			// 		?.map((_node) => _node.id) ?? []
			//
			// if (firstStep?.thread?._id) {
			// 	chainStepIds = nodes.reduce((_acc: string[], _node) => {
			// 		if (_node.data.thread?._id === firstStep.thread?._id) {
			// 			_acc.push(_node.id)
			// 		}
			// 		return _acc
			// 	}, [])
			// }
			//
			// try {
			// 	const response = await ChainsSdk.extract({
			// 		baseURL: import.meta.env.VITE_API as string,
			// 		authHeaders,
			// 		data: {
			// 			firstStepId,
			// 			name: newName,
			// 			steps: chainStepIds,
			// 			chainDefinition: chain?._id,
			// 		},
			// 	})
			// 	setChainSteps(response.steps)
			// 	onComplete()
			// } catch (error) {
			// 	errorHandler({ error, dispatch, message: 'Failed to extract chain' })
			// }
		},
		[authHeaders, chain?._id, nodes, adjacencyList],
	)

	const handleUpdateTask = useCallback(async (step: Partial<ListedStep>) => {
		setChainSteps((prev) =>
			prev.map((_step) =>
				_step._id === step._id
					? {
							..._step,
							...step,
						}
					: _step,
			),
		)
	}, [])

	const updateStep = useCallback(
		async (stepId: string, data: StepSdk.UpdateStepRequest) => {
			try {
				const step = await StepSdk.update(
					import.meta.env.VITE_API as string,
					authHeaders,
					stepId,
					data,
				)
				setChainSteps((prev) => {
					return prev.map((_step) => {
						if (_step._id === stepId) {
							return step
						}
						return _step
					})
				})
				return step
			} catch (error) {
				errorHandler({ error, dispatch, message: 'Could not update step' })
			}
		},
		[authHeaders],
	)

	useEffect(() => {
		if (
			!isFetchingTable &&
			((!tableConfig && chain?.database_table) || chain?.database_table !== tableConfig?._id)
		) {
			dispatch(
				tableActions.fetchTable({
					newTableId: chain?.database_table ?? '',
				}),
			)
		}
	}, [chain?.database_table, isFetchingTable, tableConfig?._id])

	useEffect(() => {
		if (props.chainId && props.chainId !== previousChainId) {
			fetchChain()
			return
		}

		if (previousChainId && !props.chainId) {
			setChain(undefined)
			setChainSteps([])
		}
	}, [previousChainId, props.chainId])

	useEffect(() => {
		dispatch(tableActions.getTableList())
	}, [])

	const values = useMemo(
		(): ChainChildProps => ({
			chain,
			tableConfig,
			connectedTables,
			isLoading: !!chainIdLoading,
			chainIdLoading,
			steps: chainSteps ?? [],
			onRemoveDeletedStep,
			onAddStep,
			onUpdateConnection,
			onUpdateStep: handleUpdateTask,
			updateStep,
			extractChain,
			nodes,
			nodeMap,
			groupListedNodes,
			setNodes,
			setAdjacencyList,
			adjacencyList,
			updateChain,
			stepCycles,
			setStepCycles,
			threadMap,
			rolesForChain,
		}),
		[
			chain,
			chainIdLoading,
			chainSteps,
			nodeMap,
			nodes,
			adjacencyList,
			stepCycles,
			rolesForChain,
			tableConfig,
			connectedTables,
		],
	)

	return <ChainContext.Provider value={values}>{props.children}</ChainContext.Provider>
}
