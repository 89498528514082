import { ComponentType, useContext, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _uniq from 'lodash/uniq'
import { TaskTypes, ProjectTypes } from '@cango-app/sdk/types'

import { ContactAndAssignment } from 'src/components'
import { actions as projectActions } from 'src/store/modules/projects-v3'
import { TaskContext } from 'src/providers'
import { AsyncDispatchType, RootState } from 'src/store/types'
import { selectors as roleSelectors } from 'src/store/modules/roles'

type ContactInfoProps = {
	project: Pick<ProjectTypes.Project, '_id' | 'roles' | 'externals'>
	isAttachment?: boolean
}

export const ContactInfo: ComponentType<ContactInfoProps> = ({ project, isAttachment = false }) => {
	const dispatch = useDispatch<AsyncDispatchType>()
	const { task } = useContext(TaskContext)
	const allRoles = useSelector(roleSelectors.getRoles)
	const mappedRoles = useSelector((state: RootState) =>
		roleSelectors.getMappedRoles(state, task?.organisationId ?? ''),
	)
	const typeOfTaskElement: keyof Pick<TaskTypes.PopulatedTask, 'actions' | 'attachments'> = useMemo(
		() => (isAttachment ? 'attachments' : 'actions'),
		[isAttachment],
	)

	const handleRoleAssignChange = async ({
		roleId,
		userId,
		contactId,
	}: {
		roleId: string
		contactId?: string
		userId?: string
	}) => {
		await dispatch(
			projectActions.assignRole({
				projectId: project._id,
				userId,
				roleId,
				contactId,
			}),
		)
	}

	const { externalRoles, internalRoles } = useMemo(
		() =>
			project?.roles.reduce<{
				internalRoles: ProjectTypes.Role[]
				externalRoles: ProjectTypes.Role[]
			}>(
				({ internalRoles, externalRoles }, _role) => {
					const isInternal = mappedRoles.get(_role.role)?.internal
					if (isInternal) {
						return {
							internalRoles: [...internalRoles, _role],
							externalRoles,
						}
					}
					return {
						internalRoles,
						externalRoles: [...externalRoles, _role],
					}
				},
				{ internalRoles: [], externalRoles: [] },
			) ?? { externalRoles: [], internalRoles: [] },
		[project, mappedRoles],
	)

	const taskInternalRoles: { role: string; user?: string }[] = useMemo(() => {
		if (!task) return []
		const selectedTaskRoles = task[typeOfTaskElement].reduce((_acc: string[], _action) => {
			return _uniq([..._acc, ..._action.roles])
		}, [])
		const internalRolesInTask = internalRoles.filter((_projectRole) =>
			selectedTaskRoles.includes(_projectRole.role),
		)
		return internalRolesInTask.map((_role) => {
			const mappedRole = mappedRoles.get(_role.role)
			if (mappedRole) {
				return {
					role: mappedRole._id,
					user: _role.user ?? mappedRole.defaultUser,
				}
			}
			return {
				role: _role.role,
				user: undefined,
			}
		})
	}, [task, project, typeOfTaskElement, internalRoles])

	const taskExternalRoles = useMemo(() => {
		if (!task) return []
		const selectedTaskRoles = task[typeOfTaskElement].reduce((_acc: string[], _action) => {
			return _uniq([..._acc, ..._action.roles])
		}, [])
		const externalRolesInTask = externalRoles.filter(({ role }) => selectedTaskRoles.includes(role))
		return externalRolesInTask.map(({ contact, role }) => {
			const mappedRole = mappedRoles.get(role)
			if (mappedRole) {
				return {
					role: mappedRole._id,
					contact: contact ?? mappedRole.defaultContact,
				}
			}
			return {
				role: role,
				contact: undefined,
			}
		})
	}, [task, project, typeOfTaskElement, externalRoles, allRoles])
	return (
		<ContactAndAssignment
			taskExternals={taskExternalRoles}
			taskRoles={taskInternalRoles}
			onAssignRole={handleRoleAssignChange}
			organisationId={task?.organisationId}
		/>
	)
}
