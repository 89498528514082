import { AuthSdk, HeadersType } from '@cango-app/sdk/api'
import { createSelector } from '@reduxjs/toolkit'
import { decode as decodeJwt } from 'jsonwebtoken'
import _map from 'lodash/map'
import sortBy from 'lodash/sortBy'

import type { RootState } from '../../types'

import { AuthState, UserOrganisationState } from './types'

const getAuthState = (state: RootState) => state.cangoAuth

const getSessionConfig = (state: RootState) => state.cangoSession

const getDefaultOrganisationId: (state: RootState) => string | undefined = createSelector(
	getAuthState,
	(authState) => authState?.defaultOrganisationId,
)

const getLastVisitedOrganisationId: (state: RootState) => string | undefined = createSelector(
	getAuthState,
	(authState) => authState?.lastVisitedOrganisationId,
)

const getOrganisations = createSelector(getAuthState, (authState) =>
	_map(authState?.userOrganisations, (organisation, id) => ({ ...organisation, id })),
)

const getOrganisationId: (state: RootState) => string | undefined = createSelector(
	getSessionConfig,
	getAuthState,
	(sessionConfig, authState) => {
		return (
			sessionConfig.selectedOrganisationId ??
			authState.lastVisitedOrganisationId ??
			authState.defaultOrganisationId
		)
	},
)

const getUserOrganisations: (state: RootState) => AuthState['userOrganisations'] = createSelector(
	getAuthState,
	({ userOrganisations }) => userOrganisations,
)

const getJwt: (state: RootState) => string | undefined = createSelector(
	getOrganisationId,
	getUserOrganisations,
	(organisationId, userOrganisations) => {
		if (!organisationId) return
		const organisation = userOrganisations[organisationId]
		if (!organisation) return
		return organisation.jwt
	},
)

const getSession: (state: RootState) => AuthSdk.TokenFields | undefined | null = createSelector(
	getJwt,
	(jwt) => {
		if (!jwt) return
		return decodeJwt(jwt) as AuthSdk.TokenFields | null
	},
)

const getAuthHeaders: (state: RootState) => HeadersType = createSelector(
	getJwt,
	getOrganisationId,
	(jwt, organisationId) => {
		return {
			RUID: organisationId || '',
			Authorization: `Bearer ${jwt || ''}`,
		}
	},
)

const getUserOrganisationsList: (
	state: RootState,
	filterSelected?: boolean,
) => { id: string; name: string; organisationCode: string }[] = createSelector(
	getUserOrganisations,
	(state: RootState, filterSelected?: boolean) => !!filterSelected,
	getOrganisationId,
	(organisations, filterSelected, selectedOrganisationId) => {
		const list = _map(organisations, (organisation, id) => ({
			id,
			name: organisation.name,
			organisationCode: organisation.organisationCode,
		}))
		const sortedList = sortBy(list, 'name')
		if (filterSelected) {
			return sortedList.filter(({ id }) => id !== selectedOrganisationId)
		}
		return sortedList
	},
)

const getOrganisationName: (state: RootState) => string | undefined = createSelector(
	getOrganisationId,
	getUserOrganisations,
	(organisationId, userOrganisations) => {
		if (!organisationId) return
		const organisation = userOrganisations[organisationId]
		if (!organisation) return
		return organisation.name
	},
)

const getOrganisationCode: (state: RootState) => string | undefined = createSelector(
	getOrganisationId,
	getUserOrganisations,
	(organisationId, userOrganisations) => {
		if (!organisationId) return
		const organisation = userOrganisations[organisationId]
		if (!organisation) return
		return organisation.organisationCode
	},
)

const getLoginToken: (state: RootState) => string | undefined = createSelector(
	getAuthState,
	({ loginToken }) => loginToken,
)

const getFirebaseToken: (state: RootState) => string | undefined = createSelector(
	getAuthState,
	({ firebaseToken }) => firebaseToken,
)

const isFetchingOrganisations: (state: RootState) => boolean = createSelector(
	getAuthState,
	({ organisationState }) => organisationState === UserOrganisationState.pending,
)

const isUserSigningIn: (state: RootState) => boolean = createSelector(
	isFetchingOrganisations,
	getAuthState,
	(isFetchingOrganisations, { isAuthenticating }) => {
		return isAuthenticating || isFetchingOrganisations
	},
)

export const selectors = {
	getOrganisations,
	getJwt,
	getAuthHeaders,
	getSession,
	getOrganisationName,
	getOrganisationCode,
	getUserOrganisationsList,
	getDefaultOrganisationId,
	getLastVisitedOrganisationId,
	getOrganisationId,
	getLoginToken,
	getUserOrganisations,
	getFirebaseToken,
	isFetchingOrganisations,
	isUserSigningIn,
}
