import { tablesSlice } from './reducer'
import * as thunks from './thunks'

export * as selectors from './selectors'
export * from './types'
export const actions = {
	...tablesSlice.actions,
	...thunks,
}
export const reducer = tablesSlice.reducer
